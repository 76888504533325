import React, { Component } from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect, Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import 'assets/vendors/style';
import defaultTheme from './themes/defaultTheme';
import AppLocale from '../lngProvider';

import MainApp from 'app/index';
import LogIn from './LogIn';
import SignIn from './SignIn';
//import SignUp from './SignUp';
import { setInitUrl } from '../actions/Auth';
import RTL from 'util/RTL';
import asyncComponent from 'util/asyncComponent';
//import { getAuth, onAuthStateChanged } from 'firebase/auth';

//import firebase from "firebase/app";
//import "firebase/auth";

// import firebase from "firebase/compat/app";
// import "firebase/compat/auth";
// import 'firebase/compat/firestore';

//import firebase from 'firebase/app'

// import {initializeApp}  from 'firebase/app'
// import 'firebase/firestore'
// import 'firebase/auth'
// import 'firebase/storage'

// import firebase from 'firebase/compat/app';
// import "firebase/compat/auth";
// import "firebase/compat/firestore";

// import firebase from "firebase/app";
// import "firebase/performance";
// import "firebase/firestore";
// import "firebase/auth";


import { initializeApp } from "firebase/app"
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import { getAuth, onAuthStateChanged, GoogleAuthProvider, FacebookAuthProvider, GithubAuthProvider, TwitterAuthProvider } from 'firebase/auth';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { getFirestore, collection, query, where, getDoc, getDocs } from 'firebase/firestore';
import { getDatabase, useDatabaseEmulator, ref, set, onValue } from 'firebase/database';



const firebaseConfig = {
  apiKey: 'AIzaSyCkwrJO1ej4Qn3pieK33c_7cp9YTtReMy8',
  authDomain: 'hello-suzuki.firebaseapp.com',
  databaseURL: 'https://hello-suzuki.firebaseio.com',
  projectId: 'hello-suzuki',
  storageBucket: 'hello-suzuki.appspot.com',
  messagingSenderId: '754489199656',
  appId: '1:754489199656:web:1d07fae011375e6315391d',
  measurementId: 'G-66H0SEZW8P'
};

const firebase =  initializeApp(firebaseConfig);

const auth = getAuth(firebase)

//const firebaseApp = initializeApp({ /* config */ });

// if (!firebase.apps.length) {
//   firebase.initializeApp(firebaseConfig);
// }


//firebase.initializeApp(firebaseConfig);




//import { initializeApp } from 'firebase/app'

// import { getAuth, onAuthStateChanged } from 'firebase/auth'; //, onAuthStateChanged

const RestrictedRoute = ({ component: Component, authUser, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      authUser ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/login', //'/signin',
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

class App extends Component {
  constructor(props) {
    super(props);
    
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    if (this.props.initURL === '') {
      this.props.setInitUrl(this.props.history.location.pathname);
    }
  }
   //Have to uncomment
  // componentWillMount() {
    // window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    // if (this.props.initURL === '') {
    //   this.props.setInitUrl(this.props.history.location.pathname);
    // }
  // }

  // static getDerivedStateFromProps(props, state) {
  //   console.log('getDerivedStateFromProps');
  // }

  render() {
    const {
      match,
      location,
      locale,
      authUser,
      initURL,
      isDirectionRTL
    } = this.props;


    // const auth = getAuth();
    // onAuthStateChanged(auth, (user) => {
    //   if(user){
    //     console.log('user log in');
    //     //window.location = 'dashboard.html'
    //   } else {
    //     console.log('user log out');
    //     //window.location = 'login.html'
    //     localStorage.removeItem('user_id');
    //     localStorage.removeItem('role');
    //     localStorage.removeItem('name_th');
    //     localStorage.removeItem('name_en');
    //     localStorage.removeItem('shorth_name');
    //     localStorage.removeItem('avatar');
    //     localStorage.removeItem('model');
    //     return <Redirect to={'/login'} />;
    //   }
    // });

    // firebase.auth().onAuthStateChanged(user => {
    //getAuth().onAuthStateChanged(user => {  
     auth.onAuthStateChanged(user => {
      if(user){
        //console.log('user log in');
          //window.location = 'dashboard.html'
      } else {
        //console.log('user log out');
          //window.location = 'login.html'
          localStorage.removeItem('user_id');
          localStorage.removeItem('role');
          localStorage.removeItem('name_th');
          localStorage.removeItem('name_en');
          localStorage.removeItem('shorth_name');
          localStorage.removeItem('avatar');
          localStorage.removeItem('model');
          return <Redirect to={'/login'} />;
      }
    });

    //return <Redirect to={'/login'} />;

    if (location.pathname === '/') {
      if (authUser === null) {
       // console.log('A');
        return <Redirect to={'/login'} />; //<Redirect to={'/signin'} />;
      } else if (initURL === '' || initURL === '/' || initURL === '/login' || initURL === '/signin') {
        //console.log('B');
        let role = localStorage.getItem('role');
        if (role === 'superAdmin') {
          return <Redirect to={'/app/dashboard/register-users'} />;
        } else if (role === 'smt') {
          return <Redirect to={'/app/dashboard/register-users'} />;
        } else if (role.length === 6) {
          return <Redirect to={'/app/dashboard/register-users'} />;
        } else if (role === 'userHaveCar') {
          return <Redirect to={'/app/customers/request-delete'} />;
        } else if (role === 'userNoCar') {
          return <Redirect to={'/app/customers/request-delete'} />;
        } else {
          return <Redirect to={'/app/customers/request-delete'} />;
        }
      } else {
        //console.log('C');
        return <Redirect to={initURL} />;
      }
    }
    const applyTheme = createMuiTheme(defaultTheme);

    if (isDirectionRTL) {
      console.log('direction rtl');
      applyTheme.direction = 'rtl';
      document.body.classList.add('rtl');
    } else {
      console.log('direction ltr');
      document.body.classList.remove('rtl');
      applyTheme.direction = 'ltr';
    }
    //console.log('locale:' + JSON.stringify(locale.locale))
    const currentAppLocale = AppLocale[locale.locale];
    //console.log('languageApp:' + JSON.stringify(currentAppLocale))
    return (
      <MuiThemeProvider theme={applyTheme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}
          >
            <RTL>
              <div className="app-main">
                <Switch>
                  <RestrictedRoute
                    path={`${match.url}app`}
                    authUser={authUser}
                    component={MainApp}
                  />
                  <Route path="/login" component={LogIn} />
                  <Route path="/signin" component={SignIn} />
                  {/* <Route path="/signup" component={SignUp} /> */}
                  <Route
                    component={asyncComponent(() =>
                      import('components/Error404')
                    )}
                  />
                </Switch>
              </div>
            </RTL>
          </IntlProvider>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = ({ settings, auth }) => {
  const { sideNavColor, locale, isDirectionRTL } = settings;
  const { authUser, initURL } = auth;
  return { sideNavColor, locale, isDirectionRTL, authUser, initURL };
};

export default connect(mapStateToProps, { setInitUrl })(App);
