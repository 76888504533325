export const chanegPhoneInitialState = {
    loaderApiUser: false,
    loaderUserByOldPhone: false,
    loaderUserByNewPhone: false,
    loaderChangePhone: false,
    apiUserItems: null,
    oldPhoneUser: null,
    newPhoneUser: null,
    isPhoneUsing: false,
    isMessageChangePhone: false,
    changePhoneCode: '',
    oldPhone: '',
    newPhone: '',
    vinNumber: '',
    userId: ''
};

export const secondHandCarInitialState = {
    loaderGetUserSecondHandCar: false,
    loaderSecondHandCar: false,
    userSecondHandCarItem: null,
    isMessageSecondHandCar: false,
    secondHandCarCode: '' 
};

export const campaignRankInitialState = {
    isProcessing: false,
    isFinished: false,
    progress: {
        processedCount: 0,
        totalRecords: 0,
        percentage: 0,
    },
    error: null,
};

export const campaignInactiveInitialState = {
    isProcessing: false,
    isFinished: false,
    progress: {
        processedCount: 0,
        totalRecords: 0,
        percentage: 0,
    },
    error: null,
};