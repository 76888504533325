import React from "react";
import ContainerHeader from "components/ContainerHeader/index";
import IntlMessages from "util/IntlMessages";
import withTranslatedSwal from "util/withTranslatedSwal";
import { connect } from "react-redux";
import {
  getUserSecondCarRequest,
  secondHandCarRequest,
  showGetUserSecondHandCarLoader,
  showSecondHandCarLoader,
  resetSecondHandCar,
  showMessageSecondHandCar,
  hideMessageSecondHandCar,
} from "actions/SecondHandCar";
import TextField from "@material-ui/core/TextField";
//import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import CardBox from "components/CardBox";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import CircularProgress from "@material-ui/core/CircularProgress";
//import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { PAGE_IDS } from "constants/PageConstants";
import { resetPage, updateFormData } from "actions";

class SecondHandCar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: "",
      vinNumber: "",
      userId: "",
      isActive: false,
      alertOpen: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.cancelPress = this.cancelPress.bind(this);
    //this.handleRequestClose = this.handleRequestClose.bind(this);

    this.baseState = this.state;
  }

  handleSubmit(e) {
    e.preventDefault();
    e.target.reset();
  }

  handleChange = (name) => (event, checked) => {
    this.setState({ [name]: checked });

    this.props.updateFormData(
      'secondHandCar',
      'isActive',
      checked
    )
    //console.log("isActive:" + this.state.isActive);
  };

  handleRequestOpen = () => {
    this.setState({alertOpen: true});
    //console.log("OPEN")
  };

  handleRequestClose = () => {
    this.setState({alertOpen: false});
    //console.log("CLOSE")
  };

  secondHandCarPress() {
    
    this.props.translateSwal({
      title: <IntlMessages id="pages.secondhandcar.message.alert.title" />,
      text:  <IntlMessages id="pages.secondhandcar.message.alert.text" />,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: <IntlMessages id="pages.secondhandcar.message.alert.confirm" />,
      cancelButtonText: <IntlMessages id="pages.secondhandcar.message.alert.cancel" />,
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          // Your existing submission logic

          const { formData, langFormData } = this.props;
          const secondHandCarData = langFormData.secondHandCar || formData.secondHandCar;

          this.props.showSecondHandCarLoader();
          this.props.secondHandCarRequest(
            localStorage.getItem('secondHandCarUserId'),
            secondHandCarData.vinNumber,
            secondHandCarData.isActive
          );

          

          //console.log("RESET after finishing");
          // console.log("isMessageSecondHandCar2:" + this.props.isMessageSecondHandCar);
          // console.log("secondHandCarCode2:" + this.props.secondHandCarCode);

          const isMessageSecondHandCar = this.props.isMessageSecondHandCar;
          const secondHandCarCode = this.props.secondHandCarCode;

          if (isMessageSecondHandCar) {
            this.displayMessage(secondHandCarCode);

            //console.log("secondHandCarCode3:" + secondHandCarCode);
            
            if (secondHandCarCode === 100) {
              //console.log("A");

              // console.log('secondHandCarUserId1:' + localStorage.getItem('secondHandCarUserId'));
              // localStorage.removeItem('secondHandCarUserId');
              // console.log('secondHandCarUserId2:' + localStorage.getItem('secondHandCarUserId'));

              

              this.props.translateSwal({
                title: <IntlMessages id="pages.secondhandcar.message.success.title" />,
                text:  <IntlMessages id="pages.secondhandcar.message.success.text" />,
                icon: 'success',
              })

              setTimeout(() => {
                NotificationManager.success(
                  <IntlMessages id="pages.secondhandcar.message.notification.success" />,
                  <IntlMessages id="pages.secondhandcar.message.notification.done" />,
                  3000
                );
              }, 100);

              this.props.hideMessageSecondHandCar();

              this.cancelPress();
              
            } else {
              //console.log("B");
              this.props.translateSwal({
                title: <IntlMessages id="pages.secondhandcar.message.error.title" />,
                text:  <IntlMessages id="pages.secondhandcar.message.error.text" />,
                icon: 'error',
              })

              setTimeout(() => {
                NotificationManager.error(
                  <IntlMessages id="pages.secondhandcar.message.notification.failure" />,
                  <IntlMessages id="pages.secondhandcar.message.notification.fail" />,
                  3000
                );
              }, 100);
            }
          }

          

          // document.getElementById("second-hand-car").reset();
          // this.setState({
          //   phoneNumber: "",
          //   vinNumber: "",
          //   userId: "",
          //   isActive: false,
          // });
          
          // // NEW
          
          // this.props.updateFormData(
          //   'secondHandCar',
          //   'phoneNumber',
          //   ''
          // );

          // this.props.updateFormData(
          //   'secondHandCar',
          //   'vinNumber',
          //   ''
          // );

          // this.props.updateFormData(
          //   'secondHandCar',
          //   'isActive',
          //   false
          // );

          // this.setState(this.baseState);
          // this.props.resetSecondHandCar();

          // localStorage.removeItem('secondHandCarUserId');
          // this.props.resetPage(PAGE_IDS.SECOND_HAND_CAR);
          
          
          // NEW
          

          // Show success notification
          // Swal.fire(
          //   'Changed!',
          //   'Your phone number has been changed.',
          //   'success'
          // );
        } catch (error) {
          console.log('error:' + error);
          // Show error notification
          this.props.translateSwal({
            title: <IntlMessages id="pages.secondhandcar.message.error.title" />,
            text:  <IntlMessages id="pages.secondhandcar.message.error.text" />,
            icon: 'error',
          })
          // Swal.fire(
          //   'Error!',
          //   'Failed to change phone number.',
          //   'error'
          // );
        }
      } else {
        // Show cancellation notification
        NotificationManager.info(
          <IntlMessages id="pages.secondhandcar.message.notification.cancel" />, 
          <IntlMessages id="pages.secondhandcar.message.notification.submit.cancel" />, 
          3000
        );
      }
    });
  }

  cancelPress() {
    //e.preventDefault();


    document.getElementById("second-hand-car").reset();
    this.setState({
      phoneNumber: "",
      vinNumber: "",
      userId: "",
      isActive: false,
    });

    this.setState(this.baseState);
    this.props.resetSecondHandCar();

    this.props.resetPage(PAGE_IDS.SECOND_HAND_CAR);

    localStorage.removeItem('secondHandCarUserId');

    this.props.updateFormData(
      'secondHandCar',
      'phoneNumber',
      ''
    );

    this.props.updateFormData(
      'secondHandCar',
      'vinNumber',
      ''
    );

    this.props.updateFormData(
      'secondHandCar',
      'isActive',
      false
    );
  }

  // selectAgree() {
  //   console.log("AAA")
  // }

  displayMessage(code) {
    //console.log('code:' + code);
    // if (code === 100) {
    //   NotificationManager.success(
    //     <IntlMessages id="pages.secondhandcar.message.notification.success" />,
    //     <IntlMessages id="pages.secondhandcar.message.notification.done" />,
    //     3000
    //   );
    // } else {
    //   NotificationManager.error(
    //     <IntlMessages id="pages.secondhandcar.message.notification.failure" />,
    //     <IntlMessages id="pages.secondhandcar.message.notification.fail" />,
    //     3000
    //   );
    // }
    if (this.props.isMessageSecondHandCar) {
      this.props.hideMessageSecondHandCar();
      // setTimeout(() => {
      //   this.props.hideMessageReward();
      // }, 3000);
    }
  }

  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.userSecondHandCarItem !== this.props.userSecondHandCarItem) {
  //     //var vinNumber = this.state.vinNumber;

  //     if (
  //       nextProps.userSecondHandCarItem !== undefined &&
  //       nextProps.userSecondHandCarItem !== null
  //     ) {
  //       if (nextProps.userSecondHandCarItem.length === 1) {
  //         //this.setState({ userId: u.objectId });
  //         nextProps.userSecondHandCarItem.map((u) => {
  //           //console.log('userId2:' + u.objectId);
  //           //onsold.log('uChassis:' + u.chassisNumber)
  //           this.setState({ userId: u.objectId });
  //         });
  //       }
  //     }
  //   }
  // }

  componentDidMount() {
    // Set a flag to indicate this is not a language change
    localStorage.setItem('isPageNavigation', 'true');
  }

  componentDidUpdate(prevProps) {
    //const { navigate,goBack } = this.props.navigation;

    //console.log('componentDidUpdate')

    const { formData, langFormData } = this.props;
    const secondHandCarData = langFormData.secondHandCar || formData.secondHandCar;


    // console.log('isMessageSecondHandCar:' + this.props.isMessageSecondHandCar);
    // console.log('previous:' + prevProps.secondHandCarCode);
    // console.log('current:' + this.props.secondHandCarCode);


    // if (this.props.isMessageSecondHandCar) {
    //   this.displayMessage(this.props.secondHandCarCode);
    // }

    // if ((prevProps.secondHandCarCode === 100) && (this.props.secondHandCarCode === '')) {
    //   localStorage.removeItem('secondHandCarUserId');
    //   console.log('secondHandCarUserId2:' + localStorage.getItem('secondHandCarUserId'));
    // }
    

    if (prevProps.userSecondHandCarItem !== this.props.userSecondHandCarItem) {
    //if ((secondHandCarData.phoneNumber) && (prevProps.userSecondHandCarItem !== this.props.userSecondHandCarItem)) {
      // Check if oldPhoneUser is defined and not null
      if (
        this.props.userSecondHandCarItem !== undefined &&
        this.props.userSecondHandCarItem !== null
      ) {
        // Use forEach instead of map for side effects
        this.props.userSecondHandCarItem.forEach((u) => {
          //console.log('objectId:' + u.objectId)
          // this.setState({ userId: u.objectId }, () => {
          //   localStorage.setItem('secondHandCarUserId', u.objectId);
          //   //console.log('State after update:', this.state.userId);
          // });
          localStorage.setItem('secondHandCarUserId', u.objectId);

          // u.chassisNumber.forEach((c) => {
          //   //console.log('chassisNumber2:' + c)
          //   if (c === this.state.vinNumber) {
          //     //console.log('userId1:' + u.objectId);
        
          //     //this.setState({ userId: u.objectId });
          //     //console.log('userId:2' + this.state.userId);

              
          //   }
          // });
        });
      }
    }
  }

  componentWillUnmount() {
    // Clear localStorage when component is unmounted 
    // Not working when change language
    console.log('componentWillUnmount');
    //console.log('isPageNavigation:' + localStorage.getItem('isPageNavigation'));

    const isPageNavigation = localStorage.getItem('isPageNavigation') === 'true';
    
    if (isPageNavigation) {
      // Clear userId only if it's a genuine page navigation
      this.cancelPress();
      localStorage.removeItem('secondHandCarUserId');
    }
  }

  displayProcessButton = ({ userSecondHandCarItem }) => {

    const { formData, langFormData } = this.props;
    const secondHandCarData = langFormData.secondHandCar || formData.secondHandCar;


    if (userSecondHandCarItem !== undefined && userSecondHandCarItem !== null) {
      if (userSecondHandCarItem.length === 1) {
        return (
          <div>
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                <div className="d-flex justify-content-center">
                  <CardBox styleName="col-7" cardStyle="p-5">
                    <section>
                      <center>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={secondHandCarData.isActive}
                              onChange={this.handleChange("isActive")}
                              value="isActive"
                            />
                          }
                          label={
                            <IntlMessages id="pages.secondhandcar.checkbox.active" />
                          }
                        />
                      </center>
                      <br />

                      <center>
                        <Button
                          variant="contained"
                          className="jr-btn bg-green text-white"
                          onClick={(event) => {
                            if (event) event.preventDefault();

                            this.secondHandCarPress();
                            //this.handleRequestOpen();
                            
                          }}
                        >
                          <span>
                            <IntlMessages id="pages.secondhandcar.button.process" />
                          </span>
                        </Button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <Button
                          variant="contained"
                          className="jr-btn bg-red text-white"
                          onClick={(event) => {
                            if (event) event.preventDefault();

                            this.cancelPress();
                          }}
                        >
                          <IntlMessages id="pages.secondhandcar.button.cancel" />
                        </Button>
                      </center>
                    </section>
                  </CardBox>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  showUser = (userItems) => {
    if (userItems !== undefined && userItems !== null) {
      if (userItems.length > 0) {
        // console.log('userId1:' + this.state.userId);

        // oldItems.map(u => {
        //   console.log('userId2:' + u.objectId);
        //   //onsold.log('uChassis:' + u.chassisNumber)
        //   u.chassisNumber.map(c => {
        //     if (c === this.state.vinNumber) {
        //       this.setState({ userId: u.objectId });
        //       console.log('userId3:' + this.state.userId);
        //     }
        //   });
        // });
        const { formData, langFormData } = this.props;
        const secondHandCarData = langFormData.secondHandCar || formData.secondHandCar;

        return (
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
              <div className="d-flex justify-content-center">
                <CardBox
                  styleName="col-7"
                  cardStyle="p-5"
                  heading={
                    <IntlMessages id="pages.secondhandcar.label.getuserheader" />
                  }
                >
                  <List>
                    {/* <IntlMessages id="pages.changephone.label.getuseroldphone" /> */}
                    {userItems.map((u) => {
                      return (
                        <ListItem key={u.objectId}>
                          <ListItemIcon>
                            {secondHandCarData.phoneNumber === u.phone ? (
                              <i className="zmdi zmdi-circle text-green" />
                            ) : (
                              <i className="zmdi zmdi-close-circle text-red" />
                            )}
                          </ListItemIcon>
                          <ListItemText key={u.objectId}>
                            {u.phone + " " + u.fullname + " " + u.dealerCode}
                          </ListItemText>
                          <List>
                            {u.chassisNumber.map((c) => {
                              return (
                                <ListItem key={c}>
                                  <ListItemIcon>
                                    {c === secondHandCarData.vinNumber ? (
                                      <i className="zmdi zmdi-circle text-green" />
                                    ) : (
                                      <i className="zmdi zmdi-close-circle text-red" />
                                    )}
                                  </ListItemIcon>
                                  <ListItemText key={c}>{c}</ListItemText>
                                </ListItem>
                              );
                            })}
                          </List>
                        </ListItem>
                      );
                    })}
                  </List>
                </CardBox>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
              <div className="d-flex justify-content-center">
                <CardBox
                  styleName="col-7"
                  cardStyle="p-5"
                  heading={
                    <IntlMessages id="pages.secondhandcar.label.getuserheader" />
                  }
                >
                  <ListItem>
                    <ListItemIcon>
                      <i className="zmdi zmdi-close-circle text-red" />
                    </ListItemIcon>
                    <ListItemText key="1">
                      <IntlMessages id="pages.secondhandcar.label.nouserdata" />
                    </ListItemText>
                  </ListItem>
                </CardBox>
              </div>
            </div>
          </div>
        );
      }
    } else {
      return null;
    }
  };

  

  render() {
    let role = localStorage.getItem("role");
    if (role !== "smt" && role !== "superAdmin") {
      if (role.length === 6) {
        return <Redirect to={"/app/dashboard/register-users"} />;
      } else {
        return <Redirect to={"/app/customers/request-delete"} />;
      }
    }

    const { phoneNumber, vinNumber, alertOpen } = this.state; //, userId, isActive
    const {
      loaderGetUserSecondHandCar,
      loaderSecondHandCar,
      formData,
      langFormData,   
    } = this.props; //userSecondHandCarItem, isMessageSecondHandCar, secondHandCarCode,

    //const { formData, langFormData } = this.props;
    const secondHandCarData = langFormData.secondHandCar || formData.secondHandCar;

    const userId = localStorage.getItem('secondHandCarUserId');

    return (
      <div className="app-wrapper">
        <ContainerHeader
          match={this.props.match}
          title={<IntlMessages id="pages.secondhandcar" />}
        />
        <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3"></div>
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-12">
            <div className="d-flex justify-content-center">
              <CardBox
                styleName="col-7"
                cardStyle="p-5"
                heading={<IntlMessages id="pages.secondhandcar.label.title" />}
              >
                <form
                  id="second-hand-car"
                  method="post"
                  //onSubmit={this.handleSubmit.bind(this)}
                  action="/"
                >
                  <TextField
                    type="tel"
                    label={
                      <IntlMessages id="pages.secondhandcar.textfield.phonenumber" />
                    }
                    value={secondHandCarData.phoneNumber}
                    onChange={(event) => {
                      //this.setState({ phoneNumber: event.target.value })

                      const inputValue = event.target.value.replace(/\D/g, '').slice(0, 10);
                      //this.setState({ phoneNumber: inputValue });       
                      this.props.updateFormData(
                        'secondHandCar',
                        'phoneNumber', 
                        inputValue
                      )  
                    }}
                    fullWidth
                    margin="normal"
                    className="mt-0 mb-2"                    
                    inputProps={{ 
                      maxLength: 10,
                      pattern: "\\d{10}", // Ensure only digits are entered
                    }}
                    helperText={<IntlMessages id="pages.changephone.message.validate.phone" />}
                    error={secondHandCarData.phoneNumber.length > 0 && secondHandCarData.phoneNumber.length < 10}
                  />

                  <TextField
                    type="text"
                    label={
                      <IntlMessages id="pages.secondhandcar.textfield.vinnumber" />
                    }
                    value={secondHandCarData.vinNumber}
                    onChange={(event) => {
                      //this.setState({ vinNumber: event.target.value })
                      this.props.updateFormData(
                        'secondHandCar',
                        'vinNumber', 
                        event.target.value
                      )
                    }}
                    fullWidth
                    margin="normal"
                    className="mt-0 mb-2"
                    inputProps={{ maxLength: 17 }}
                  />
                  <section>
                    <Button
                      variant="contained"
                      onClick={(event) => {
                        if (event) event.preventDefault();

                        if (secondHandCarData.phoneNumber === "") {
                          NotificationManager.warning(
                            <IntlMessages id="notification.warning" />,
                            <IntlMessages id="pages.secondhandcar.message.phonenumberempty" />,
                            3000
                          );
                          return;
                        }
                        if (secondHandCarData.phoneNumber.length !== 10) {
                          NotificationManager.warning(
                            <IntlMessages id="notification.warning" />,
                            <IntlMessages id="pages.changephone.message.validate.phone" />,
                            3000
                          );
                          return;
                        }
                        if (secondHandCarData.vinNumber === "") {
                          NotificationManager.warning(
                            <IntlMessages id="notification.warning" />,
                            <IntlMessages id="pages.secondhandcar.message.vinnumberempty" />,
                            3000
                          );
                          return;
                        }

                        this.props.showGetUserSecondHandCarLoader();
                        this.props.getUserSecondCarRequest(
                          secondHandCarData.phoneNumber,
                          secondHandCarData.vinNumber
                        );
                      }}
                      color="primary"
                    >
                      <IntlMessages id="pages.secondhandcar.button.verify" />
                    </Button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Button
                      variant="contained"
                      onClick={(event) => {
                        if (event) event.preventDefault();

                        this.cancelPress();
                      }}
                      color="secondary"
                    >
                      <IntlMessages id="pages.secondhandcar.button.reset" />
                    </Button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {/* {localStorage.getItem('secondHandCarUserId')} */}
                  </section>
                </form>
              </CardBox>
            </div>
          </div>
        </div>
        {loaderGetUserSecondHandCar && (
          <div className="loader-view">
            <CircularProgress />
          </div>
        )}
        {loaderSecondHandCar && (
          <div className="loader-view">
            <CircularProgress />
          </div>
        )}
        <NotificationContainer />
        {/* {this.state.userId !== ""
          ? this.displayProcessButton(this.props)
          : null} */}
        {localStorage.getItem('secondHandCarUserId') !== ""
          ? this.displayProcessButton(this.props)
          : null}
        {this.showUser(this.props.userSecondHandCarItem)}

        
        {/* {
          <Dialog
            open={this.state.alertOpen}
            // onClose={this.handleRequestClose()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {this.props.locale.locale === "en" ? "Warning" : "คำเตือน"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {this.props.locale.locale === "en" ? "Are you sure you want to permanently remove this car?" : "คุณแน่ใจที่จะลบรถคันนี้ใช่ไหม?"}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={(event) => {
                        if (event) event.preventDefault();

                        this.handleRequestClose();
                        this.secondHandCarPress();
                      }} color="primary" autoFocus>{this.props.locale.locale === "en" ? "Yes" : "ใช่"}</Button>
              <Button onClick={(event) => {
                        if (event) event.preventDefault();

                        this.handleRequestClose();
                      }} color="secondary">
                {this.props.locale.locale === "en" ? "No" : "ไม่ใช่"}
              </Button>
            </DialogActions>
          </Dialog>
          }  */}
        
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  formData: state.secondHandCar,
  langFormData: state.settings.langFormData,
  ...(() => {
    const { secondHandCar, settings } = state; 
    const {
      loaderGetUserSecondHandCar,
      loaderSecondHandCar,
      userSecondHandCarItem,
      isMessageSecondHandCar,
      secondHandCarCode,
    } = secondHandCar;
    const { locale } = settings;

    return {
      loaderGetUserSecondHandCar,
      loaderSecondHandCar,
      userSecondHandCarItem,
      isMessageSecondHandCar,
      secondHandCarCode,
      locale: settings.locale,
    };
  })()
});

export default connect(mapStateToProps, {
  getUserSecondCarRequest,
  secondHandCarRequest,
  showGetUserSecondHandCarLoader,
  showSecondHandCarLoader,
  resetSecondHandCar,
  showMessageSecondHandCar,
  hideMessageSecondHandCar,
  resetPage,
  updateFormData,
})(withTranslatedSwal(SecondHandCar));
