import {
    // RESET_PAGE_STATE,
    // UPDATE_PAGE_STATE,
    RESET_PAGE,
} from 'constants/ActionTypes';
import {
    resetPageSuccess,
} from 'actions/ResetPage';

import { all, takeEvery, put, call, fork } from 'redux-saga/effects';


function* handleResetPage(action) {
    try {
      yield put(resetPageSuccess(action.payload.pageId));
      //yield put(resetPageSuccess());
    } catch (error) {
      console.error('Reset page error:', error);
    }
}
  
export function* watchResetPage() {
    yield takeEvery(RESET_PAGE, handleResetPage);
}

export default function* rootSaga() {
    yield all([fork(watchResetPage)]);
}

/*
// Optional: Add more action types if needed
export const SUBMIT_PAGE_DATA = 'SUBMIT_PAGE_DATA';
export const SUBMIT_PAGE_SUCCESS = 'SUBMIT_PAGE_SUCCESS';
export const SUBMIT_PAGE_ERROR = 'SUBMIT_PAGE_ERROR';

// Helper functions (implement these based on your needs)
const clearPageCache = async (pageId) => {
    // Implement cache clearing logic
};

const logPageReset = async (pageId) => {
    // Implement logging logic
};

const showNotification = async (message, type = 'info') => {
    // Implement notification logic
};

const validatePageData = async (pageId, data) => {
    // Implement validation logic
};

const saveToLocalStorage = async (pageId, data) => {
    localStorage.setItem(`page_${pageId}`, JSON.stringify(data));
};

const submitToFirebase = async (pageId, data) => {
// Implement Firebase submission logic
};

function* handlePageReset(action) {
  try {
    // You can add any cleanup logic here
    const pageId = action.payload;
    
    // Example: Clear any cached data for this page
    yield call(clearPageCache, pageId);
    
    // Example: Log page reset for analytics
    yield call(logPageReset, pageId);
    
    // Example: Show notification
    yield call(showNotification, `${pageId} has been reset`);
  } catch (error) {
    console.error('Error in reset saga:', error);
  }
}

function* handlePageUpdate(action) {
  try {
    const { pageId, data } = action.payload;
    
    // Example: Validate data if needed
    yield call(validatePageData, pageId, data);
    
    // Example: Auto-save to local storage
    yield call(saveToLocalStorage, pageId, data);
  } catch (error) {
    console.error('Error in update saga:', error);
  }
}

function* handlePageSubmit(action) {
  try {
    const { pageId, data } = action.payload;
    
    // Example: Submit to Firebase
    yield call(submitToFirebase, pageId, data);
    
    // On success, dispatch success action
    yield put({ type: SUBMIT_PAGE_SUCCESS, payload: pageId });
    
    // Optional: Reset the page after successful submission
    yield put({ type: RESET_PAGE_STATE, payload: pageId });
    
    // Show success notification
    yield call(showNotification, 'Data submitted successfully');
  } catch (error) {
    yield put({ type: SUBMIT_PAGE_ERROR, payload: { pageId, error } });
    yield call(showNotification, 'Error submitting data', 'error');
  }
}

export function* watchPageActions() {
  yield takeEvery(RESET_PAGE_STATE, handlePageReset);
  yield takeEvery(UPDATE_PAGE_STATE, handlePageUpdate);
  yield takeEvery(SUBMIT_PAGE_DATA, handlePageSubmit);
}

export default function* rootSaga() {
  yield all([fork(watchPageActions)]);
  //yield all([call(fetchRegisterUserCount)]);
  //yield all([takeEvery(GET_REGISTER_USER_COUNT, fetchRegisterUserCount)]);
}
*/