// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'toggle_collapse_menu';
export const DRAWER_TYPE = 'drawer_type';
export const FIXED_DRAWER = 'fixed_drawer';
export const COLLAPSED_DRAWER = 'collapsible';
export const MINI_DRAWER = 'mini_drawer';
export const THEME_COLOR = 'theme_color';
export const DARK_THEME = 'dark_theme';
export const WINDOW_WIDTH = 'window-width';
export const SWITCH_LANGUAGE = 'switch-language';
export const CHANGE_DIRECTION = 'change-direction';

export const CHANGE_NAVIGATION_STYLE = 'change-navigation-style';
export const HORIZONTAL_NAVIGATION = 'horizontal_navigation';
export const VERTICAL_NAVIGATION = 'vertical_navigation';

export const HORIZONTAL_MENU_POSITION = 'horizontal_menu_position';
export const ABOVE_THE_HEADER = 'above_the_header';
export const INSIDE_THE_HEADER = 'inside_the_header';
export const BELOW_THE_HEADER = 'below_the_header';

//Contact Module const

export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';

export const FETCH_ALL_CONTACT = 'fetch_all_contact';
export const FETCH_ALL_CONTACT_SUCCESS = 'fetch_all_contact_success';
export const ADD_FAVOURITE = 'add_favourite';
export const ON_CONTACT_SELECT = 'on_contact_select';
export const ON_ADD_CONTACT = 'on_add_contact';
export const ON_CONTACT_CLOSE = 'on_contact_close';
export const ON_FILTER_OPTION_SELECT = 'on_filter_option_select';
export const ON_SAVE_CONTACT = 'on_save_contact';
export const ON_DELETE_CONTACT = 'on_delete_contact';
export const ON_DELETE_SELECTED_CONTACT = 'on_delete_selected_contact';
export const FILTER_CONTACT = 'filter_contact';
export const GET_ALL_CONTACT = 'get_all_contact';
export const GET_UNSELECTED_ALL_CONTACT = 'get_unselected_all_contact';
export const ON_ALL_CONTACT_SELECT = 'on_all_contact_select';
export const UPDATE_SEARCH_USER = 'update_search_user';
export const ON_TOGGLE_DRAWER = 'on_toggle_drawer';
export const HANDLE_REQUEST_CLOSE = 'handle_request_close';
export const HIDE_CONTACT_LOADER = 'hide_contact_loader';

//Auth const
export const SIGNUP_USER = 'signup_user';
export const SIGNUP_USER_SUCCESS = 'signup_user_success';
export const SIGNIN_GOOGLE_USER = 'signin_google_user';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'signin_google_user_success';
export const SIGNIN_FACEBOOK_USER = 'signin_facebook_user';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'signin_facebook_user_success';
export const SIGNIN_TWITTER_USER = 'signin_twitter_user';
export const SIGNIN_TWITTER_USER_SUCCESS = 'signin_twitter_user_success';
export const SIGNIN_GITHUB_USER = 'signin_github_user';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_SUCCESS';
export const SIGNIN_USER = 'signin_user';
export const SIGNIN_USER_SUCCESS = 'signin_user_success';
export const SIGNOUT_USER = 'signout_user';
export const SIGNOUT_USER_SUCCESS = 'signout_user_success';
export const INIT_URL = 'init_url';
export const SIGNIN_PHONE_USER = 'signin_phone_user';
export const SIGNIN_PHONE_USER_SUCCESS = 'signin_phone_user_success';
export const SIGNIN_OTP_USER = 'signin_otp_user';
export const SIGNIN_OTP_USER_SUCCESS = 'signin_otp_user_success';
export const ON_SHOW_PHONE_LOADER = 'on_show_phone_loader';
export const ON_HIDE_PHONE_LOADER = 'on_hide_phone_loader';

// Chat Module const

export const FETCH_ALL_CHAT_USER = 'fetch_all_chat_user';
export const FETCH_ALL_CHAT_USER_CONVERSATION =
  'fetch_all_chat_user_conversation';
export const FETCH_ALL_CHAT_USER_SUCCESS = 'fetch_all_chat_user_success';
export const FETCH_ALL_CHAT_USER_CONVERSATION_SUCCESS =
  'fetch_all_chat_user_conversation_success';
export const FILTER_USERS = 'filter_users';
export const ON_SELECT_USER = 'on_select_user';
export const ON_SHOW_LOADER = 'on_show_loader';
export const ON_HIDE_LOADER = 'on_hide_loader';
export const USER_INFO_STATE = 'user_info_state';
export const SUBMIT_COMMENT = 'submit_comment';
export const UPDATE_MESSAGE_VALUE = 'update_message_value';
export const UPDATE_SEARCH_CHAT_USER = 'update_search_chat_user';

//// Mail Module const

export const ADD_LABEL = 'ADD_LABEL';
export const GET_ALL_MAIL = 'get_all_mail';
export const FETCH_ALL_MAIL = 'fetch_all_mail';
export const FETCH_ALL_MAIL_SUCCESS = 'fetch_all_mail_success';
export const GET_IMPORTANT_MAIL = 'get_important_mail';
export const GET_NAV_FILTERS = 'get_nav_filters';
export const GET_NAV_LABELS = 'get_nav_labels';
export const GET_NAV_FOLDER = 'get_nav_folder';
export const GET_READ_MAIL = 'get_read_mail';
export const GET_STARRED_MAIL = 'get_starred_mail';
export const GET_UNIMPORTANT_MAIL = 'get_unimportant_mail';
export const GET_UNREAD_MAIL = 'get_unread_mail';
export const GET_UNSELECTED_ALL_MAIL = 'get_unselected_all_mail';
export const GET_UNSTARRED_MAIL = 'get_unstarred_mail';
export const ON_ALL_MAIL_SELECT = 'on_all_mail_select';
export const ON_FOLDER_MENU_ITEM_SELECT = 'on_folder_menu_item_select';
export const ON_FOLDER_SELECT = 'on_folder_select';
export const ON_IMPORTANT_SELECT = 'on_important_select';
export const ON_LABEL_MENU_ITEM_SELECT = 'on_label_menu_item_select';
export const GET_MARK_AS_START = 'get_mark_as_start';
export const ON_LABEL_SELECT = 'on_label_select';
export const ON_MAIL_CHECKED = 'on_mail_checked';
export const ON_MAIL_SELECT = 'on_mail_select';
export const ON_MAIL_SEND = 'on_mail_send';
export const ON_OPTION_MENU_ITEM_SELECT = 'on_option_menu_item_select';
export const ON_OPTION_MENU_SELECT = 'on_option_menu_select';
export const ON_START_SELECT = 'on_start_select';
export const SEARCH_MAIL = 'search_mail';
export const ON_DELETE_MAIL = 'on_delete_mail';
export const SET_CURRENT_MAIL_NULL = 'set_current_mail_null';
export const ON_COMPOSE_MAIL = 'on_compose_mail';

//// TO-DO Module const

export const ON_SORTEND = 'on_sortend';
export const FETCH_ALL_TODO = 'fetch_all_todo';
export const FETCH_ALL_TODO_SUCCESS = 'fetch_all_todo-success';
export const FETCH_ALL_TODO_CONVERSATION = 'fetch_all_todo_conversation';
export const FETCH_ALL_TODO_CONVERSATION_SUCCESS =
  'fetch_all_todo_conversation_success';
export const SELECT_ALL_TODO = 'select_all_todo';
export const GET_ALL_TODO = 'get_all_todo';
export const GET_UNSELECTED_ALL_TODO = 'get_unselected_all_todo';
export const GET_STARRED_TODO = 'get_starred_todo';
export const GET_UNSTARRED_TODO = 'get_unstarred_todo';
export const GET_IMPORTANT_TODO = 'get_important_todo';
export const GET_UNIMPORTANT_TODO = 'get_unimportant_todo';
export const ON_LABEL_UPDATE = 'on_label_update';
export const ON_TODO_UPDATE = 'on_todo_update';
export const ON_DELETE_TODO = 'on_delete_todo';
export const SEARCH_TODO = 'search_todo';
export const SHOW_TODOS = 'show_todos';
export const GET_TODO_CONVERSATION = 'get_todo_conversation';
export const ON_TODO_CHECKED = 'on_todo_checked';
export const ON_TODO_ADD = 'on_todo_add';
export const ON_TODO_SELECT = 'on_todo_select';
export const SET_CURRENT_TODO_NULL = 'set_current_todo_null';
export const REMOVE_LABEL = 'remove_label';
export const UPDATE_SEARCH = 'update_search';

///// Register User Count Module const
export const SET_FIRESTORE = 'set_firestore';
export const GET_REGISTER_USER_COUNT_REQUEST =
  'get_register_user_count_request';
export const GET_REGISTER_USER_COUNT_SUCCESS =
  'get_register_user_count_success';
// export const REQUEST_HELLO_WORLD = 'request_hello_world';
// export const RECEIVE_HELLO_WORLD = 'receive_hello_world';

// Dealers
export const GET_DEALERS_REQUEST = 'get_dealers_request';
export const GET_DEALERS_SUCCESS = 'get_dealers_success';
export const GET_USERS_DEFAULT_DEALERS_REQUEST = 'get_users_default_dealers_request';
export const GET_USERS_DEFAULT_DEALERS_SUCCESS = 'get_users_default_dealers_success';
export const CREATE_DEALER_ACCOUNT_REQUEST = 'create_dealer_account_request';
export const CREATE_DEALER_ACCOUNT_SUCCESS = 'create_dealer_account_success';
export const ADD_DEALER_DATA_REQUEST = 'add_dealer_data_request';
export const ADD_DEALER_DATA_SUCCESS = 'add_dealer_data_success';
export const ON_SHOW_LOADER_CREATE_DEALER_ACCOUNT =
  'on_show_loader_create_dealer_account';
export const ON_HIDE_LOADER_CREATE_DEALER_ACCOUNT =
  'on_hide_loader_create_dealer_account';
export const ON_SHOW_LOADER_ADD_DEALER_DATA = 'on_show_loader_add_dealer_data';
export const ON_HIDE_LOADER_ADD_DEALER_DATA = 'on_hide_loader_add_dealer_data';
export const ON_SHOW_LOADER_USERS_DEFAULT = 'on_show_loader_users_default';
export const ON_HIDE_LOADER_USERS_DEFAULT = 'on_hide_loader_users_default';

// Service Survey
export const GET_RESULT_SERVICE_SURVEY_REQUEST =
  'get_result_service_survey_request';
export const GET_RESULT_SERVICE_SURVEY_SUCCESS =
  'get_result_service_survey_success';
export const GET_RESULT_DEALER_SERVICE_SURVEY_REQUEST =
  'get_result_dealer_service_survey_request';
export const GET_RESULT_DEALER_SERVICE_SURVEY_SUCCESS =
  'get_result_dealer_service_survey_success';
export const GET_RESULT_AREA_SERVICE_SURVEY_REQUEST =
  'get_result_area_service_survey_request';
export const GET_RESULT_AREA_SERVICE_SURVEY_SUCCESS =
  'get_result_area_service_survey_success';
export const GET_RESULT_BY_DEALER_SERVICE_SURVEY_REQUEST =
  'get_result_by_dealer_service_survey_request';
export const GET_RESULT_BY_DEALER_SERVICE_SURVEY_SUCCESS =
  'get_result_by_dealer_service_survey_success';
export const GET_SERVICE_SURVEY_REQUEST = 'get_service_survey_request';
export const GET_SERVICE_SURVEY_SUCCESS = 'get_service_survey_success';
export const GET_SERVICE_SURVEY_BY_DATE_REQUEST =
  'get_service_survey_by_date_request';
export const GET_SERVICE_SURVEY_BY_DATE_SUCCESS =
  'get_service_survey_by_date_success';
export const ON_SHOW_LOADER_SERVICE_SURVEY = 'on_show_loader_service_survey';
export const ON_HIDE_LOADER_SERVICE_SURVEY = 'on_hide_loader_service_survey';

// Users
export const GET_USERS_BY_DATE_REQUEST = 'get_users_by_date_request';
export const GET_USERS_BY_DATE_SUCCESS = 'get_users_by_date_success';
export const GET_USERS_BY_DEALER_REQUEST = 'get_users_by_dealer_request';
export const GET_USERS_BY_DEALER_SUCCESS = 'get_users_by_dealer_success';
export const GET_USERS_BY_DEALER_BY_DATE_REQUEST =
  'get_users_by_dealer_by_date_request';
export const GET_USERS_BY_DEALER_BY_DATE_SUCCESS =
  'get_users_by_dealer_by_date_success';
export const GET_USERS_BY_DEFAULT_DEALER_BY_DATE_REQUEST =
  'get_users_by_default_dealer_by_date_request';
export const GET_USERS_BY_DEFAULT_DEALER_BY_DATE_SUCCESS =
  'get_users_by_default_dealer_by_date_success';
export const GET_FRIENDS_BY_DEALER_REQUEST = 'get_friends_by_dealer_request';
export const GET_FRIENDS_BY_DEALER_SUCCESS = 'get_friends_by_dealer_success';
export const ON_SHOW_LOADER_USERS_BY_DATE = 'on_show_loader_users_by_date';
export const ON_HIDE_LOADER_USERS_BY_DATE = 'on_hide_loader_users_by_date';
export const ON_SHOW_LOADER_USERS_BY_DEALER = 'on_show_loader_users_by_dealer';
export const ON_HIDE_LOADER_USERS_BY_DEALER = 'on_hide_loader_users_by_dealer';
export const ON_SHOW_LOADER_USERS_BY_DEALER_BY_DATE =
  'on_show_loader_users_by_dealer_by_date';
export const ON_HIDE_LOADER_USERS_BY_DEALER_BY_DATE =
  'on_hide_loader_users_by_dealer_by_date';
export const ON_SHOW_LOADER_FRIENDS_BY_DEALER =
  'on_show_loader_friends_by_dealer';
export const ON_HIDE_LOADER_FRIENDS_BY_DEALER =
  'on_hide_loader_friends_by_dealer';

// Chats
export const GET_RECENTS_BY_DEALER_REQUEST = 'get_recents_by_dealer_request';
export const GET_RECENTS_BY_DEALER_SUCCESS = 'get_recents_by_dealer_success';
export const GET_CHAT_HISTORY_BY_CHATROOMID_REQUEST =
  'get_chat_history_by_chatroomid_request';
export const GET_CHAT_HISTORY_BY_CHATROOMID_SUCCESS =
  'get_chat_history_by_chatroomid_success';
export const ON_SHOW_LOADER_RECENTS_BY_DEALER =
  'on_show_loader_recents_by_dealer';
export const ON_HIDE_LOADER_RECENTS_BY_DEALER =
  'on_hide_loader_recents_by_dealer';
export const ON_SHOW_LOADER_CHAT_HISTORY_BY_CHATROOMID =
  'on_show_loader_chat_history_by_chatroomid';
export const ON_HIDE_LOADER_CHAT_HISTORY_BY_CHATROOMID =
  'on_hide_loader_chat_history_by_chatroomid';

// Privilege
// Reward
export const GET_REWARDS_REQUEST = 'get_rewards_request';
export const GET_REWARDS_SUCCESS = 'get_rewards_success';
export const GET_REWARD_BY_ID_REQUEST = 'get_reward_by_id_request';
export const GET_REWARD_BY_ID_SUCCESS = 'get_reward_by_id_success';
export const CREATE_REWARD_REQUEST = 'create_reward_request';
export const CREATE_REWARD_SUCCESS = 'create_reward_success';
export const UPDATE_REWARD_REQUEST = 'update_reward_request';
export const UPDATE_REWARD_SUCCESS = 'update_reward_success';
export const DELETE_REWARD_REQUEST = 'delete_reward_request';
export const DELETE_REWARD_SUCCESS = 'delete_reward_success';
export const CLEAR_REWARD_ITEM = 'clear_reward_item';

export const ON_SHOW_LOADER_GET_REWARDS = 'on_show_loader_get_rewards';
export const ON_HIDE_LOADER_GET_REWARDS = 'on_hide_loader_get_rewards';
export const ON_SHOW_LOADER_GET_REWARD_BY_ID =
  'on_show_loader_get_reward_by_id';
export const ON_HIDE_LOADER_GET_REWARD_BY_ID =
  'on_hide_loader_get_reward_by_id';
export const ON_SHOW_LOADER_CREATE_REWARD = 'on_show_loader_create_reward';
export const ON_HIDE_LOADER_CREATE_REWARD = 'on_hide_loader_create_reward';
export const ON_SHOW_LOADER_UPDATE_REWARD = 'on_show_loader_update_reward';
export const ON_HIDE_LOADER_UPDATE_REWARD = 'on_hide_loader_update_reward';
export const ON_SHOW_LOADER_DELETE_REWARD = 'on_show_loader_delete_reward';
export const ON_HIDE_LOADER_DELETE_REWARD = 'on_hide_loader_delete_reward';
export const SHOW_MESSAGE_REWARD = 'show_message_reward';
export const HIDE_MESSAGE_REWARD = 'hide_message_reward';
export const ON_SHOW_REFRESH_REWARD = 'on_show_refresh_reward';
export const ON_HIDE_REFRESH_REWARD = 'on_hide_refresh_reward';

// Redeem
export const GET_REDEEM_REQUEST = 'get_redeem_request';
export const GET_REDEEM_SUCCESS = 'get_redeem_success';
export const GET_REDEEM_BY_DEALER_REQUEST = 'get_redeem_by_dealer_request';
export const GET_REDEEM_BY_DEALER_SUCCESS = 'get_redeem_by_dealer_success';
export const GET_REDEEM_DELAERLIST_REQUEST = 'get_redeem_dealerlist_request';
export const GET_REDEEM_DEALERLIST_SUCCESS = 'get_redeem_dealerlist_success';
export const ON_SHOW_LOADER_GET_REDEEM = 'on_show_loader_get_redeem';
export const ON_HIDE_LOADER_GET_REDEEM = 'on_hide_loader_get_redeem';
export const ON_SHOW_LOADER_GET_REDEEM_BY_DEALER =
  'on_show_loader_get_redeem_by_dealer';
export const ON_HIDE_LOADER_GET_REDEEM_BY_DEALER =
  'on_hide_loader_get_redeem_by_dealer';
export const ON_SHOW_LOADER_GET_REDEEM_DEALERLIST =
  'on_show_loader_get_redeem_dealerlist';
export const ON_HIDE_LOADER_GET_REDEEM_DEALERLIST =
  'on_hide_loader_get_redeem_dealerlist';

  // Change Phone
  export const GET_API_USER_REQUEST = 'get_api_user_request';
  export const GET_API_USER_SUCCESS = 'get_api_user_success';
  export const GET_USER_BY_OLD_PHONE_REQUEST = 'get_user_by_old_phone_request';
  export const GET_USER_BY_OLD_PHONE_SUCCESS = 'get_user_by_old_phone_success';
  export const GET_USER_BY_NEW_PHONE_REQUEST = 'get_user_by_new_phone_request';
  export const GET_USER_BY_NEW_PHONE_SUCCESS = 'get_user_by_new_phone_success';
  export const ON_SHOW_LOADER_API_USER = 'on_show_loader_api_user';
  export const ON_HIDE_LOADER_API_USER = 'on_hide_loader_api_user';
  export const ON_SHOW_LOADER_OLD_PHONE = 'on_show_loader_old_phone';
  export const ON_HIDE_LOADER_OLD_PHONE = 'on_hide_loader_old_phone';
  export const ON_SHOW_LOADER_NEW_PHONE = 'on_show_loader_new_phone';
  export const ON_HIDE_LOADER_NEW_PHONE = 'on_hide_loader_new_phone';
  export const CHANGE_PHONE_REQUEST = 'change_phone_request';
  export const CHANGE_PHONE_SUCCESS = 'change_phone_success';
  export const ON_SHOW_LOADER_CHANGE_PHONE = 'on_show_loader_change_phone';
  export const ON_HIDE_LOADER_CHANGE_PHONE = 'on_hide_loader_change_phone';
  export const SHOW_MESSAGE_CHANGE_PHONE = 'show_message_change_phone';
  export const HIDE_MESSAGE_CHANGE_PHONE = 'hide_message_change_phone';
  export const RESET_CHANGE_PHONE = 'reset_change_phone';

  // Second Hand Car
  export const GET_USER_SECOND_HAND_CAR_REQUEST = 'get_user_second_hand_car_request';
  export const GET_USER_SECOND_HAND_CAR_SUCCESS = 'get_user_second_hand_car_success';
  export const ON_SHOW_LOADER_GET_USER_SECOND_HAND_CAR = 'on_show_loader_get_user_second_hand_car';
  export const ON_HIDE_LOADER_GET_USER_SECOND_HAND_CAR = 'on_hide_loader_get_user_second_hand_car';
  export const SECOND_HAND_CAR_REQUEST = 'second_hand_car_request';
  export const SECOND_HAND_CAR_SUCCESS = 'second_hand_car_success';
  export const ON_SHOW_LOADER_SECOND_HAND_CAR = 'on_show_loader_second_hand_car';
  export const ON_HIDE_LOADER_SECOND_HAND_CAR = 'on_hide_loader_second_hand_car';
  export const SHOW_MESSAGE_SECOND_HAND_CAR = 'show_message_second_hand_car';
  export const HIDE_MESSAGE_SECOND_HAND_CAR = 'hide_message_second_hand_car';
  export const RESET_SECOND_HAND_CAR = 'reset_second_hand_car';

  // Analytic Users
  export const GET_ANALYTIC_USERS_REQUEST = 'get_analytic_users_request';
  export const GET_ANALYTIC_USERS_SUCCESS = 'get_analytic_users_success';
  export const ON_SHOW_LOADER_GET_ANALYTIC_USERS = 'on_show_loader_get_analytic_users';
  export const ON_HIDE_LOADER_GET_ANALYTIC_USERS = 'on_hide_loader_get_analytic_users';

  // Contact Us
  export const GET_CONTACT_US_REQUEST = 'get_contact_us_request';
  export const GET_CONTACT_US_SUCCESS = 'get_contact_us_success';
  export const ON_SHOW_LOADER_GET_CONTACT_US = 'on_show_loader_get_contact_us';
  export const ON_HIDE_LOADER_GET_CONTACT_US = 'on_hide_loader_get_contact_us';

  // Ranking
  export const GET_RANKING_RESULT_SERVICE_SURVEY_REQUEST = 'get_ranking_result_service_survey_request';
  export const GET_RANKING_RESULT_SERVICE_SURVEY_SUCCESS = 'get_ranking_result_service_survey_success';
  export const GET_RANKING_RESULT_DEALER_SERVICE_SURVEY_REQUEST = 'get_ranking_result_dealer_service_survey_request';
  export const GET_RANKING_RESULT_DEALER_SERVICE_SURVEY_SUCCESS = 'get_ranking_result_dealer_service_survey_success';
  export const GET_RANKING_RESULT_AREA_SERVICE_SURVEY_REQUEST = 'get_ranking_result_area_service_survey_request';
  export const GET_RANKING_RESULT_AREA_SERVICE_SURVEY_SUCCESS = 'get_ranking_result_area_service_survey_success';
  export const ON_SHOW_LOADER_RANKING = 'on_show_loader_ranking';
  export const ON_HIDE_LOADER_RANKING = 'on_hide_loader_ranking';

  // Search User
  export const GET_USER_BY_VIN_REQUEST = 'get_user_by_vin_request';
  export const GET_USER_BY_VIN_SUCCESS = 'get_user_by_vin_success';
  export const ON_SHOW_LOADER_USER_BY_VIN = 'on_show_loader_user_by_vin';
  export const ON_HIDE_LOADER_USER_BY_VIN = 'on_hide_loader_user_by_vin';
  export const GET_CARS_BY_USER_REQUEST = 'get_cars_by_user_request';
  export const GET_CARS_BY_USER_SUCCESS = 'get_cars_by_user_success';
  export const ON_SHOW_LOADER_CARS_BY_USER = 'on_show_loader_cars_by_user';
  export const ON_HIDE_LOADER_CARS_BY_USER = 'on_hide_loader_cars_by_user';
  export const RESET_SEARCH_USER_ITEMS = 'reset_search_user_items';

  // Active Users
  export const GET_ACTIVE_USERS_ALL_REQUEST = 'get_active_users_all_request';
  export const GET_ACTIVE_USERS_ALL_SUCCESS = 'get_active_users_all_success';
  export const ON_SHOW_LOADER_GET_ACTIVE_USERS_ALL = 'on_show_loader_get_active_users_all';
  export const ON_HIDE_LOADER_GET_ACTIVE_USERS_ALL = 'on_hide_loader_get_active_users_all';
  export const GET_USERS_BY_DEFAULT_DEALER_REQUEST = 'get_users_by_default_dealer_request';
  export const GET_USERS_BY_DEFAULT_DEALER_SUCCESS = 'get_users_by_default_dealer_success';
  export const ON_SHOW_LOADER_GET_USERS_BY_DEFAULT_DEALER = 'on_show_loader_get_users_by_default_dealer';
  export const ON_HIDE_LOADER_GET_USERS_BY_DEFAULT_DEALER = 'on_hide_loader_get_users_by_default_dealer';
  export const UPDATE_ACTIVE_USERS_REQUEST = 'update_active_users_request';
  export const UPDATE_ACTIVE_USERS_SUCCESS = 'update_active_users_success';

  // Delete User Data
  export const GET_DELETE_USER_DATA_REQUEST = 'get_delete_user_data_request';
  export const GET_DELETE_USER_DATA_SUCCESS = 'get_delete_user_data_success';
  export const CREATE_DELETE_USER_DATA_REQUEST = 'create_delete_user_data_request';
  export const CREATE_DELETE_USER_DATA_SUCCESS = 'create_delete_user_data_success';
  export const UPDATE_DELETE_USER_DATA_REQUEST = 'update_delete_user_data_request';
  export const UPDATE_DELETE_USER_DATA_SUCCESS = 'update_delete_user_data_success';
  export const ON_SHOW_LOADER_DELETE_USER_DATA = 'on_show_loader_delete_user_data';
  export const ON_HIDE_LOADER_DELETE_USER_DATA = 'on_hide_loader_delete_user_data';
  export const ON_SHOW_LOADER_GET_DELETE_USER_DATA = 'on_show_loader_get_delete_user_data';
  export const ON_HIDE_LOADER_GET_DELETE_USER_DATA = 'on_hide_loader_get_delete_user_data';


  // Campaign Inactive
  export const PROCESS_CAMPAIGN_INACTIVE_REQUEST = 'PROCESS_CAMPAIGN_INACTIVE_REQUEST';
  export const PROCESS_CAMPAIGN_INACTIVE_PROGRESS = 'PROCESS_CAMPAIGN_INACTIVE_PROGRESS';
  export const PROCESS_CAMPAIGN_INACTIVE_SUCCESS = 'PROCESS_CAMPAIGN_INACTIVE_SUCCESS';
  export const PROCESS_CAMPAIGN_INACTIVE_FAILURE = 'PROCESS_CAMPAIGN_INACTIVE_FAILURE';
  export const PROCESS_CAMPAIGN_INACTIVE_CANCEL = 'PROCESS_CAMPAIGN_INACTIVE_CANCEL';

  // Campaign Rank
  export const PROCESS_CAMPAIGN_RANK_REQUEST = 'PROCESS_CAMPAIGN_RANK_REQUEST';
  export const PROCESS_CAMPAIGN_RANK_PROGRESS = 'PROCESS_CAMPAIGN_RANK_PROGRESS';
  export const PROCESS_CAMPAIGN_RANK_SUCCESS = 'PROCESS_CAMPAIGN_RANK_SUCCESS';
  export const PROCESS_CAMPAIGN_RANK_FAILURE = 'PROCESS_CAMPAIGN_RANK_FAILURE';
  export const PROCESS_CAMPAIGN_RANK_CANCEL = 'PROCESS_CAMPAIGN_RANK_CANCEL';

  // Reset Page State
  export const RESET_PAGE_STATE = 'RESET_PAGE_STATE';
  export const UPDATE_PAGE_STATE = 'UPDATE_PAGE_STATE';

  export const RESET_PAGE = 'RESET_PAGE';
  export const RESET_PAGE_SUCCESS = 'RESET_PAGE_SUCCESS';
  export const RESET_PAGE_FAILURE = 'RESET_PAGE_FAILURE';

  export const UPDATE_FORM_DATA = 'UPDATE_FORM_DATA';


  // export const GET_ACTIVE_USERS_BY_DATE_REQUEST = 'get_active_users_by_date_request';
  // export const GET_INACTIVE_USERS_BY_DATE_REQUEST = 'get_inactive_users_by_date_request';
  // export const GET_ACTIVE_USERS_BY_DATE_SUCCESS = 'get_active_users_by_date_success';
  // export const GET_INACTIVE_USERS_BY_DATE_SUCCESS = 'get_inactive_users_by_date_success';
  // export const ON_SHOW_LOADER_GET_ACTIVE_USERS = 'on_show_loader_get_active_users';
  // export const ON_SHOW_LOADER_GET_INACTIVE_USERS = 'on_show_loader_get_inactive_users';
  // export const ON_HIDE_LOADER_GET_ACTIVE_USERS = 'on_hide_loader_get_active_users';
  // export const ON_HIDE_LOADER_GET_INACTIVE_USERS = 'on_hide_loader_get_inactive_users';