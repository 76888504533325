import {
  PROCESS_CAMPAIGN_RANK_REQUEST,
  PROCESS_CAMPAIGN_RANK_PROGRESS,
  PROCESS_CAMPAIGN_RANK_SUCCESS,
  PROCESS_CAMPAIGN_RANK_FAILURE,
  PROCESS_CAMPAIGN_RANK_CANCEL,
} from 'constants/ActionTypes';

const INIT_STATE = {
  isProcessing: false,
  isFinished: false,
  progress: {
    processedCount: 0,
    totalRecords: 0,
    percentage: 0,
  },
  error: null,
};

//export const processingReducer = (state = INIT_STATE, action) => {
//export default function processingReducer(state = INIT_STATE, action) {
export default function campaignRank (state = INIT_STATE, action) {
//   console.log("Processing Reducer received action:", action);
//   console.log("Current state:", state);

  switch (action.type) {
    case PROCESS_CAMPAIGN_RANK_REQUEST: {
      console.log("Handling REQUEST");
      return {
        ...state,
        isProcessing: true,
        isFinished: false,
        error: null,
      };
    }
    case PROCESS_CAMPAIGN_RANK_PROGRESS: {
      console.log("Handling PROGRESS");
      return {
        ...state,
        isProcessing: true, // Make sure this stays true
        isFinished: false,
        progress: action.payload,
      };
    };
    case PROCESS_CAMPAIGN_RANK_SUCCESS: {
      console.log("Handling SUCCESS");
      return {
        ...state,
        isProcessing: false,
        isFinished: true,
      };
    };
    case PROCESS_CAMPAIGN_RANK_FAILURE: {
      console.log("Handling FAILURE");
      return {
        ...state,
        isProcessing: false,
        isFinished: true,
        error: action.payload,
      };
    };
    case PROCESS_CAMPAIGN_RANK_CANCEL: {
      return {
        ...state,
        isProcessing: false,
        isFinished: true,
        error: null,
      };
    };
    default:
      return state;
  }
};
