// Compat Version
// import firebase from 'firebase/compat/app';
// import 'firebase/compat/auth';
// import 'firebase/compat/firestore';
// import 'firebase/compat/app-check';
// import 'firebase/compat/database';
// import ReduxSagaFirebase from 'redux-saga-firebase';
// Compat Version

// Now using firebase version 10.5.0 (Latest version at 13/10/2023 08:15) but using compat style have to update to use modular alter
//https://firebase.google.com/docs/web/modular-upgrade

// Old Version
//import firebase from 'firebase';
//me
//import '@firebase/firestore';

// import 'firebase/firestore'
// import 'firebase/auth'
// import 'firebase/storage'

//import ReduxSagaFirebase from 'redux-saga-firebase';
// Old Version


// Modular Version
import { initializeApp } from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged, GoogleAuthProvider, FacebookAuthProvider, GithubAuthProvider, TwitterAuthProvider } from 'firebase/auth';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { getFirestore, collection, query, where, getDoc, getDocs } from 'firebase/firestore';
import { getDatabase, useDatabaseEmulator, ref, set, onValue } from 'firebase/database';
import ReduxSagaFirebase from 'redux-saga-firebase';

// Modular Version

// Initialize Firebase
const firebaseConfig = {
  apiKey: 'AIzaSyCkwrJO1ej4Qn3pieK33c_7cp9YTtReMy8',
  authDomain: 'hello-suzuki.firebaseapp.com',
  databaseURL: 'https://hello-suzuki.firebaseio.com',
  projectId: 'hello-suzuki',
  storageBucket: 'hello-suzuki.appspot.com',
  messagingSenderId: '754489199656',
  appId: '1:754489199656:web:1d07fae011375e6315391d',
  measurementId: 'G-66H0SEZW8P'
};

const firebase =  initializeApp(firebaseConfig);

//import firebase from 'firebase';
//me
//import '@firebase/firestore';

/*
import { firebase } from '@firebase/app';
import ReduxSagaFirebase from 'firebase-redux-saga';

import '@firebase/auth';
import '@firebase/database';
import '@firebase/storage';
*/

//me use
// import { createStore, combineReducers, compose } from 'redux';
// import { reactReduxFirebase } from 'react-redux-firebase';
// import { reduxFirestore } from 'redux-firestore';
//end use

//const admin = require('firebase-admin');

//const firebaseAuth = rsf.auth;
//const firebaseSocialAuth = firebase.auth();

//Disable for phone testing
//firebase.auth().settings.appVerificationDisabledForTesting = true;
//"firebase": "^7.22.1" before update to 8.9.1

// const app = admin.initializeApp({
//   apiKey: 'AIzaSyCkwrJO1ej4Qn3pieK33c_7cp9YTtReMy8',
//   authDomain: 'hello-suzuki.firebaseapp.com',
//   databaseURL: 'https://hello-suzuki.firebaseio.com',
//   projectId: 'hello-suzuki',
//   storageBucket: 'hello-suzuki.appspot.com',
//   messagingSenderId: '754489199656',
//   appId: '1:754489199656:web:1d07fae011375e6315391d',
//   measurementId: 'G-66H0SEZW8P'
// });
// const rsf = new ReduxSagaFirebase(app);

//me use
// const rrfConfig = {
//   userProfile: 'users',
//   enableLogging: false,
//   updateProfileOnLogin: true,
//   useFirestoreForProfile: true
// };
//end use


//me use
// Add reactReduxFirebase enhancer when making store creator
// const createStoreWithFirebase = compose(
//   reactReduxFirebase(firebase, rrfConfig), // firebase instance as first argument
//   reduxFirestore(firebase) // <- needed if using firestore
// )(createStore);
//end use

// Create initial state
//const initialState = {};

// const rrfProps = {
//   firebase,
//   config: rrfConfig,
//   dispatch: store.dispatch,
//   createFirestoreInstance // <- needed if using firestore
// };

// Create store
//const store = createStoreWithFirebase(rootReducer, initialState);
// const store = createStoreWithFirebase(
//   rootReducer,
//   initialState,
//   compose(
//     reactReduxFirebase(firebase) //,
//     //window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
//   )
// );
//end me



//===========================================  Start Compat Version  ==============================================================

// //me
//const firebaseApp = firebase.initializeApp(config);
//const rsf = new ReduxSagaFirebase(firebase.initializeApp(firebaseConfig));

const rsf = new ReduxSagaFirebase(firebase);

// Initialize other services on firebase instance
//firebase.firestore(); // <- needed if using firestore
//firebase.functions(); // <- needed if using httpsCallable

// const database = firebase.database();
const db = getFirestore(firebase)
const database = getDatabase(firebase)
const auth = getAuth(firebase)

// const appCheck = firebase.appCheck();

// appCheck.activate('6Lf1eq4lAAAAAMxWpTTOv5splAM9fx3xTAeRfAYZ', true);

//firebase.initializeApp(config);
//const auth = firebase.auth();

// const settings = FirestoreSettings.GetDefault();
// const timing = CallTiming.FromTimeout(TimeSpan.FromSeconds(10));

// settings.WriteSettings = settings.WriteSettings.WithCallTiming(timing);

// const client = FirestoreClient.Create(settings: settings);

// const db = FirestoreDb.Create(client: client);

//const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

const googleAuthProvider = new GoogleAuthProvider();
const facebookAuthProvider = new FacebookAuthProvider();
const githubAuthProvider = new GithubAuthProvider();
const twitterAuthProvider = new TwitterAuthProvider();




//===========================================  End Compat Version  =================================================================

//===========================================  Start Modular Version  ==============================================================


//https://modularfirebase.web.app/common-use-cases/authentication/

// const firebaseApp = initializeApp(config);

// initializeAppCheck(firebaseApp, {
//   provider: new ReCaptchaV3Provider(
//     '6Lf1eq4lAAAAAMxWpTTOv5splAM9fx3xTAeRfAYZ'
//   ),
//   isTokenAutoRefreshEnabled: true,
// });

// const rsf = new ReduxSagaFirebase(firebaseApp);
// const db = getFirestore(firebaseApp);
// const auth = getAuth(firebaseApp);
// const database = getDatabase(firebaseApp);

// const googleAuthProvider = new GoogleAuthProvider();
// const facebookAuthProvider = new FacebookAuthProvider();
// const githubAuthProvider = new GithubAuthProvider();
// const twitterAuthProvider = new TwitterAuthProvider();


//============================================  End Modular Version  ==============================================================

export {
  // createStoreWithFirebase,
  firebase,
  db,
  //firestore,
  rsf,
  auth,
  database,
  googleAuthProvider,
  githubAuthProvider,
  facebookAuthProvider,
  twitterAuthProvider
};
