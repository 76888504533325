import { all, call, fork, put, takeEvery } from "redux-saga/effects";
//import firebase from "firebase";
//import firebase from 'firebase/compat/app';
//import 'firebase/compat/auth';
import {
  auth,
  facebookAuthProvider,
  githubAuthProvider,
  googleAuthProvider,
  twitterAuthProvider,
} from "../firebase/firebase";
import { getAuth, deleteUser, signInWithEmailAndPassword, signInWithPhoneNumber, createUserWithEmailAndPassword, RecaptchaVerifier, onAuthStateChanged, GoogleAuthProvider, FacebookAuthProvider, GithubAuthProvider, TwitterAuthProvider } from 'firebase/auth';
import {
  SIGNIN_FACEBOOK_USER,
  SIGNIN_GITHUB_USER,
  SIGNIN_GOOGLE_USER,
  SIGNIN_TWITTER_USER,
  SIGNIN_USER,
  SIGNOUT_USER,
  SIGNUP_USER,
  SIGNIN_PHONE_USER,
  SIGNIN_OTP_USER,
} from "constants/ActionTypes";
import {
  showAuthMessage,
  userSignInSuccess,
  userSignOutSuccess,
  userSignUpSuccess,
  userPhoneSignInSuccess,
  userOtpSignInSuccess,
} from "actions/Auth";
import {
  userFacebookSignInSuccess,
  userGithubSignInSuccess,
  userGoogleSignInSuccess,
  userTwitterSignInSuccess,
} from "../actions/Auth";
import { getFirestore, collection, query, where, doc, getDoc, getDocs } from 'firebase/firestore';
import { rsf, firebase, db } from '../firebase/firebase';

/*
reCAPTCHA Key V3
site key:   6Lf1eq4lAAAAAMxWpTTOv5splAM9fx3xTAeRfAYZ
secret key: 7IZ0F4H09QfSn4GFiCI
*/

// Initialize reCAPTCHA
const initializeRecaptcha = () => {
  const auth = getAuth();
  const recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
    size: 'normal',
    callback: (response) => {
      // reCAPTCHA solved - enable sign-in button
      console.log('reCAPTCHA verified:' + JSON.stringify(response));
    },
    'expired-callback': () => {
      // Reset reCAPTCHA
      console.log('reCAPTCHA expired');
    }
  });
  return recaptchaVerifier;
};

// NEW
const createUserWithEmailPasswordRequest = async (email, password) => {
  //const auth = getAuth();
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    return userCredential;
  } catch (error) {
    throw error;
  }
};

// OLD
// const createUserWithEmailPasswordRequest = async (email, password) =>
//   await auth
//     .createUserWithEmailAndPassword(email, password)
//     .then((authUser) => authUser)
//     .catch((error) => error);

// NEW
const signInUserWithEmailPasswordRequest = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    return userCredential;
  } catch (error) {
    throw error;
  }
}

// OLD
// const signInUserWithEmailPasswordRequest = async (email, password) =>
//   await auth
//     .signInWithEmailAndPassword(email, password)
//     .then((authUser) => authUser)
//     .catch((error) => error);

// NEW
const signOutRequest = async () => {  
  try {
    // const userCredential = await signOut(auth);
    // return userCredential;
    await signOut(auth);
    return true;
  } catch (error) {
    throw error;
  }
};

// OLD
// const signOutRequest = async () =>
//   await auth
//     .signOut()
//     .then((authUser) => authUser)
//     .catch((error) => error);


// NEW
// Send OTP - Async/await version
const signInUserWithPhoneNumberRequest = async (phoneNumber, applicationVerifier) => {
  //const auth = getAuth();
  try {
    const confirmationResult = await signInWithPhoneNumber(
      auth, 
      phoneNumber, 
      applicationVerifier
    );
    return confirmationResult;
  } catch (error) {
    throw error;
  }
};

// NEW
// Verify OTP - Async/await version
const verifyOtpRequest = async (confirmationResult, code) => {
  try {
    const userCredential = await confirmationResult.confirm(code);
    return userCredential;
  } catch (error) {
    throw error;
  }
};

// OLD
// const signInUserWithPhoneNumberRequest = async (
//   phoneNumber,
//   applicationVerifier
// ) =>
//   await auth
//     .signInWithPhoneNumber(phoneNumber, applicationVerifier)
//     .then((confirmationResult) => confirmationResult)
//     .catch((error) => error);

    // OLD
// const verifyOtpRequest = async (confirmationResult, code) =>
//   await confirmationResult
//     .confirm(code)
//     .then((result) => result)
//     .catch((error) => error);

const signInUserWithGoogleRequest = async () =>
  await auth
    .signInWithPopup(googleAuthProvider)
    .then((authUser) => authUser)
    .catch((error) => error);

const signInUserWithFacebookRequest = async () =>
  await auth
    .signInWithPopup(facebookAuthProvider)
    .then((authUser) => authUser)
    .catch((error) => error);

const signInUserWithGithubRequest = async () =>
  await auth
    .signInWithPopup(githubAuthProvider)
    .then((authUser) => authUser)
    .catch((error) => error);

const signInUserWithTwitterRequest = async () =>
  await auth
    .signInWithPopup(twitterAuthProvider)
    .then((authUser) => authUser)
    .catch((error) => error);


// NEW
const deleteUserRequest = async () => {
  //const auth = getAuth();
  try {
    if (!auth.currentUser) {
      throw new Error('No user currently logged in');
    }
    await deleteUser(auth.currentUser);
    return true;
  } catch (error) {
    throw error;
  }
};

// OLD
// const deleteUserRequest = async () =>
//   await auth
//     .currentUser.delete()
//     .then((result) => result)
//     .catch((error) => error);


// const deleteUserRequest = async (user) =>
//   await auth
//     .deleteUser(user)
//     .then((result) => result)
//     .catch((error) => error);


// const confirmRequest = async (confirmationResult, code) =>
//   await confirmationResult.confirm(code)
//   .then(result.user => authUser)
//   .catch(error => error)

/*
    var applicationVerifier = new firebase.auth.RecaptchaVerifier(
      *     'recaptcha-container');
      * firebase.auth().signInWithPhoneNumber(phoneNumber, applicationVerifier)
      *     .then(function(confirmationResult) {
      *       var verificationCode = window.prompt('Please enter the verification ' +
      *           'code that was sent to your mobile device.');
      *       return confirmationResult.confirm(verificationCode);
      *     })
      *     .catch(function(error) {
      *       // Handle Errors here.
      *     });
      * */

function* signInUserWithPhoneNumber({ payload }) {
  const { phoneNumber, appVerifier } = payload;
  console.log("phoneNumber2:" + phoneNumber);
  //console.log("appVerifier2:" + JSON.stringify(appVerifier));
  //console.log("appVerifier2:" + appVerifier);
  //const applicationVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container');

  try {
    //const confirmationResult = yield call(rsf.auth.signInWithPhoneNumber, phoneNumber, appVerifier);

    // Initialize reCAPTCHA 
    // NO Call this in page and saga
    //const recaptchaVerifier = yield call(initializeRecaptcha);

    // Send OTP
    // const confirmationResult = yield call(
    //   signInWithPhoneNumber,
    //   auth,
    //   phoneNumber,
    //   appVerifier
    // );

    const confirmationResult = yield call(
      signInUserWithPhoneNumberRequest,
      phoneNumber,
      appVerifier
    ); //also working

    // const confirmationResult = {
    //   'verificationId' : 'AD8T5IuhjHPtKlnl85EjvuX4EXfeXSvVRwt7wcd9BtAbk5qsfzKZZd-FM2_EPDEaetNnfdEk8-BhutzL_mtBjNGM4v0XfxWtrKyl71EyhmvaahIbp_rkqUS21E4V2WZqMBkJyRkLHXPdlB6nQ3fP3WTkhVPgkmUlnA'
    // }

    window.confirmationResult = confirmationResult;

    //console.log("confirmationResult:" + JSON.stringify(confirmationResult));
    //console.log('confirmationResultMessage:' + confirmationResult.message);

    // if (confirmationResult.message) {
    //   console.log('11');
    //   //yield put(showAuthMessage(confirmationResult.message));
    // } else {
    //   console.log('22');
    // }

    if (confirmationResult.message) {
      yield put(showAuthMessage(confirmationResult.message));
    } else if (confirmationResult.verificationId !== undefined) {
      //console.log("id:" + confirmationResult.verificationId);

      //const credentials = yield call(confirmationResult.confirm, "123456");
      //console.log('credentials:' + JSON.stringify(credentials));

      yield put(userPhoneSignInSuccess(confirmationResult));
    } else {
      yield put(showAuthMessage("เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง!"));
    }

    //yield put(userPhoneSignInSuccess(confirmationResult));

    // if (confirmationResult.message) {
    //   yield put(showAuthMessage(confirmationResult.message));
    // } else {
    //   let role = 'userHaveCar';
    //   localStorage.setItem('user_id', confirmationResult.user.uid);
    //   localStorage.setItem('role', role);
    //     var items = {
    //       uid: signInUser.user.uid,
    //       role: role
    //     };
    //   yield put(userPhoneSignInSuccess(items));
    // }
  } catch (error) {
    console.log("error:" + JSON.stringify(error));

    if (error.code === 'auth/invalid-app-credential') {
      if (window.recaptchaVerifier) {
        window.recaptchaVerifier.clear();
      }
      yield put(showAuthMessage('Invalid reCAPTCHA response! Please try again.'));
    } else if (error.code === 'auth/invalid-phone-number') {
      yield put(showAuthMessage('Invalid phone number format!'));
    } else if (error.code === 'auth/quota-exceeded') {
      yield put(showAuthMessage('SMS quota exceeded! Please try again later.'));
    } else if (error.code === 'auth/argument-error') {
      yield put(showAuthMessage('Argument error!'));
    } else if (error.code === 'auth/user-not-found') {
      yield put(showAuthMessage('No users are found!'));
    } else if (error.code === 'auth/invalid-email') {
      yield put(showAuthMessage('Invalid Email!'));
    } else {
      yield put(showAuthMessage(error.code));
    }
  }
}

function* signInUserWithOTP({ payload }) {
  const { confirmationResult, code } = payload;

  //console.log("confirmationResultOTP:" + JSON.stringify(confirmationResult));
  //console.log("code:" + code);

  try {
    //const credential = rsf.auth.PhoneAuthProvider.credential(confirmationResult.verificationId, code);
    //console.log('credential:' + JSON.stringify(credential));

    const signInUser = yield call(verifyOtpRequest, confirmationResult, code);
    //console.log("signInUser:" + JSON.stringify(signInUser));
    //console.log('signInUser.user:' + JSON.stringify(signInUser.user));
    //console.log('uid:' + signInUser.user.uid);

    if (signInUser.code === 'auth/invalid-verification-code') {
      // Wrong OTP
      //console.log('Wrong OTP');
      yield put(showAuthMessage("รหัส OTP ไม่ถูกต้อง"));
    } else {
      if (signInUser.user.uid !== undefined) {

        // const snapshot = yield call(
        //   rsf.firestore.getDocument,
        //   "UsersThailand/" + signInUser.user.uid
        // );


        const docRef = doc(db, 'UsersThailand', signInUser.user.uid);
                
        const snapshot = yield call(getDoc, docRef);

        
        //console.log('snapshot:' + JSON.stringify(snapshot.data()));
  
        if (snapshot.empty) {
          const isDeleted = yield call(deleteUserRequest);
          console.log('isDeleted1:' + isDeleted);

          //yield put(showAuthMessage("Incorrect User1"));
          yield put(showAuthMessage("You have no account yet!"));
        } else {
          if (snapshot.data() === undefined) {
            //delete user

            // Phone and Pin correct but no account
  
            const isDeleted = yield call(deleteUserRequest);
            console.log('isDeleted2:' + isDeleted);

            if (isDeleted) {
              console.log('deleted');
            } else {
              console.log('not delete');
            }
  
            yield put(showAuthMessage("You have no account yet!"));
  
            // auth.currentUser.delete()
            // .then(() => {
            //   console.log('user deleted');
            //   yield put(showAuthMessage("Incorrect User2"));
            // })
            // .catch((error) => {
            //   console.log('error:' + error);
            //   yield put(showAuthMessage("Incorrect User9"));
            // });
  
            // signInUser.user.delete()
            // .then(() => {
            //   console.log('user deleted');
            // })
            // .catch((error) => {
            //   console.log('error:' + error);
            // });
            //const deleteUser = yield call(deleteUserRequest, signInUser.user);
            //console.log('deleteUser:' + JSON.stringify(deleteUser));
            // auth
            // .deleteUser(signInUser.user)
            // .then(() => {
            //   console.log('user deleted');
            // })
            // .catch((error) => {
            //   console.log('error:' + error);
            // });
            
          } else if (!snapshot.data().active) {          
            const isDeleted = yield call(deleteUserRequest);
            console.log('isDeleted2:' + isDeleted);
            yield put(showAuthMessage("Your account has been inactived!"));
          } else {
            //console.log('success');
            //yield put(showAuthMessage("Incorrect User9"));
  
            let role = "userHaveCar";
            const user = snapshot.data();
            localStorage.setItem("user_id", signInUser.user.uid);
            localStorage.setItem("role", role);
            localStorage.setItem("name_th", user.fullname);
            localStorage.setItem("name_en", user.lastname);
            localStorage.setItem("shorth_name", user.firstname);
            localStorage.setItem("avatar", user.avatar);
            localStorage.setItem("model", "");
            var items = {
              uid: signInUser.user.uid,
              role: role,
              name_th: user.fullname,
              name_en: user.lastname,
              shorth_name: user.firstname,
              avatar: user.avatar,
              model: "",
            };
  
            yield put(userSignInSuccess(items));
          }
        }
        
      } else {
        //error      
        const isDeleted = yield call(deleteUserRequest);
        console.log('isDeleted4:' + isDeleted);
        yield put(showAuthMessage("Incorrect User!"));
      }
    }
  } catch (error) {
    console.log("error:" + error);
    // const isDeleted = yield call(deleteUserRequest);
    // console.log('isDeleted5:' + isDeleted);

    if (error.code === 'auth/requires-recent-login') {
      yield put(showAuthMessage('Required user log in!'));
    } else if (error.code === 'auth/user-not-found') {
      yield put(showAuthMessage('User not found!'));    
    } else {
      yield put(showAuthMessage("Incorrect User!"));
    }
  }
}

function* createUserWithEmailPassword({ payload }) {
  const { email, password } = payload;
  try {
    const signUpUser = yield call(
      createUserWithEmailPasswordRequest,
      email,
      password
    );
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem("user_id", signUpUser.user.uid);
      yield put(userSignUpSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    //yield put(showAuthMessage(error));
    if (error.code === 'auth/email-already-in-use') {
      yield put(showAuthMessage('Email is already registered!'));
    } else if (error.code === 'auth/invalid-email') {
      yield put(showAuthMessage('Invalid email format!'));
    }  else if (error.code === 'auth/operation-not-allowed') {
      yield put(showAuthMessage('Email/password accounts are not enabled!'));
    }  else if (error.code === 'auth/weak-password') {
      yield put(showAuthMessage('Password is too weak!'));
    } else {
      yield put(showAuthMessage(error.code));
    }
  }
}

function* signInUserWithGoogle() {
  try {
    const signUpUser = yield call(signInUserWithGoogleRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem("user_id", signUpUser.user.uid);
      yield put(userGoogleSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithFacebook() {
  try {
    const signUpUser = yield call(signInUserWithFacebookRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem("user_id", signUpUser.user.uid);
      yield put(userFacebookSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithGithub() {
  try {
    const signUpUser = yield call(signInUserWithGithubRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem("user_id", signUpUser.user.uid);
      yield put(userGithubSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithTwitter() {
  try {
    const signUpUser = yield call(signInUserWithTwitterRequest);
    if (signUpUser.message) {
      if (signUpUser.message.length > 100) {
        yield put(showAuthMessage("Your request has been canceled."));
      } else {
        yield put(showAuthMessage(signUpUser.message));
      }
    } else {
      localStorage.setItem("user_id", signUpUser.user.uid);
      yield put(userTwitterSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithEmailPassword({ payload }) {
  const { email, password } = payload;
  console.log("signInWithEmail:" + email + " password:" + password);
  var role = "";
  try {
    if (email.includes("999999@suzuki.co.th")) {
      yield put(showAuthMessage("Incorrect User"));
    } else if (email.includes("@suzuki.co.th")) {
      const signInUser = yield call(
        signInUserWithEmailPasswordRequest,
        email,
        password
      );

      //NEW
      // const signInUser = yield call(
      //   signInUserWithEmailPassword,
      //   auth,
      //   email,
      //   password
      // );

      //console.log('authUser:' + JSON.stringify(signInUser));

      if (signInUser.message) {
        yield put(showAuthMessage(signInUser.message));
      } else {
        if (email.includes("smt")) {
          //console.log('AAAA1');
          role = "smt";
          localStorage.setItem("user_id", signInUser.user.uid);
          localStorage.setItem("role", role);
          localStorage.setItem("name_th", "Suzuki Motor Thailand");
          localStorage.setItem("name_en", "Suzuki Motor Thailand");
          localStorage.setItem("shorth_name", "Suzuki Motor Thailand");
          localStorage.setItem(
            "avatar",
            "https://firebasestorage.googleapis.com/v0/b/hello-suzuki.appspot.com/o/AvatarDealersThailand%2F988888.jpg?alt=media&token=fad9f6f6-ceff-4709-a8af-c86fb6d62f99"
          );
          localStorage.setItem("model", "");
          var items = {
            uid: signInUser.user.uid,
            role: role,
            name_th: "Suzuki Motor Thailand",
            name_en: "Suzuki Motor Thailand",
            shorth_name: "Suzuki Motor Thailand",
            avatar: "https://firebasestorage.googleapis.com/v0/b/hello-suzuki.appspot.com/o/AvatarDealersThailand%2F988888.jpg?alt=media&token=fad9f6f6-ceff-4709-a8af-c86fb6d62f99",
            model: "",
          };

          yield put(userSignInSuccess(items));
        } else if (email.includes("tadaomis@suzuki.co.th")) {
          //console.log('AAAA2');
          role = "smt";
          localStorage.setItem("user_id", signInUser.user.uid);
          localStorage.setItem("role", role);
          localStorage.setItem("name_th", "Mr.Tadaomi Suzuki");
          localStorage.setItem("name_en", "Mr.Tadaomi Suzuki");
          localStorage.setItem("shorth_name", "Mr.Tadaomi Suzuki");
          localStorage.setItem(
            "avatar",
            "https://firebasestorage.googleapis.com:443/v0/b/hello-suzuki.appspot.com/o/ProfilePhotoThailand%2FvfEeCdRsKAaLHhpDeeLngjSYTn32.jpg?alt=media&token=ac829255-198c-4041-b6bd-f2a88ce3f039"
          );
          localStorage.setItem("model", "");
          var items = {
            uid: signInUser.user.uid,
            role: role,
            name_th: "Mr.Tadaomi Suzuki",
            name_en: "Mr.Tadaomi Suzuki",
            shorth_name: "Mr.Tadaomi Suzuki",
            avatar: "https://firebasestorage.googleapis.com:443/v0/b/hello-suzuki.appspot.com/o/ProfilePhotoThailand%2FvfEeCdRsKAaLHhpDeeLngjSYTn32.jpg?alt=media&token=ac829255-198c-4041-b6bd-f2a88ce3f039",
            model: "",
          };
          //https://firebasestorage.googleapis.com/v0/b/hello-suzuki.appspot.com/o/AvatarDealersThailand%2Ftadaomis.jpg?alt=media&token=7e6e94f6-ac52-497d-adcf-d59ca83fe709

          yield put(userSignInSuccess(items));
        } else if (email.includes("888888")) {
          role = "superAdmin";
          localStorage.setItem("user_id", signInUser.user.uid);
          localStorage.setItem("role", role);
          localStorage.setItem("name_th", "Super Admin");
          localStorage.setItem("name_en", "Super Admin");
          localStorage.setItem("shorth_name", "Super Admin");
          localStorage.setItem(
            "avatar",
            "https://firebasestorage.googleapis.com/v0/b/hello-suzuki.appspot.com/o/AvatarDealersThailand%2F988888.jpg?alt=media&token=fad9f6f6-ceff-4709-a8af-c86fb6d62f99"
          );
          localStorage.setItem("model", "");
          var items = {
            uid: signInUser.user.uid,
            role: role,
            name_th: "Super Admin",
            name_en: "Super Admin",
            shorth_name: "Super Admin",
            avatar: "https://firebasestorage.googleapis.com/v0/b/hello-suzuki.appspot.com/o/AvatarDealersThailand%2F988888.jpg?alt=media&token=fad9f6f6-ceff-4709-a8af-c86fb6d62f99",
            model: "",
          };

          yield put(userSignInSuccess(items));
        } else {
          role = email.substring(0, 6);

          let dealerCode = email.substring(0, 6);

          // const snapshot = yield call(
          //   rsf.firestore.getDocument,
          //   "DealersThailand/" + dealerCode
          // );

          const docRef = doc(db, 'DealersThailand', dealerCode);
                
          const snapshot = yield call(getDoc, docRef);

          if (snapshot.empty) {
            //console.log("C1:");
            yield put(showAuthMessage("Incorrect Dealer"));
          } else {
            //console.log("C2:");
            //console.log("dealerNameTh:" + snapshot.data().dealerNameTh);
            //console.log("dealerNameEn:" + snapshot.data().dealerNameEn);
            //console.log("snapshot:" + snapshot.data());
            const dealer = snapshot.data();
            localStorage.setItem("user_id", signInUser.user.uid);
            localStorage.setItem("role", role);
            localStorage.setItem("name_th", snapshot.data().dealerNameTh);
            localStorage.setItem("name_en", snapshot.data().dealerNameEn);
            localStorage.setItem(
              "shorth_name",
              snapshot.data().dealerShortNameEn
            );
            localStorage.setItem("avatar", snapshot.data().avatar);
            localStorage.setItem("model", "");
            var items = {
              uid: signInUser.user.uid,
              role: role,
              name_th: snapshot.data().dealerNameTh,
              name_en: snapshot.data().dealerNameEn,
              shorth_name: snapshot.data().dealerShortNameEn,
              avatar: dealer.avatar,
              model: "",
            };

            // console.log(
            //   "uid:" +
            //     signInUser.user.uid +
            //     " role:" +
            //     role +
            //     " name_th:" +
            //     snapshot.data().dealerNameTh +
            //     " name_en:" +
            //     snapshot.data().dealerNameEn +
            //     " short:" +
            //     snapshot.data().dealerShortNameEn
            // );

            yield put(userSignInSuccess(items));
          }
        }
        //yield put(userSignInSuccess(signInUser.user.uid));
      }
    } else {
      //console.log("A3:");
      //yield put(showAuthMessage('Incorrect User'));

      const signInUser = yield call(
        signInUserWithEmailPasswordRequest,
        email,
        password
      );

      //console.log("messageeee:" + signInUser.message);

      if (signInUser.message) {
        yield put(showAuthMessage(signInUser.message));
      } else {
        // const snapshot = yield call(
        //   rsf.firestore.getDocument,
        //   "UsersThailand/" + signInUser.user.uid
        // );

        const docRef = doc(db, 'UsersThailand', signInUser.user.uid);
                
        const snapshot = yield call(getDoc, docRef);

        if (snapshot.empty) {
          yield put(showAuthMessage("Incorrect User"));
        } else if (!snapshot.data().active) {
          yield put(showAuthMessage("Incorrect User"));
        } else {
          let role = "userNoCar";
          const user = snapshot.data();
          localStorage.setItem("user_id", signInUser.user.uid);
          localStorage.setItem("role", role);
          localStorage.setItem("name_th", user.fullname);
          localStorage.setItem("name_en", user.lastname);
          localStorage.setItem("shorth_name", user.firstname);
          localStorage.setItem("avatar", user.avatar);
          localStorage.setItem("model", "");
          var items = {
            uid: signInUser.user.uid,
            role: role,
            name_th: user.fullname,
            name_en: user.lastname,
            shorth_name: user.firstname,
            avatar: user.avatar,
            model: "",
          };

          // console.log(
          //   "uid:" +
          //     signInUser.user.uid +
          //     " role:" +
          //     role +
          //     " name_th:" +
          //     user.fullname +
          //     " name_en:" +
          //     user.lastname +
          //     " short:" +
          //     user.firstname
          // );

          yield put(userSignInSuccess(items));
        }
      }
    }
    //
    // const signInUser = yield call(
    //   signInUserWithEmailPasswordRequest,
    //   email,
    //   password
    // );
    // if (signInUser.message) {
    //   yield put(showAuthMessage(signInUser.message));
    // } else {
    //   localStorage.setItem('user_id', signInUser.user.uid);
    //   localStorage.setItem('role', 'smt');
    //   items = {
    //     uid: signInUser.user.uid,
    //     role:
    //   }
    //   yield put(userSignInSuccess(signInUser.user.uid));
    //   //yield put(userSignInSuccess(signInUser.user.uid));
    // }
  } catch (error) {
    // case 'auth/user-not-found':
    //     throw new Error('No user found with this email');
    //   case 'auth/wrong-password':
    //     throw new Error('Incorrect password');
    //   case 'auth/invalid-email':
    //     throw new Error('Invalid email format');
    //   case 'auth/user-disabled':
    //     throw new Error('This account has been disabled');
    //   case 'auth/too-many-requests':
    //     throw new Error('Too many failed attempts. Try again later');

    console.log("eeerrrrr:" + JSON.stringify(error));
    //yield put(showAuthMessage(error));
    if (error.code === 'auth/user-not-found') {
      yield put(showAuthMessage('No user found with this email!'));
    } else if (error.code === 'auth/wrong-password') {
      yield put(showAuthMessage('Incorrect password!'));
    } else if (error.code === 'auth/invalid-email') {
      yield put(showAuthMessage('Invalid email format!'));
    } else if (error.code === 'auth/user-disabled') {
      yield put(showAuthMessage('This account has been disabled!'));
    } else if (error.code === 'auth/too-many-requests') {
      yield put(showAuthMessage('Too many failed attempts. Try again later!'));
    } else {
      yield put(showAuthMessage(error.code));
    }
  }
}

function* signOut() {
  try {
    const signOutUser = yield call(signOutRequest);
    //console.log('signOutUser:' + JSON.stringify(signOutUser));
    //if (signOutUser === undefined) {
    if (signOutUser) {
      localStorage.removeItem("user_id");
      localStorage.removeItem("role");
      localStorage.removeItem("name_th");
      localStorage.removeItem("name_en");
      localStorage.removeItem("shorth_name");
      localStorage.removeItem("avatar");
      localStorage.removeItem("model");
      sessionStorage.clear();
      yield put(userSignOutSuccess(signOutUser));
    } else {
      yield put(showAuthMessage(signOutUser.message));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

export function* signInWithPhone() {
  yield takeEvery(SIGNIN_PHONE_USER, signInUserWithPhoneNumber);
}

export function* signInWithOTP() {
  yield takeEvery(SIGNIN_OTP_USER, signInUserWithOTP);
}

export function* createUserAccount() {
  yield takeEvery(SIGNUP_USER, createUserWithEmailPassword);
}

export function* signInWithGoogle() {
  yield takeEvery(SIGNIN_GOOGLE_USER, signInUserWithGoogle);
}

export function* signInWithFacebook() {
  yield takeEvery(SIGNIN_FACEBOOK_USER, signInUserWithFacebook);
}

export function* signInWithTwitter() {
  yield takeEvery(SIGNIN_TWITTER_USER, signInUserWithTwitter);
}

export function* signInWithGithub() {
  yield takeEvery(SIGNIN_GITHUB_USER, signInUserWithGithub);
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export default function* rootSaga() {
  yield all([
    fork(signInWithPhone),
    fork(signInWithOTP),
    fork(signInUser),
    fork(createUserAccount),
    fork(signInWithGoogle),
    fork(signInWithFacebook),
    fork(signInWithTwitter),
    fork(signInWithGithub),
    fork(signOutUser),
  ]);
}
