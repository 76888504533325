import {
  PROCESS_CAMPAIGN_INACTIVE_REQUEST,
  PROCESS_CAMPAIGN_INACTIVE_PROGRESS,
  PROCESS_CAMPAIGN_INACTIVE_SUCCESS,
  PROCESS_CAMPAIGN_INACTIVE_FAILURE,
  PROCESS_CAMPAIGN_INACTIVE_CANCEL,
} from 'constants/ActionTypes';

const INIT_STATE = {
  isProcessing: false,
  isFinished: false,
  progress: {
    processedCount: 0,
    totalRecords: 0,
    percentage: 0,
  },
  error: null,
};

//export const processingReducer = (state = INIT_STATE, action) => {
//export default function processingReducer(state = INIT_STATE, action) {
export default function campaignInactive (state = INIT_STATE, action) {
//   console.log("Processing Reducer received action:", action);
//   console.log("Current state:", state);

  switch (action.type) {
    case PROCESS_CAMPAIGN_INACTIVE_REQUEST: {
      console.log("Handling REQUEST");
      return {
        ...state,
        isProcessing: true,
        isFinished: false,
        error: null,
      };
    }
    case PROCESS_CAMPAIGN_INACTIVE_PROGRESS: {
      console.log("Handling PROGRESS");
      return {
        ...state,
        isProcessing: true, // Make sure this stays true
        isFinished: false,
        progress: action.payload,
      };
    };
    case PROCESS_CAMPAIGN_INACTIVE_SUCCESS: {
      console.log("Handling SUCCESS");
      return {
        ...state,
        isProcessing: false,
        isFinished: true,
      };
    };
    case PROCESS_CAMPAIGN_INACTIVE_FAILURE: {
      console.log("Handling FAILURE");
      return {
        ...state,
        isProcessing: false,
        isFinished: true,
        error: action.payload,
      };
    };
    case PROCESS_CAMPAIGN_INACTIVE_CANCEL: { //"CANCEL_PROCESSING"
      return {
        ...state,
        isProcessing: false,
        isFinished: true,
        error: null,
      };
    };
    default:
      return state;
  }
};