import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

const InjectMessage = props => {
  // Ensure props.intl is available before formatting
  if (props.intl) {
    // Convert to string to resolve [object Object] issue
    const formattedMessage = props.intl.formatMessage(
      { id: props.id },
      props.values
    );
    return formattedMessage;
  }
  return props.id;
};

export default injectIntl(InjectMessage, {
  withRef: false
});


//Original
// import React from 'react';
// import {FormattedMessage, injectIntl} from 'react-intl';

// const InjectMassage = props => <FormattedMessage {...props} />;
// export default injectIntl(InjectMassage, {
//   withRef: false
// });
