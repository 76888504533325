import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';

const rootEl = document.getElementById('app-site');

// Create a reusable render method that we can call more than once
let render = () => {
  // Dynamically import our main App component, and render it
  const MainApp = require('./MainApp').default;
  ReactDOM.render(<MainApp />, rootEl);
};

if (module.hot) {
  module.hot.accept('./MainApp', () => {
    const MainApp = require('./MainApp').default;
    render(<MainApp />, rootEl);
  });
}

render();

// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';
// import React from 'react';
// import ReactDOM from 'react-dom';
// import { IntlProvider } from 'react-intl';
// import AppLocale from './lngProvider'; // Ensure this is correctly imported

// const rootEl = document.getElementById('app-site');

// // Ensure a default locale is set
// const defaultLocale = 'en';
// const messages = AppLocale[defaultLocale];

// // Create a reusable render method that we can call more than once
// let render = () => {
//   // Dynamically import our main App component, and render it
//   const MainApp = require('./MainApp').default;
  
//   ReactDOM.render(
//     <IntlProvider
//       locale={defaultLocale}
//       messages={messages}
//       defaultLocale={defaultLocale}
//     >
//       <MainApp />
//     </IntlProvider>,
//     rootEl
//   );
// };

// if (module.hot) {
//   module.hot.accept('./MainApp', () => {
//     const MainApp = require('./MainApp').default;
//     render();
//   });
// }

// render();

