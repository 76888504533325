import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import { ButtonGroup } from "reactstrap";
import Button from "@material-ui/core/Button";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import IntlMessages from "util/IntlMessages";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGithubSignIn,
  userGoogleSignIn,
  userSignIn,
  userTwitterSignIn,
  userPhoneSignIn,
  userOtpSignIn,
} from "actions/Auth";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardSubtitle,
  CardText,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import AppBar from "@material-ui/core/AppBar";
import { switchLanguage } from "actions/Setting";
import LanguageSwitcher from "components/LanguageSwitcher/index";
import classnames from "classnames";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { makeStyles } from "@material-ui/core/styles";
import OtpInput from "react-otp-input";
//import { getAuth } from "firebase/auth";
//import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
//import { firebase, getAuth, RecaptchaVerifier } from "firebase";
//import firebase from "firebase";

// import firebase from "firebase/app";
// import "firebase/auth";

// import firebase from 'firebase/compat/app';
// import 'firebase/compat/auth';
// import { Input } from "@material-ui/core";
//import app from "./base";
//import { OtpInput } from 'components/OtpInput';
//import white from '@material-ui/core/colors/white';
//import indigo from '@material-ui/core/colors/indigo';

import { initializeApp } from "firebase/app"
import "firebase/auth";
import { getAuth, RecaptchaVerifier } from 'firebase/auth';



const firebaseConfig = {
  apiKey: 'AIzaSyCkwrJO1ej4Qn3pieK33c_7cp9YTtReMy8',
  authDomain: 'hello-suzuki.firebaseapp.com',
  databaseURL: 'https://hello-suzuki.firebaseio.com',
  projectId: 'hello-suzuki',
  storageBucket: 'hello-suzuki.appspot.com',
  messagingSenderId: '754489199656',
  appId: '1:754489199656:web:1d07fae011375e6315391d',
  measurementId: 'G-66H0SEZW8P'
};

const firebase =  initializeApp(firebaseConfig);

const tabStyle = {
  default_tab: {
    color: "#FFFFFF",
    width: "50%",
    backgroundColor: "#01344f",
    fontSize: 15,
  },
  active_tab: {
    color: "#D4002C",
    width: "50%",
    backgroundColor: "#FFFFFF",
    fontSize: 15,
  },
};

const useStyles = {
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
};

class LogIn extends React.Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      flag: "gb",
      langSwitcher: false,
      anchorEl: undefined,
      activeTab: "1",
      activeIndex: 0,
      //value: 0,
      isOTP: false,
      phone: "",
      phoneNumber: "",
      code: "",
    };
    //this.toggle = this.toggle.bind(this);
    //this.handleChange = this.handleChange(this);
    //this.validate = this.validate(this);
    //this.phoneChange = this.phoneChange(this);
    //this.otpChange = this.otpChange(this);
    //const [code, setCode] = useState("");
  }

  componentDidMount() {
    // console.log('componentDidMount');
    // if (!window.recaptchaVerifier) {
    //   this.setUpRecaptcha();
    // }
  }
  // componentDibMount() {
  //   console.log('componentDibMount');
  //   this.setState( { phone: '287350' });
  //   this.setUpRecaptcha();
  // }

  componentDidUpdate() {
    //console.log('componentDidUpdate');
    //{/* {showMessage && NotificationManager.error(alertMessage)} */}
    
    if (this.props.showMessage) {
      NotificationManager.error(this.props.alertMessage);
      setTimeout(() => {
        this.props.hideMessage();
      }, 100);
    }
    if (this.props.authUser !== null) {
      this.props.history.push("/");
    }

    if (this.props.confirmationResult !== null) {
      //this.setState({ isOTP: true });
      window.confirmationResult = this.props.confirmationResult;
    }
  }

  componentWillReceiveProps(nextProps) {
    //console.log('componentWillReceiveProps');

    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 100);
    }

    if (nextProps.confirmationResult !== this.props.confirmationResult) {
      //console.log('in');
      //var vinNumber = this.state.vinNumber;

      this.setState({ isOTP: true });
    } else {
      //console.log('not in');
    }
  }

  componentWillUnmount() {
    console.log('componentWillUnmount');
    //window.recaptchaVerifier.recapcha.reset()
    //window.recaptchaVerifier.clear()


    // Cleanup recaptcha when component unmounts
    if (window.recaptchaVerifier) {
      window.recaptchaVerifier.clear();
    }
  }

  conponentDidUnmount() {
    //console.log('conponentDidUnmount');
  }

  // setUpRecaptcha = () => {
  //   //console.log("setUpRecaptcha is called");    
    
  //   //const auth = firebase.auth();

  //   const auth = getAuth(firebase);

  //   //console.log("setUpRecaptcha");

  //   //if (!window.recaptchaVerifier) {
  //   window.recaptchaVerifier = new auth.RecaptchaVerifier( //new auth.RecaptchaVerifier(
  //     "recaptcha-container",
  //     {
  //       size: "invisible",
  //       callback: (response) => {
  //         //console.log('response:' + response);
  //         // reCAPTCHA solved, allow signInWithPhoneNumber.
  //         // ...

  //         this.props.showAuthLoader();
  //         this.props.userPhoneSignIn(this.state.phoneNumber, window.recaptchaVerifier);
  //       },
  //       "expired-callback": () => {
  //         //console.log('captcha expired')
  //         //setTimeout(() => window.recaptchaVerifier.recapcha.reset(), 500);
  //         //window.recaptchaVerifier.clear()
  //         // Response expired. Ask user to solve reCAPTCHA again.
  //         // ...
  //       },
  //     }
  //   );

  //   // window.recaptchaVerifier.render().then(function (widgetId) {
  //   //   window.recaptchaWidgetId = widgetId;
  //   // });

  //   window.recaptchaVerifier.verify();
  //   //}
  // };

  setUpRecaptcha = async () => {
    try {
      console.log('setUpRecaptcha is called');
      // const auth = getAuth(firebase);
      const auth = getAuth();
      //invisible normal
      window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
        size: 'invisible',
        callback: async (response) => {
          // console.log('AAAA');
          // console.log('response:' + JSON.stringify(response));
          // console.log('windows:' + window.recaptchaVerifier);
          // reCAPTCHA solved, allow signInWithPhoneNumber
          this.props.showAuthLoader();
          this.props.userPhoneSignIn(this.state.phoneNumber, window.recaptchaVerifier);
        },
        // Add this to explicitly use v2
        //isV2: true,
        'expired-callback': () => {
          // Handle expired recaptcha
          console.log('reCAPTCHA expired');
          if (window.recaptchaVerifier) {
            window.recaptchaVerifier.clear();
          }
        }
      });

      console.log('reCAPTCHA verifier created:', window.recaptchaVerifier);
    
      // First render
      await window.recaptchaVerifier.render();
      //console.log('reCAPTCHA rendered');

      // Add a small delay
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      // The verify() call will trigger the callback above when successful
      await window.recaptchaVerifier.verify();
      // console.log('Verify called');

      

      // Important: First render the verifier
      //await window.recaptchaVerifier.render();

      
      
      // Then manually trigger the verification
      //const verificationResult = await window.recaptchaVerifier.verify();

      //console.log('verificationResult:' + JSON.stringify(verificationResult));

      //console.dir('win:' + window.recaptchaVerifier);

      const verifier = window.recaptchaVerifier;
      console.log({
        render: typeof verifier.render,
        verify: typeof verifier.verify,
        clear: typeof verifier.clear,
        size: verifier.parameters?.size,
        sitekey: verifier.parameters?.sitekey
      });

      //this.props.showAuthLoader();
      //this.props.userPhoneSignIn(this.state.phoneNumber, verificationResult);
      //this.props.userPhoneSignIn(this.state.phoneNumber, window.recaptchaVerifier);

      // window.recaptchaVerifier.render().then((widgetId) => {
      //   window.recaptchaWidgetId = widgetId;
      // });
    } catch (error) {
      console.error('Error setting up reCAPTCHA:', error);
      if (window.recaptchaVerifier) {
        window.recaptchaVerifier.clear();
      }
    }
  };

  getOtpFromPhoneNumber = () => {
    const appVerifier = window.recaptchaVerifier;
    firebase
      .auth()
      .signInWithPhoneNumber(this.state.phoneNumber, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
      })
      .catch((error) => {
        console.log("unable to send OTP");
        //console.log("error1:" + JSON.stringify(error));
      });
  };

  verifyOTP = () => {
    window.confirmationResult
      .confirm(this.state.code)
      .then((result) => {
        const user = result.user;
        //console.log("result:" + JSON.stringify(result));
        //console.log("user:" + JSON.stringify(result.user));
      })
      .catch((error) => {
        console.log("error2:" + JSON.stringify(error));
      });
  };

  // onPhoneSubmit = async (phoneNumber) => {
  //   const appVerifier = await window.recaptchaVerifier;

  //   try {
  //     const response = await signInWithPhone()
  //   }
  // }

  //   toggle(tab) {
  //     if (this.state.activeTab !== tab) {
  //       this.setState({
  //         activeTab: tab,
  //       });
  //     }
  //   }

  handleChange = (event, value) => {
    //console.log("value:" + value);
    if (value === 0) {
      this.setState({ activeTab: "1" });
    } else if (value === 1) {
      this.setState({ activeTab: "2" });
    }
    this.setState({ activeIndex: value });
  };

  phoneChange = (e) => {
    const re = /^[0][0-9\b]+S/;

    //console.log('phone state:' + this.state.phone);

    //console.log('typing:' + e.target.value);

    let phoneValue = e.target.value;

    // if (phoneValue === 0) {
    //   console.log('aaa');
    // }
    // if (phoneValue === '0') {
    //   console.log('vvv');
    // }

    if (phoneValue === "0") {
      //console.log('a');
      phoneValue = e.target.value;
    } else if (phoneValue === "" && this.state.phone === "0") {
      phoneValue = e.target.value;
    } else if (phoneValue.length === 1 && this.state.phone === "0") {
      //console.log('b');
      phoneValue = "0" + e.target.value;
    } else {
      //console.log('c');
      phoneValue = "0" + e.target.value;
    }

    //console.log('phoneValue:' + phoneValue);

    this.setState({ phone: phoneValue });

    const pValue = phoneValue; //this.state.phone;

    let finalPhone = "";
    if (pValue.length > 0) {
      finalPhone = "+66" + pValue.substring(1, pValue.length);
    } else {
      finalPhone = pValue;
    }

    //console.log('finalPhone:' + finalPhone);

    //finalPhone = '+66915464664';
    this.setState({ phoneNumber: finalPhone });
    //console.log('phoneNumber:' + this.state.phoneNumber);

    // if (![null, '', false].includes(e.target.value)) {
    //   this.setState({ phone: e.target.value });
    // }

    // if (e.target.value === '' || re.test(e.target.value)) {
    //   this.setState({ phone: e.target.value });
    // }

    //console.log("key:" + e.target.value);

    // if ((e.target.value === '') || (re.test(e.target.value))) {
    //   console.log("True");
    //   this.setState({ phone: e.target.value });
    // } else {
    //   console.log("false");
    // }

    //const pValue = e.target.value.replace(/\+|-/gi, ""); // use this one
    //const pValue = e.target.value.replace(/^[0][0-9\b]+S/, "");
    //const pValue = e.target.value.replace(/^[0][0-9]/, "");

    //const pValue = e.target.value;

    // let finalPhone = '';
    // if (pValue.length === 10) {
    //   finalPhone = '+66' + pValue.substring(1, pValue.length);
    // } else {
    //   finalPhone = pValue;
    // }

    // console.log('finalPhone:' + finalPhone);

    // finalPhone = '+66915464664';
    // this.setState({ phone: finalPhone });
  };

  otpChange = (code) => {
    //const codeChange = (code) => setCode(code);

    //console.log("code1:" + code)
    this.setState({ code: code });

    //console.log("code1:" + e.target.value);
    //console.log("code2:" + value);
    //e.preventDefault();
    //setCode(e.target.value);
    //this.setState({ otp: e.target.value })
    //console.log('otp:' + this.state.otp);
  };

  onLangSwitcherSelect = (event) => {
    this.setState({
      langSwitcher: !this.state.langSwitcher,
      anchorEl: event.currentTarget,
    });
  };
  handleRequestClose = () => {
    // console.log('state flag:' + this.state.flag);
    // console.log('local:' + this.props.locale.icon);

    // var currentFlag = this.state.flag;
    // if (this.state.flag === 'th') {
    //   currentFlag = 'gb';
    // }
    // if (this.state.flag === 'gb') {
    //   currentFlag = 'th';
    // }
    // console.log('flag:' + currentFlag);
    this.setState({
      langSwitcher: false,
      //flag: currentFlag
    });
  };

  onSubmitEmail = (e) => {
    // this.props.showAuthLoader();
    // this.props.userSignIn({ email, password });
  };

  onSubmitPhone = (e) => {
    // this.setUpRecaptcha();
    // const appVerifier = window.recaptchaVerifier;
    // console.log('appVerifierWeb:' + appVerifier);
    // console.log('phoneNumber:' + phoneNumber);
    //  //this.setState({ isOTP: true });
    // this.props.showAuthLoader();
    // this.props.userPhoneSignIn(phoneNumber, appVerifier);
  };

  getStyle = (isActive) => {
    return isActive ? tabStyle.active_tab : tabStyle.default_tab;
  };

  resendOTP = () => {
    if (!window.recaptchaVerifier) {
      this.setUpRecaptcha();
    }

    const appVerifier = window.recaptchaVerifier;
    const phoneNumber = this.state.phoneNumber;

    //window.recaptchaVerifier.verify();

    // if (appVerifier !== undefined) {
    //   console.log('appVerifierWeb:' + JSON.stringify(appVerifier));
    // } else {
    //   console.log('appVerifierWeb:' + appVerifier); //JSON.stringify(appVerifier));
    // }

    //console.log('appVerifierWeb:' + appVerifier);
    //console.log('phoneNumber:' + phoneNumber);

    //this.setState({ isOTP: true });
    this.props.showAuthLoader();
    this.props.userPhoneSignIn(phoneNumber, appVerifier);
  };

  displayPhone = () => {
    const {
      email,
      password,
      value,
      activeTab,
      activeIndex,
      isOTP,
      phone,
      phoneNumber,
      code,
    } = this.state;

    if (this.state.isOTP) {
      return (
        <fieldset>
          <OtpInput
            value={code}
            onChange={this.otpChange}
            numInputs={6}
            separator={<span style={{ width: "18px" }}></span>}
            isInputNum={true}
            shouldAutoFocus={true}
            inputStyle={{
              color: "#01344f",
              border: "1px solid #CFD3DB",
              borderRadius: "8px",
              width: "54px",
              height: "54px",
              fontSize: "20px",
              fontWeight: "400",
              caretColor: "blue",
            }}
            codeInputFieldStyle={{
              color: "#01344f",
              fontSize: "25px",
              backgroundColor: "#ff00dd",
            }}
            focusStyle={{
              border: "1px solid #01344f",
              outline: "none",
            }}
          />
          <br />
          <div className="mb-3 d-flex align-items-center justify-content-between">
            <Button
              onClick={() => {
                this.props.showAuthLoader();
                this.props.userOtpSignIn(
                  this.props.confirmationResult,
                  this.state.code
                );

                //On page
                //this.verifyOTP();
              }}
              variant="contained"
              color="primary"
            >
              <IntlMessages id="appModule.sendotp" />
            </Button>

            {/* <Link to="/signup">
                      <IntlMessages id="signIn.signUp" />
                    </Link> */}
          </div>

          <div className="app-social-block my-1 my-sm-3">
            <center>
              <IntlMessages id="appModule.resendotpmessage" />
            </center>
          </div>
          <div>
            <center>
              <a
                onClick={this.resendOTP}
                style={{ cursor: "pointer", color: "#01344f" }}
              >
                <IntlMessages id="appModule.resendotp" />
              </a>
            </center>
          </div>
        </fieldset>
      );
    } else {
      return (
        <div>
          <fieldset>
            <TextField
              label={<IntlMessages id="appModule.phone" />}
              fullWidth
              // onKeyPress={event => {
              //     //this.validate(event)
              //     let charCode = event.which ? event.which : event.keyCode;
              //     if (event.key === 'Enter') {
              //       console.log('Enter Key:' + charCode);
              //     }
              //     console.log('Enter keyCode:' + event.keyCode);
              //     if ((event.keyCode === 8) || (event.keyCode === 46)) {
              //       return true;
              //     } else if ((event.keyCode < 48) || (event.keyCode > 57)) {
              //       return false;
              //     } else {
              //       return true;
              //     }
              //   }
              // }
              type="number"
              // value={value !== NaN ? value : ' '}
              value={this.state.phone}
              placeholder="0XXXXXXXXXX"
              onChange={(event) => {
                // if (isNaN(event.target.value)) {
                //   return;
                // }
                // this.setState({ phone: event.target.value });
                this.phoneChange(event);
              }}
              margin="normal"
              className="mt-1 my-sm-3 input-number"
              inputProps={{ maxLength: 9 }}
              onInput={(e) => {
                //e.target.value = e.target.value.replace(/\+|-/ig, '');
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 9);
              }}
            />
            <div className="mb-3 d-flex align-items-center justify-content-between">
              <Button
                onClick={(e) => {
                  //e.stopPropagation();
                  e.preventDefault();

                  if (this.state.phone === "") {
                    NotificationManager.warning(
                      <IntlMessages id="notification.warning" />,
                      <IntlMessages id="appModule.message.phone.empty" />,
                      3000
                    );
                    return;
                  }

                  //alert("phone");
                  //this.setUpRecaptcha();

                  // if (window.recaptchaVerifier && recaptchaWrapperRef.current) {
                  //   console.log('clear recaptcha');
                  //   window.recaptchaVerifier.recapcha.reset();
                  //   window.recaptchaVerifier.clear();
                  //   recaptchaWrapperRef.current.innerHTML = `<div id="recaptcha-container"></div>`;
                  // }

                  // if (window.recaptchaVerifier) {
                  //   console.log('clear recaptcha');
                  //   window.recaptchaVerifier.reset();
                  //   //window.recaptchaVerifier.recapcha.reset();
                  //   window.recaptchaVerifier.clear();
                  //   //recaptchaWrapperRef.current.innerHTML = `<div id="recaptcha-container"></div>`;
                  // }


                  this.setUpRecaptcha();


                  // if (!window.recaptchaVerifier) {
                  //   this.setUpRecaptcha();
                  // } else {

                  //   window.recaptchaVerifier.verify();
                  //   const appVerifier = window.recaptchaVerifier;

                    

                  //   // if (appVerifier !== undefined) {
                  //   //   console.log('appVerifierWeb:' + JSON.stringify(appVerifier));
                  //   // } else {
                  //   //   console.log('appVerifierWeb:' + appVerifier); //JSON.stringify(appVerifier));
                  //   // }
                  //   console.log("appVerifierWeb:" + appVerifier);
                  //   console.log("phoneNumber:" + phoneNumber);

                  //   //this.setState({ isOTP: true });
                  //   this.props.showAuthLoader();
                  //   this.props.userPhoneSignIn(phoneNumber, appVerifier);
                  // }

                  //On page
                  //this.getOtpFromPhoneNumber();
                }}
                variant="contained"
                color="primary"
              >
                <IntlMessages id="appModule.getotp" />
              </Button>

              {/* <Link to="/signup">
                      <IntlMessages id="signIn.signUp" />
                    </Link> */}
            </div>

            <div className="app-social-block my-1 my-sm-3"></div>
          </fieldset>
        </div>
      );
    }
  };

  fillPhone = (e) => {
    this.setState({ isOTP: false });
  };

  validate = (evt) => {
    //evt.preventDefault();

    var theEvent = evt; // || window.event;

    console.log("key:" + evt);

    // Handle paste
    if (theEvent.type === "paste") {
      key = theEvent.clipboardData.getData("text/plain");
    } else {
      // Handle key press
      var key = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode(key);
    }
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  };

  render() {
    const {
      email,
      password,
      activeTab,
      activeIndex,
      isOTP,
      phone,
      code,
    } = this.state;
    const {
      showMessage,
      loader,
      alertMessage,
      confirmationResult,
      locale,
    } = this.props;

    return (
      <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        {/* <div ref={recaptchaWrapperRef}> */}
        {/* <div id="recaptcha-container"></div> */}
        <div 
        id="recaptcha-container" 
        className="recaptcha-container"
        style={{ margin: '20px 0' }}
      ></div>
        {/* </div> */}
        <Card className="shadow border-0">
          <CardHeader className="bg-primary">
            <Tabs
              value={activeIndex}
              onChange={this.handleChange}
              variant="fullWidth"
              TabIndicatorProps={{ style: { backgroundColor: "#D4002C" } }}
              scrollButtons="on"
            >
              <Tab
                className="tab"
                style={this.getStyle(activeIndex === 0)}
                label={<IntlMessages id="appModule.ownsuzukicar" />}
              />
              <Tab
                className="tab"
                style={this.getStyle(activeIndex === 1)}
                label={<IntlMessages id="appModule.nosuzukicar" />}
              />
              {/* <Tab className="tab" label="Own Suzuki Car"/>
            <Tab className="tab" label="No Suzuki Car"/> */}
            </Tabs>
          </CardHeader>

          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId="1">
              <CardBody>
                <div className="app-login-content-new">
                  <div className="row">
                    {isOTP ? (
                      <a
                        onClick={this.fillPhone}
                        style={{ cursor: "pointer", color: "#01344f" }}
                      >
                        <IntlMessages id="appModule.backtofillphone" />
                      </a>
                    ) : null}

                    <ul className="header-notifications list-inline ml-auto">
                      <IconButton
                        className="icon-btn"
                        language="gb"
                        onClick={() => {
                          const language = {
                            languageId: "english",
                            locale: "en",
                            name: "English",
                            icon: "gb",
                          };
                          this.props.switchLanguage(language);
                        }}
                      >
                        <i className={`flag flag-24 flag-gb`} />
                      </IconButton>
                      <IconButton
                        className="icon-btn"
                        language="th"
                        onClick={() => {
                          const language = {
                            languageId: "thai",
                            locale: "th",
                            name: "Thai",
                            icon: "th",
                          };
                          this.props.switchLanguage(language);
                        }}
                      >
                        <i className={`flag flag-24 flag-th`} />
                      </IconButton>
                    </ul>
                  </div>
                  <div className="app-login-header mb-4">
                    <h1>
                      {isOTP ? (
                        <IntlMessages id="appModule.otp" />
                      ) : (
                        <IntlMessages id="appModule.phone" />
                      )}
                    </h1>
                  </div>
                  <div className="app-login-form">
                    <form>{this.displayPhone()}</form>
                    {/* {this.displayPhone()} */}
                  </div>
                </div>
              </CardBody>
              {/* <CardFooter>2 days ago</CardFooter> */}
            </TabPane>

            <TabPane tabId="2">
              <CardBody>
                <div className="app-login-content-new">
                  <div className="row">
                    <ul className="header-notifications list-inline ml-auto">
                      <IconButton
                        className="icon-btn"
                        language="gb"
                        onClick={() => {
                          const language = {
                            languageId: "english",
                            locale: "en",
                            name: "English",
                            icon: "gb",
                          };
                          this.props.switchLanguage(language);
                        }}
                      >
                        <i className={`flag flag-24 flag-gb`} />
                      </IconButton>
                      <IconButton
                        className="icon-btn"
                        language="th"
                        onClick={() => {
                          const language = {
                            languageId: "thai",
                            locale: "th",
                            name: "Thai",
                            icon: "th",
                          };
                          this.props.switchLanguage(language);
                        }}
                      >
                        <i className={`flag flag-24 flag-th`} />
                      </IconButton>
                    </ul>
                  </div>
                  <div className="app-login-header mb-4">
                    <h1>
                      <IntlMessages id="appModule.email" />
                    </h1>
                  </div>
                  <div className="app-login-form">
                    <form>
                      <fieldset>
                        <TextField
                          label={<IntlMessages id="appModule.email" />}
                          fullWidth
                          onChange={(event) =>
                            this.setState({ email: event.target.value })
                          }
                          defaultValue={email}
                          margin="normal"
                          className="mt-1 my-sm-3"
                        />
                        <TextField
                          type="password"
                          label={<IntlMessages id="appModule.password" />}
                          fullWidth
                          onChange={(event) =>
                            this.setState({ password: event.target.value })
                          }
                          defaultValue={password}
                          margin="normal"
                          className="mt-1 my-sm-3"
                        />

                        <div className="mb-3 d-flex align-items-center justify-content-between">
                          <Button
                            onClick={(e) => {
                              if (e) e.preventDefault();

                              if (this.state.email === "") {
                                NotificationManager.warning(
                                  <IntlMessages id="notification.warning" />,
                                  <IntlMessages id="appModule.message.email.empty" />,
                                  3000
                                );
                                return;
                              }

                              if (this.state.password === "") {
                                NotificationManager.warning(
                                  <IntlMessages id="notification.warning" />,
                                  <IntlMessages id="appModule.message.password.empty" />,
                                  3000
                                );
                                return;
                              }
                              //e.preventDefault();
                              //alert("email");
                              //e.stopPropagation();
                              this.props.showAuthLoader();
                              this.props.userSignIn({ email, password });
                            }}
                            variant="contained"
                            color="primary"
                          >
                            <IntlMessages id="appModule.signIn" />
                          </Button>

                          {/* <Link to="/signup">
                      <IntlMessages id="signIn.signUp" />
                    </Link> */}
                        </div>

                        <div className="app-social-block my-1 my-sm-3"></div>
                      </fieldset>
                    </form>
                  </div>
                </div>
              </CardBody>
              {/* <CardFooter>2 days ago</CardFooter> */}
            </TabPane>
          </TabContent>
        </Card>
        {loader && (
          <div className="loader-view">
            <CircularProgress />
          </div>
        )}
        {/* {showMessage && NotificationManager.error(alertMessage)} */}
        <NotificationContainer />
      </div>
    );
  }
}

const mapStateToProps = ({ auth, settings }) => {
  const {
    loader,
    alertMessage,
    showMessage,
    authUser,
    confirmationResult,
  } = auth;
  const { locale } = settings;

  return {
    loader,
    alertMessage,
    showMessage,
    authUser,
    confirmationResult,
    locale: settings.locale,
  };
};

export default connect(mapStateToProps, {
  userSignIn,
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGoogleSignIn,
  userGithubSignIn,
  userTwitterSignIn,
  userPhoneSignIn,
  userOtpSignIn,
  switchLanguage,
})(LogIn);
