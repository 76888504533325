import {
  all,
  takeEvery,
  call,
  fork,
  put,
  takeLatest,
} from 'redux-saga/effects';
import {
  getRewardsSuccess,
  getRewardByIdSuccess,
  createRewardSuccess,
  updateRewardSuccess,
  deleteRewardSuccess,
  hideLoaderGetRewards,
  hideLoaderGetRewardById,
  hideLoaderCreateReward,
  hideLoaderUpdateReward,
  hideLoaderDeleteReward,
  showMessageReward,
} from 'actions/Reward';
import {
  GET_REWARDS_REQUEST,
  GET_REWARD_BY_ID_REQUEST,
  CREATE_REWARD_REQUEST,
  UPDATE_REWARD_REQUEST,
  DELETE_REWARD_REQUEST,
} from 'constants/ActionTypes';
import moment from 'moment-timezone';
import { getFirestore, collection, query, where, doc, getDoc, getDocs, setDoc, updateDoc, deleteDoc, serverTimestamp } from 'firebase/firestore';
import { rsf, firebase, db } from '../firebase/firebase';
import { 
  getStorage, 
  ref, 
  uploadString,
  uploadBytes,
  deleteObject,
  getDownloadURL 
} from 'firebase/storage';
// import { rsf, db } from '../firebase/firebase';
// import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";


const storage = getStorage();


function* getReward() {
  try {
    // const colRef = firebase.firestore().collection('RewardsThailand');
    
    // const snapshot = yield call(rsf.firestore.getCollection, colRef);
    

    const colRef = collection(db, 'RewardsThailand');
    
    // Build query with date range
    // const q = query(
    //   colRef,
    //   where('date', '>=', startDate),
    //   where('date', '<=', endDate)
    // );
    
    // Fetch documents
    const snapshot = yield call(getDocs, colRef);



    //console.log('items:' + snapshot);
    let items = [];
    snapshot.forEach((doc) => {
      //console.log(doc.data());
      var data = doc.data();
      data['id'] = data.rewardId;
      //console.log('T:' + data.friends.length);
      items.push(data);

      // datas = {
      //   id: doc.id,
      //   ...doc.data()
      // };
    });
    items.sort((a, b) => {
      return a.order > b.order ? 1 : -1; //ascending
      //return a.order > b.order ? -1 : 1; //descending
    });
    //console.log('To:' + items.length);
    yield put(getRewardsSuccess(items));
  } catch (error) {
    console.log(error);
    yield put(hideLoaderGetRewards());
  }
}

function* getRewardById({ payload }) {
  const { rewardid } = payload;
  //console.log('get rewardId:' + rewardid);
  try {
    // const colRef = firebase.firestore().collection('RewardsThailand');
    
    // const snapshot = yield call(
    //   rsf.firestore.getCollection,
    //   colRef.where('rewardId', '==', rewardid)
    // );
    // //colRef.where('active', '==', true);

    const colRef = collection(db, 'RewardsThailand');
    
    // Build query with date range
    const q = query(
      colRef,
      where('rewardId', '==', rewardid),      
    );
    
    // Fetch documents
    const snapshot = yield call(getDocs, q);



    //console.log('items:' + snapshot);
    let items = [];
    snapshot.forEach((doc) => {
      //console.log(doc.data());
      var data = doc.data();
      data['id'] = data.rewardId;
      //console.log('T:' + data.friends.length);
      items.push(data);

      // datas = {
      //   id: doc.id,
      //   ...doc.data()
      // };
    });
    var item = null;
    if (items.length > 0) {
      item = items[0];
    }
    console.log('To:' + items.length);
    yield put(getRewardByIdSuccess(item));
  } catch (error) {
    console.log(error);
    yield put(hideLoaderGetRewardById());
  }
}

function* createReward({ payload }) {
  const {
    imgSrc,
    order,
    partNumber,
    titleEn,
    titleTh,
    detailEn,
    detailTh,
    points,
    pointsPerBaht,
    isActive,
  } = payload;
  //console.log('imgSrc:' + imgSrc);

  const currentDateTime = getCurrentDateTime();

  const storage = getStorage();

  var path =
    'RewardPhotoThailand/' +
    partNumber.toLowerCase() +
    '-' +
    currentDateTime +
    '.png';
  var metadata = {
    contentType: 'image/png',
  };
  if (imgSrc.indexOf('image/png') !== -1) {
    console.log('png file');
    path =
      'RewardPhotoThailand/' +
      partNumber.toLowerCase() +
      '-' +
      currentDateTime +
      '.png';
    metadata = {
      contentType: 'image/png',
    };
  }
  if (imgSrc.indexOf('image/jpg') !== -1) {
    console.log('jpg file');
    path =
      'RewardPhotoThailand/' +
      partNumber.toLowerCase() +
      '-' +
      currentDateTime +
      '.jpg';
    metadata = {
      contentType: 'image/jpeg',
    };
  }
  if (imgSrc.indexOf('image/jpeg') !== -1) {
    console.log('jpeg file');
    path =
      'RewardPhotoThailand/' +
      partNumber.toLowerCase() +
      '-' +
      currentDateTime +
      '.jpg';
    metadata = {
      contentType: 'image/jpeg',
    };
  }
  //console.log('path:' + path);
  try {

    //const task = rsf.storage.uploadFile(path, imgSrc);  // This one for blob or file
    // const task = rsf.storage.uploadString(path, imgSrc, 'data_url', metadata);
    // yield task;
    // const url = yield call(rsf.storage.getDownloadURL, path);

    // const colRef = firebase.firestore().collection('RewardsThailand');
    // // const colRef = query(collection(db, 'RewardsThailand'));
    // const rewardId = colRef.doc().id;
    // const doc = yield call(
    //   rsf.firestore.setDocument,
    //   'RewardsThailand/' + rewardId,
    //   {
    //     rewardId: rewardId,
    //     order: Number(order),
    //     partNumber: partNumber,
    //     titleEn: titleEn,
    //     titleTh: titleTh,
    //     detailEn: detailEn,
    //     detailTh: detailTh,
    //     points: Number(points),
    //     pointsPerBaht: Number(pointsPerBaht),
    //     isActive: isActive,
    //     photo: url,
    //     createdAt: currentDateTime,
    //     updatedAt: currentDateTime,
    //   }
    // );

    // Create storage reference
    const storageRef = ref(storage, path);
    // For base64 data URLs
    yield call(uploadString, storageRef, imgSrc, 'data_url', metadata);

    // For Blob or File objects
    //yield call(uploadBytes, storageRef, imgSrc, metadata);

    // Get download URL
    const url = yield call(getDownloadURL, storageRef);

    const docData = {
      rewardId: rewardId,
      order: Number(order),
      partNumber: partNumber,
      titleEn: titleEn,
      titleTh: titleTh,
      detailEn: detailEn,
      detailTh: detailTh,
      points: Number(points),
      pointsPerBaht: Number(pointsPerBaht),
      isActive: isActive,
      photo: url,
      createdAt: currentDateTime,
      updatedAt: currentDateTime,
    }

    // Add document to collection
    const rewardRef = collection(db, 'RewardsThailand');
    const newDocRef = doc(rewardRef);
    const rewardId = newDocRef.id;

    // Add with set doc
    yield call(setDoc, newDocRef, docData);

    // Add without set doc id
    // const docRef = yield call(
    //   addDoc, 
    //   collection(db, 'RewardsThailand'),
    //   docData
    // );

    
    yield put(createRewardSuccess(rewardId));
    //yield put(createRewardSuccess(docRef.id));
    yield put(showMessageReward('Success'));
    //yield put(hideLoaderCreateReward());
  } catch (error) {
    console.log(error);
    yield put(hideLoaderCreateReward());
  }
}

function* updateReward({ payload }) {
  const {
    rewardid,
    imgSrc,
    order,
    partNumber,
    titleEn,
    titleTh,
    detailEn,
    detailTh,
    points,
    pointsPerBaht,
    isActive,
  } = payload;
  // console.log('update id:' + rewardid);
  // console.log('order:', order);
  // console.log('partNumber:' + partNumber);
  // console.log('detailEn:', detailEn);
  // console.log('detailTh:', detailTh);

  const currentDateTime = getCurrentDateTime();

  if (imgSrc !== null) {
    var path =
      'RewardPhotoThailand/' +
      partNumber.toLowerCase() +
      '-' +
      currentDateTime +
      '.png';
    var metadata = {
      contentType: 'image/png',
    };
    if (imgSrc.indexOf('image/png') !== -1) {
      console.log('png file');
      path =
        'RewardPhotoThailand/' +
        partNumber.toLowerCase() +
        '-' +
        currentDateTime +
        '.png';
      metadata = {
        contentType: 'image/png',
      };
    }
    if (imgSrc.indexOf('image/jpg') !== -1) {
      console.log('jpg file');
      path =
        'RewardPhotoThailand/' +
        partNumber.toLowerCase() +
        '-' +
        currentDateTime +
        '.jpg';
      metadata = {
        contentType: 'image/jpeg',
      };
    }
    if (imgSrc.indexOf('image/jpeg') !== -1) {
      console.log('jpeg file');
      path =
        'RewardPhotoThailand/' +
        partNumber.toLowerCase() +
        '-' +
        currentDateTime +
        '.jpg';
      metadata = {
        contentType: 'image/jpeg',
      };
    }
    //console.log('path:' + path);
    try {
      
      // const task = rsf.storage.uploadString(path, imgSrc, 'data_url', metadata);
      // yield task;
      // const url = yield call(rsf.storage.getDownloadURL, path);
      // console.log('url:' + url);

      // //const colRef = firebase.firestore().collection('RewardsThailand');
      // const updateData = {
      //   order: Number(order),
      //   partNumber: partNumber,
      //   titleEn: titleEn,
      //   titleTh: titleTh,
      //   detailEn: detailEn,
      //   detailTh: detailTh,
      //   points: Number(points),
      //   pointsPerBaht: Number(pointsPerBaht),
      //   isActive: isActive,
      //   photo: url,
      //   updatedAt: currentDateTime,
      // };
      // yield call(
      //   rsf.firestore.updateDocument,
      //   'RewardsThailand/' + rewardid,
      //   updateData
      // );


      // Create storage reference
      const storageRef = ref(storage, path);
      // For base64 data URLs
      yield call(uploadString, storageRef, imgSrc, 'data_url', metadata);

      // For Blob or File objects
      //yield call(uploadBytes, storageRef, imgSrc, metadata);

      // Get download URL
      const url = yield call(getDownloadURL, storageRef);


      // Get existing document to check old image path
      const docRef = doc(db, 'RewardsThailand', rewardid);
      const docSnap = yield call(getDoc, docRef);
      // Delete old image if exists
      if (docSnap.data().photo) {
        try {
          const oldImageRef = ref(storage, docSnap.data().photo);
          yield call(deleteObject, oldImageRef);
        } catch (deleteError) {
          console.warn('Failed to delete old image:', deleteError);
        }
      }

      const docData = {
        order: Number(order),
        partNumber: partNumber,
        titleEn: titleEn,
        titleTh: titleTh,
        detailEn: detailEn,
        detailTh: detailTh,
        points: Number(points),
        pointsPerBaht: Number(pointsPerBaht),
        isActive: isActive,
        photo: url,
        updatedAt: currentDateTime,
      }

      // Update document to collection
      yield call(updateDoc, docRef, docData);


      yield put(updateRewardSuccess());
      yield put(showMessageReward('Success'));
    } catch (error) {
      console.log(error);
      yield put(hideLoaderUpdateReward());
    }
  } else {
    try {
      //var currentDateTime = getCurrentDateTime();
      //const colRef = firebase.firestore().collection('RewardsThailand');
      const updateData = {
        order: Number(order),
        partNumber: partNumber,
        titleEn: titleEn,
        titleTh: titleTh,
        detailEn: detailEn,
        detailTh: detailTh,
        points: Number(points),
        pointsPerBaht: Number(pointsPerBaht),
        isActive: isActive,
        updatedAt: currentDateTime,
      };

      const docRef = doc(db, 'RewardsThailand', rewardid);

      // Update document to collection
      yield call(updateDoc, docRef, updateData);


      yield put(updateRewardSuccess());
      yield put(showMessageReward('Success'));
    } catch (error) {
      console.log(error);
      yield put(hideLoaderUpdateReward());
    }
  }
}

function* deleteReward({ payload }) {
  const { rewardid, deletePath } = payload;

  // var path = deletePath.replace(
  //   'https://firebasestorage.googleapis.com/v0/b/hello-suzuki.appspot.com/o/',
  //   ''
  // );
  // path = path.replace('%2F', '/');
  // path = path.substring(0, path.indexOf('?'));
  // //console.log('path:' + path);

  try {
    //yield call(rsf.firestore.deleteDocument, 'RewardsThailand/' + rewardid);
    
    // 1. Get document data first to get the image path
    const docRef = doc(db, 'RewardsThailand', rewardid);
    const docSnap = yield call(getDoc, docRef);

    if (!docSnap.exists()) {
      throw new Error('Reward document not found');
    }

    // 2. Delete image from storage if exists
    if (docSnap.data().photo) {
      try {
        const imageRef = ref(storage, docSnap.data().photo);
        yield call(deleteObject, imageRef);
      } catch (storageError) {
        console.warn('Failed to delete image from storage:', storageError);
        // Continue with document deletion even if image deletion fails
      }
    }

    // 3. Delete the document
    yield call(deleteDoc, docRef);


    yield put(deleteRewardSuccess());
  } catch (error) {
    console.log(error);
    yield put(hideLoaderDeleteReward());
  }
}

function getCurrentDateTime() {
  let dd = moment()
    .tz('Asia/Bangkok')
    .format('YYYYMMDD HH:mm:ss'); //LTS
  //console.log('time1:' + dd);
  let ee = dd.replace(/:/g, '');
  //console.log('time2:' + ee);
  ee = ee.replace(/ /g, '');
  let str = ee.trim();
  //console.log('final:' + str);
  return str;
}

export function* watchGetRewardsRequest() {
  yield takeLatest(GET_REWARDS_REQUEST, getReward);
}
export function* watchGetRewardByIdRequest() {
  yield takeLatest(GET_REWARD_BY_ID_REQUEST, getRewardById);
}
export function* watchCreateRewardRequest() {
  yield takeLatest(CREATE_REWARD_REQUEST, createReward);
}
export function* watchUpdateRewardRequest() {
  yield takeLatest(UPDATE_REWARD_REQUEST, updateReward);
}
export function* watchDeleteRewardRequest() {
  yield takeLatest(DELETE_REWARD_REQUEST, deleteReward);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetRewardsRequest),
    fork(watchGetRewardByIdRequest),
    fork(watchCreateRewardRequest),
    fork(watchUpdateRewardRequest),
    fork(watchDeleteRewardRequest),
  ]);
}

/*
var metadata = {
  contentType: 'image/png',
  metadata: {
    firebaseStorageDownloadTokens: '9d225721-aa4f-473c-a3c9-25d33384a9ec'
  }
};
*/
