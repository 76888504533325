import React from 'react';
import {ConnectedRouter} from 'connected-react-router'
import {Provider} from 'react-redux';
import {Route, Switch} from 'react-router-dom';

import configureStore, {history} from './store';
import './firebase/firebase';
import App from './containers/App';

export const store = configureStore();


const MainApp = () =>
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>        
        <Route path="/" component={App}/>        
      </Switch>
    </ConnectedRouter>
  </Provider>;

export default MainApp;


// import React from 'react';
// import { ConnectedRouter } from 'connected-react-router';
// import { Provider } from 'react-redux';
// import { Route, Switch } from 'react-router-dom';
// import { IntlProvider } from 'react-intl';
// import configureStore, { history } from './store';
// import './firebase/firebase';
// import App from './containers/App';
// import AppLocale from './lngProvider'; // Import your locales

// export const store = configureStore();

// const MainApp = () => {
//   // Get initial locale from localStorage or default to 'en'
//   // const [locale, setLocale] = React.useState(() => {
//   //   return localStorage.getItem('appLocale') || 'en';
//   // });

//   const [locale, setLocale] = React.useState('en');

//   // Function to change locale
//   const changeLocale = (newLocale) => {
//     setLocale(newLocale);
//     localStorage.setItem('appLocale', newLocale);
//   };

//   return (
//     <Provider store={store}>
//       <IntlProvider
//         messages={AppLocale[locale]}
//         locale={locale}
//         defaultLocale="en"
//         onError={(err) => {
//           if (err.code === 'MISSING_TRANSLATION') {
//             console.warn(`Missing translation for: ${err.messageId}`);
//             return;
//           }
//           console.error(err);
//         }}
//       >
//         <ConnectedRouter history={history}>
//           <Switch>
//             <Route 
//               path="/" 
//               render={(props) => (
//                 <App 
//                   {...props} 
//                   locale={locale} 
//                   changeLocale={changeLocale} 
//                 />
//               )} 
//             />
//           </Switch>
//         </ConnectedRouter>
//       </IntlProvider>
//     </Provider>
//   );
// };

// export default MainApp;
