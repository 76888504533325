import { PAGE_IDS } from 'constants/PageConstants';
import {
  chanegPhoneInitialState,
  secondHandCarInitialState,
  campaignRankInitialState,
  campaignInactiveInitialState,
} from '../initialStates';
import { 
    // RESET_PAGE_STATE,
    // UPDATE_PAGE_STATE,
    RESET_PAGE,
    RESET_PAGE_SUCCESS,
    RESET_PAGE_FAILURE, 
} from 'constants/ActionTypes';

// Map of page IDs to their initial states
const initialStateMap = {
    [PAGE_IDS.CHANGE_PHONE]: chanegPhoneInitialState,
    [PAGE_IDS.SECOND_HAND_CAR]: secondHandCarInitialState,
    [PAGE_IDS.CAMPAIGN_RANK]: campaignRankInitialState,
    [PAGE_IDS.CAMPAIGN_INACTIVE]: campaignInactiveInitialState,   
};

// Initial state for the reset reducer
const initialState = {
    resetting: false,
    resetError: null,
    lastResetPage: null,
    resetHistory: [],
    resetStatus: {} // Tracks reset status for each page
};

// Higher-order reducer creator
export const createPageReducer = (pageId, customReducer) => {
  const initialState = initialStateMap[pageId];
  
  return (state = initialState, action) => {
    switch (action.type) {
      case RESET_PAGE_SUCCESS:
        if (action.payload.pageId === pageId) {
          return {
            ...initialState
          };
        }
        return state;
      default:
        // Handle other actions using the custom reducer if provided
        return customReducer ? customReducer(state, action) : state;
    }
  };
};

// Helper function to update reset status for a specific page
const updateResetStatus = (state, pageId, status, error = null) => ({
    ...state,
    resetStatus: {
      ...state.resetStatus,
      [pageId]: {
        isResetting: status === 'resetting',
        lastReset: status === 'success' ? new Date().toISOString() : state.resetStatus[pageId]?.lastReset,
        error: error,
        success: status === 'success'
      }
    }
  });
  
  const resetReducer = (state = initialState, action) => {

    // console.log("Processing Reducer received action:", action);
    // console.log("Current state:", state);

    switch (action.type) {
      case RESET_PAGE:
        return {
          ...state,
          resetting: true,
          resetError: null,
          lastResetPage: action.payload.pageId,
          resetStatus: {
            ...state.resetStatus,
            [action.payload.pageId]: {
              isResetting: true,
              lastReset: null,
              error: null,
              success: false
            }
          }
          //...updateResetStatus(state, action.payload.pageId, 'resetting')
        };
  
      case RESET_PAGE_SUCCESS:
        return {
          ...state,
          resetting: false,
          resetStatus: {
            ...state.resetStatus,
            [action.payload.pageId]: {
              isResetting: false,
              lastReset: new Date().toISOString(),
              error: null,
              success: true
            }
          },
          resetHistory: [
            ...state.resetHistory,
            {
              pageId: action.payload.pageId,
              timestamp: new Date().toISOString()
            }
          ],
          //...updateResetStatus(state, action.payload.pageId, 'success')
        };
  
      case RESET_PAGE_FAILURE:
        return {
          ...state,
          resetting: false,
          resetError: action.payload.error,
          resetStatus: {
            ...state.resetStatus,
            [action.payload.pageId]: {
              isResetting: false,
              lastReset: null,
              error: action.payload.error,
              success: false
            }
          },
          //...updateResetStatus(state, action.payload.pageId, 'error', action.payload.error)
        };
  
    //   case CLEAR_RESET_HISTORY:
    //     return {
    //       ...state,
    //       resetHistory: []
    //     };
  
    //   case CLEAR_RESET_ERROR:
    //     return {
    //       ...state,
    //       resetError: null,
    //       resetStatus: {
    //         ...state.resetStatus,
    //         [action.payload.pageId]: {
    //           ...state.resetStatus[action.payload.pageId],
    //           error: null
    //         }
    //       }
    //     };
  
      default:
        return state;
    }
  };
  
  // Selectors
//   export const getResetStatus = (state, pageId) => state.reset.resetStatus[pageId] || {
//     isResetting: false,
//     lastReset: null,
//     error: null,
//     success: false
//   };
  
  export const getResetHistory = (state) => state.reset.resetHistory;
  
  export const getLastResetPage = (state) => state.reset.lastResetPage;
  
  export const isResetting = (state) => state.reset.resetting;
  
  export const getResetError = (state) => state.reset.resetError;
  
  // Combine with page reducers
  export const createCombinedReducer = (pageId, pageReducer) => {
    console.log('createCombinedReducer');
    return (state, action) => {
      // Handle reset action in page reducer
      if (action.type === RESET_PAGE_SUCCESS && action.payload.pageId === pageId) {
        return initialStateMap[pageId];
      }
      
      // Handle other actions in page reducer
      return pageReducer(state, action);
    };
  };
  
  export default resetReducer;