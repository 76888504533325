import React from 'react';
import { injectIntl } from 'react-intl';
import Swal from 'sweetalert2';

// Create a helper function to format messages
const formatMessage = (intl, id, values) => {
  return intl.formatMessage({ id }, values);
};

// HOC or Wrapper component for Sweetalert2 with translations
const withTranslatedSwal = (WrappedComponent) => {
  class TranslatedSwalComponent extends React.Component {
    translateSwal = (options) => {
      const { intl } = this.props;
      
      // Translate title
      if (options.title && typeof options.title === 'object') {
        options.title = formatMessage(intl, options.title.props.id, options.title.props.values);
      }
      
      // Translate text
      if (options.text && typeof options.text === 'object') {
        options.text = formatMessage(intl, options.text.props.id, options.text.props.values);
      }
      
      // Translate confirm button text
      if (options.confirmButtonText && typeof options.confirmButtonText === 'object') {
        options.confirmButtonText = formatMessage(intl, options.confirmButtonText.props.id, options.confirmButtonText.props.values);
      }
      
      // Translate cancel button text
      if (options.cancelButtonText && typeof options.cancelButtonText === 'object') {
        options.cancelButtonText = formatMessage(intl, options.cancelButtonText.props.id, options.cancelButtonText.props.values);
      }

      return Swal.fire(options);
    };

    render() {
      return (
        <WrappedComponent 
          {...this.props} 
          translateSwal={this.translateSwal} 
        />
      );
    }
  }

  return injectIntl(TranslatedSwalComponent);
};

export default withTranslatedSwal;