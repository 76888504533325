import { all, takeLatest, takeEvery, call, fork, put } from 'redux-saga/effects';
import {
  getRecentsByDealerSuccess,
  getChatHistoryByChatroomidSuccess,
  hideLoaderRecentsByDealer,
  hideLoaderChatHistoryByChatroomid
} from 'actions/Chats';
import {
  GET_RECENTS_BY_DEALER_REQUEST,
  GET_CHAT_HISTORY_BY_CHATROOMID_REQUEST
} from 'constants/ActionTypes';
import { getFirestore, collection, doc, where, getDocs, addDoc, query, orderBy, limit } from 'firebase/firestore';
import { rsf, firebase, db } from '../firebase/firebase';
// import { rsf, db } from '../firebase/firebase';
// import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";

function* getRecentsByDealer({ payload }) {
  const { dealerCode } = payload;
  try {

    // const colRef = firebase.firestore().collection('RecentThailand');    
    // const snapshot = yield call(
    //   rsf.firestore.getCollection,
    //   colRef.where('userId', '==', dealerCode)
    // );

    // Create reference to the RegisterUsers collection
    const colRef = collection(db, 'RecentThailand');
    
    // Build query with date range
    const q = query(
      colRef,
      where('userId', '==', dealerCode),
    );
    
    // Fetch documents
    const snapshot = yield call(getDocs, q);

    //console.log('items:' + snapshot);
    let items = [];
    snapshot.forEach(doc => {
      //console.log(doc.data());
      var data = doc.data();
      data['id'] = data.recentId;
      items.push(data);

      // datas = {
      //   id: doc.id,
      //   ...doc.data()
      // };
    });
    items.sort((a, b) => {
      return a.withUserOriginNameTh > b.withUserOriginNameTh ? 1 : -1; //ascending
      //return a.dealerCode > b.dealerCode ? -1 : 1; //descending
    });
    //console.log('To:' + items.length);
    yield put(getRecentsByDealerSuccess(items));
  } catch (error) {
    console.log(error);
    yield put(hideLoaderRecentsByDealer());
  }
}

function* getChatHistoryByChatroomid({ payload }) {
  const { dealercode, userid } = payload;

  var chatRoomId = '';
  if (dealercode < userid) {
    chatRoomId = dealercode + userid;
  } else {
    chatRoomId = userid + dealercode;
  }

  //console.log('dec:' + dealercode + ' chtId:' + userid);
  try {
    // const colRef = firebase
    //   .firestore()
    //   .collection('MessageThailand')
    //   .doc(dealercode)
    //   .collection(chatRoomId);
    // //const colRef = query(collection(db, 'MessageThailand', dealercode, chatRoomId));

    // const snapshot = yield call(rsf.firestore.getCollection, colRef);


    // Create reference to the RegisterUsers collection

    const messageDocRef = doc(db, 'MessageThailand', dealercode);
    const colRef = collection(messageDocRef, chatRoomId);
    
    // Build query with date range
    // const q = query(
    //   colRef,
    //   where('date', '>=', startDate),
    //   where('date', '<=', endDate)
    // );
    
    // // Fetch documents
    // const snapshot = yield call(getDocs, q);

    const snapshot = yield call(getDocs, colRef);
    
    //console.log('items:' + snapshot);
    let items = [];
    snapshot.forEach(doc => {
      //console.log(doc.data());
      var data = doc.data();
      data['id'] = data.messageId;
      items.push(data);

      // datas = {
      //   id: doc.id,
      //   ...doc.data()
      // };
    });
    items.sort((a, b) => {
      //return a.date > b.date ? 1 : -1; //ascending
      return a.date > b.date ? -1 : 1; //descending
    });
    //console.log('To:' + items.length);
    yield put(getChatHistoryByChatroomidSuccess(items));
  } catch (error) {
    console.log(error);
    yield put(hideLoaderChatHistoryByChatroomid());
  }
}

export function* watchGetRecentsByDealerRequest() {
  yield takeLatest(GET_RECENTS_BY_DEALER_REQUEST, getRecentsByDealer);
}
export function* watchGetChatHistoryByChatroomidRequest() {
  yield takeLatest(
    GET_CHAT_HISTORY_BY_CHATROOMID_REQUEST,
    getChatHistoryByChatroomid
  );
}

export default function* rootSaga() {
  yield all([
    fork(watchGetRecentsByDealerRequest),
    fork(watchGetChatHistoryByChatroomidRequest)
  ]);
}
