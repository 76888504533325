import { all, take, call, put, cancelled, fork, race, select, cancel, takeEvery } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import {
    processDataRequest,
    processDataProgress,
    processDataSuccess,
    processDataFailure
} from 'actions/CampaignRank';
import {
    PROCESS_CAMPAIGN_RANK_REQUEST,
    PROCESS_CAMPAIGN_RANK_CANCEL,
 } from 'constants/ActionTypes';

//import { rsf, firebase } from '../firebase/firebase';

// Selector to check if processing is already running
//const selectIsProcessing = (state) => state.processing?.isProcessing;

//const selectIsProcessing = (state) => state.isProcessing;

// Debug selector to check entire state
const logEntireState = (state) => {
  console.log('Entire Redux State:', state);
  return state;
};

// Try different selectors based on your state structure
const selectIsProcessing = (state) => {
  // Debug log
  //console.log('Current State:', state);

  //console.log('AAA:' + state?.campaignRank.isProcessing);

  return state?.campaignRank?.isProcessing;
  
  // Access processing state based on your reducer structure
  // return state?.processing?.isProcessing || 
  //        state?.isProcessing || 
  //        false;
};

// Create an event channel for SSE with Parameters
function createSSEChannel(url, params) {
    return eventChannel(emitter => {

        // Add parameters to URL
        const queryString = new URLSearchParams(params).toString();
        const fullUrl = `${url}${queryString ? `?${queryString}` : ''}`;
        //const fullUrl = `${url}?${queryString}`;

        //console.log('fullUrl:' + fullUrl);

        const eventSource = new EventSource(fullUrl);

        eventSource.onopen = () => {
            //console.log('EventSource connected successfully');
          };
      
          eventSource.onmessage = (event) => {
            try {
              //console.log('Received SSE message:', event.data); // Debug log
              const data = JSON.parse(event.data);
              emitter(data);
            } catch (error) {
              console.error('Error parsing SSE data:', error);
              emitter(new Error('Failed to parse server response'));
            }
          };
          
          eventSource.onerror = (error) => {
            console.error('EventSource error:', error);
            if (eventSource.readyState === EventSource.CLOSED) {
              console.log('EventSource is closed');
            } else if (eventSource.readyState === EventSource.CONNECTING) {
              console.log('EventSource is trying to reconnect');
            }
            emitter(new Error(`Connection failed: ${error?.message || 'Unknown error'}`));
            eventSource.close();
          };

        // eventSource.onmessage = (event) => {
        //     const data = JSON.parse(event.data);
        //     emitter(data);
        // };

        // eventSource.onerror = (error) => {
        //     emitter(new Error(error));
        //     eventSource.close();
        // };

        // const eventSource = new EventSource(url);

        // eventSource.onmessage = (event) => {
        //     try {
        //         const data = JSON.parse(event.data);
        //         emitter(data);
        //     } catch (error) {
        //         console.error('Error parsing SSE data:', error);
        //         emitter(new Error('Failed to parse server response'));
        //     }
        // };
            
        // eventSource.onerror = () => {
        //     console.error('EventSource error');
        //     eventSource.close();
        //     emitter(new Error('EventSource connection failed'));
        // };

        return () => {
            console.log('Closing EventSource connection');
            eventSource.close();
        };
    });
}

function* processDataFlow(params) {
    
    let channel = null;

    // Debug: Log entire state
    //yield select(logEntireState);

    // Check if already processing
    // const isProcessing = yield select(selectIsProcessing);

    // console.log('isProcessing:' + isProcessing);

    // if (isProcessing) {
    //   console.log('Process already running, ignoring new request');
    //   return;
    // }
    
    try {
     

      // Define your Firebase Function URL
    //   const functionUrl = process.env.NODE_ENV === 'development'
    //     ? 'http://localhost:5001/your-project-id/us-central1/processLargeDataset'
    //     : 'https://your-region-your-project-id.cloudfunctions.net/processLargeDataset';
  
      


      // START HERE

      const functionUrl =  'https://asia-east2-hello-suzuki.cloudfunctions.net/campaignRank';
      //const functionUrl =  'https://asia-east2-hello-suzuki.cloudfunctions.net/processLargeDataset';

      console.log('Starting process with params:', params); // Debug log

      // Create channel with parameters
      channel = yield call(createSSEChannel, functionUrl, params);          
  
      while (true) {
        const { data, timeout } = yield race({
          data: take(channel),
          timeout: new Promise(resolve => setTimeout(resolve, 600000)) // 10 minutes timeout
        });
  
        if (timeout) {
          throw new Error('Processing timed out');
        }
  
        if (data instanceof Error) {
          throw data;
        }
  
        if (data.status === 'completed') {
          yield put(processDataSuccess());
          break;
        }
  
        if (data?.processedCount !== undefined) {
          yield put(processDataProgress({
            processedCount: data.processedCount,
            totalRecords: data.totalRecords,
            percentage: data.percentage
          }));
        }

        // yield put(processDataProgress({
        //   processedCount: 30000,
        //   totalRecords: 30000,
        //   percentage: 100.00
        // }));

        // yield put(processDataSuccess());
        //   break;
  
        
      }
    } catch (error) {
      console.error('Saga error:', JSON.stringify(error));
      console.log(error);
      yield put(processDataFailure(JSON.stringify(error.message)));
    } finally {
      if (channel) {
        channel.close();
      }
      if (yield cancelled()) {
        if (channel) {
          channel.close();
        }
      }
    }
}


// Create an event channel for SSE Without Parameter
// function createSSEChannel(url) {
//   return eventChannel(emitter => {
//     const eventSource = new EventSource(url);
    
//     eventSource.onmessage = (event) => {
//       const data = JSON.parse(event.data);
//       emitter(data);
//     };
    
//     eventSource.onerror = (error) => {
//       emitter(new Error(error));
//     };

//     // Return unsubscribe function
//     return () => {
//       eventSource.close();
//     };
//   });
// }


//Without Parameter
// function* processDataFlow() {
//     let channel = null;
    
//     try {
//     //   const functionUrl = process.env.NODE_ENV === 'development'
//     //     ? 'http://localhost:5001/your-project-id/us-central1/processLargeDataset'
//     //     : 'https://your-region-your-project-id.cloudfunctions.net/processLargeDataset';
  

//       const functionUrl =  'https://asia-east2-hello-suzuki.cloudfunctions.net/processLargeDataset';
      
//       // Create the channel
//       channel = yield call(createSSEChannel, functionUrl);
  
//       // Process incoming messages
//       while (true) {
//         const data = yield take(channel);
//         console.log('Received SSE data:', data);
  
//         if (data instanceof Error) {
//           throw data;
//         }
  
//         if (data.status === 'completed') {
//           yield put(processDataSuccess());
//           break;
//         } else {
//           yield put(processDataProgress({
//             processedCount: data.processedCount,
//             totalRecords: data.totalRecords,
//             percentage: data.percentage
//           }));
//         }
//       }
//     } catch (error) {
//         console.error('Saga error:', error);
//         yield put(processDataFailure(error.message));
//     } finally {
//         if (channel) {
//             channel.close();
//         }
//         if (yield cancelled()) {
//             if (channel) {
//                 channel.close();
//             }
//         }
//     }
//   }


//   function createEventChannelOld(url) {
//     return new Promise((resolve, reject) => {
      
//       //Without withCredentials
//       const eventSource = new EventSource(url);
  
//       //With withCredentials
//       // const eventSource = new EventSource(url, {
//       //     withCredentials: true
//       // });
      
//       eventSource.onopen = () => {
//         resolve(eventSource);
//       };
      
//       eventSource.onerror = (error) => {
//         reject(error);
//         eventSource.close();
//       };
//     });
//   }

// function* processDataFlowOld() {
//   let eventSource = null;
  
//   try {
//     // Connect to the Firebase Function
//     eventSource = yield call(
//         createEventChannelOld,
//       'https://asia-east2-hello-suzuki.cloudfunctions.net/processLargeDataset'
//     );

//     // Create a Promise that will handle the messages
//     yield new Promise((resolve, reject) => {
//         eventSource.onmessage = function(event) {
//           try {
//             const data = JSON.parse(event.data);
//             console.log('Received SSE data:', data); // Debug log
            
//             if (data.error) {
//               reject(new Error(data.error));
//             }
            
//             if (data.status === 'completed') {
//               put(processDataSuccess());
//               eventSource.close();
//               resolve();
//             } else {
//               // Fix: Use function call syntax for put
//               put(processDataProgress({
//                 processedCount: data.processedCount,
//                 totalRecords: data.totalRecords,
//                 percentage: data.percentage
//               }));
//             }
//           } catch (error) {
//             console.error('Error processing message:', error);
//             reject(error);
//           }
//         };
//       });
    
//   } catch (error) {
//     console.error('Saga error:', error);
//     yield put(processDataFailure(error.message));
//   } finally {
//     if (yield cancelled()) {
//       if (eventSource) {
//         eventSource.close();
//       }
//     }
//   }
// }

// export function* watchProcessDataOld() {
//   while (true) {
//     yield take(PROCESS_DATA_REQUEST);
//     const processingTask = yield fork(processDataFlow);
//     yield take([PROCESS_DATA_SUCCESS, PROCESS_DATA_FAILURE]);
//     yield cancel(processingTask);
//   }
// }



// Without Params
// export function* watchProcessData() {
//     while (true) {
//       yield take(PROCESS_DATA_REQUEST);
//       yield fork(processDataFlow);
//     }
//     // yield takeEvery(PROCESS_DATA_REQUEST, processDataFlow);
// }

// With Params
export function* watchProcessData() {
    while (true) {

      // Check if already processing before starting new process
      const isProcessing = yield select(selectIsProcessing);
      console.log('isProcessing:' + isProcessing);
      if (isProcessing) {
        console.log('Process already running, ignoring new request');
        continue;
      }

      // Modified to get parameters from action
      const action = yield take(PROCESS_CAMPAIGN_RANK_REQUEST);
      const params = action.payload || {};

      if (action.payload?.cancel) {
        // Handle cancellation
        continue;
      }

      

      // Set processing state to true before starting
      // yield put({
      //   type: PROCESS_DATA_REQUEST,
      //   payload: { isProcessing: true }
      // });
  
      yield race({
        task: call(processDataFlow, params),
        cancel: take(PROCESS_CAMPAIGN_RANK_CANCEL) //'CANCEL_PROCESSING'
      });
    }
}


export default function* rootSaga() {
  yield all([
    fork(watchProcessData),
    // fork(watchGetRedeemByDealerRequest),
    // fork(watchGetRedeemDealerListRequest),
  ]);
}