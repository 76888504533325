import { all, takeEvery, takeLatest, call, fork, put } from 'redux-saga/effects';
import {
    getActiveUsersAllSuccess,
    hideLoaderGetActiveUsersAll,
    getUsersByDefaultDealerSuccess,
    hideLoaderGetUsersByDefaultDealer,
    updateActiveUsersSuccess,
    // getActiveUserByDateSuccess,
    // getInActiveUserByDateSuccess,
    // hideLoaderGetActiveUsers,
    // hideLoaderGetInactiveUsers,
} from 'actions/ActiveUsers';
import {
    GET_ACTIVE_USERS_ALL_REQUEST,
    GET_USERS_BY_DEFAULT_DEALER_REQUEST,
    UPDATE_ACTIVE_USERS_REQUEST,
    // GET_ACTIVE_USERS_BY_DATE_REQUEST,
    // GET_INACTIVE_USERS_BY_DATE_REQUEST,
} from 'constants/ActionTypes';
import { getFirestore, collection, query, where, doc, getDoc, getDocs, updateDoc } from 'firebase/firestore';
import { rsf, firebase, db } from '../firebase/firebase';
// import { rsf, db } from '../firebase/firebase';
// import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";


function* getActiveUsersAll({ payload }) {
  //const { dealerCode } = payload;
  //console.log('ddddealerCode:' + dealerCode);

  //console.log('Call getActiveUsersAll Sagas');

  try {


    // const colRef = firebase.firestore().collection('ActiveUsersThailand');    

    // const snapshot = yield call(rsf.firestore.getCollection, colRef);

    // Get reference to the collection
    const colRef = collection(db, 'ActiveUsersThailand');

    // Get all documents (no query needed)
    const snapshot = yield call(getDocs, colRef);

    let items = [];
    snapshot.forEach((doc) => {
      //console.log(doc.data());
      var data = doc.data();
      data['id'] = data.dealerCode;
      items.push(data);

      // datas = {
      //   id: doc.id,
      //   ...doc.data()
      // };
    });
    items.sort((a, b) => {
      return a.updatedDate > b.updatedDate ? 1 : -1; //ascending
      //return a.dealerCode > b.dealerCode ? -1 : 1; //descending
    });
    //console.log('To:' + items.length);
    yield put(getActiveUsersAllSuccess(items));
  } catch (error) {
    console.log(error);
    yield put(hideLoaderGetActiveUsersAll());
  }
}

function* getUsersByDefaultDealer({ payload }) {
  const { dealerCode } = payload;
  //console.log('ddddealerCode:' + dealerCode);
  try {


    // const colRef = firebase.firestore().collection('UsersThailand');
    
    // const snapshot = yield call(
    //   rsf.firestore.getCollection,
    //   colRef.where('dealerCode', '==', dealerCode).where('active', '==', true)    
    // );


    // Create reference to the RegisterUsers collection
    const colRef = collection(db, 'UsersThailand');
    
    // Build query with date range
    const q = query(
      colRef,
      where('dealerCode', '==', dealerCode),
      where('active', '==', true)
    );
    
    // Fetch documents
    const snapshot = yield call(getDocs, q);


    //console.log('items:' + snapshot);
    let items = [];
    snapshot.forEach((doc) => {
      //console.log(doc.data());
      var data = doc.data();
      data['id'] = data.objectId;
      items.push(data);

      // datas = {
      //   id: doc.id,
      //   ...doc.data()
      // };
    });
    items.sort((a, b) => {
      return a.createdAt > b.createdAt ? 1 : -1; //ascending
      //return a.dealerCode > b.dealerCode ? -1 : 1; //descending
    });
    //console.log('To:' + items.length);
    yield put(getUsersByDefaultDealerSuccess(items));
  } catch (error) {
    console.log(error);
    yield put(hideLoaderGetUsersByDefaultDealer());
  }
}

function* updateActiveUsers({ payload }) {
  const { updateData } = payload;

  try {
    

    // yield call(
    //   rsf.firestore.updateDocument,
    //   'ActiveUsersThailand/' + updateData.dealerCode,
    //   updateData
    // );

    // Create reference to the specific document
    const docRef = doc(db, 'ActiveUsersThailand', updateData.dealerCode);
    
    // Update the document
    yield call(updateDoc, docRef, updateData);


    yield put(updateActiveUsersSuccess());
    //yield put(showMessageReward('Success'));
  } catch (error) {
    console.log(error);
    //yield put(hideLoaderUpdateReward());
  }
}

// function* getActiveUsersByDate({ payload }) {
//   const { startDate, endDate } = payload;
//   const startDateStr = startDate + '000000';
//   const endDateStr = endDate + '595959';
//   try {
//     const colRef = firebase.firestore().collection('UsersThailand');
//     const snapshot = yield call(
//       rsf.firestore.getCollection,
//       colRef
//         .where('createdAt', '>=', startDateStr)
//         .where('createdAt', '<=', endDateStr)
//     );
//     //console.log('items:' + snapshot);
//     let items = [];
//     snapshot.forEach((doc) => {
//       //console.log(doc.data());
//       var data = doc.data();
//       data['id'] = data.objectId;
//       items.push(data);

//       // datas = {
//       //   id: doc.id,
//       //   ...doc.data()
//       // };
//     });
//     //console.log('To:' + items.length);
//     yield put(getActiveUserByDateSuccess(items));
//   } catch (error) {
//     console.log(error);
//     yield put(hideLoaderGetActiveUsers());
//   }
// }

// function* getInactiveUsersByDate({ payload }) {
//     const { startDate, endDate } = payload;
//     const startDateStr = startDate + '000000';
//     const endDateStr = endDate + '595959';
//     try {
//       const colRef = firebase.firestore().collection('UsersThailand');
//       const snapshot = yield call(
//         rsf.firestore.getCollection,
//         colRef
//           .where('createdAt', '>=', startDateStr)
//           .where('createdAt', '<=', endDateStr)
//       );
//       //console.log('items:' + snapshot);
//       let items = [];
//       snapshot.forEach((doc) => {
//         //console.log(doc.data());
//         var data = doc.data();
//         data['id'] = data.objectId;
//         items.push(data);
  
//         // datas = {
//         //   id: doc.id,
//         //   ...doc.data()
//         // };
//       });
//       console.log('To:' + items.length);
//       yield put(getInActiveUserByDateSuccess(items));
//     } catch (error) {
//       console.log(error);
//       yield put(hideLoaderGetInactiveUsers());
//     }
//   }

  export function* watchGetActiveUsersAllRequest() {
    yield takeLatest(GET_ACTIVE_USERS_ALL_REQUEST, getActiveUsersAll);
  }

  export function* watchGetUsersByDefaultDealerRequest() {
    yield takeLatest(GET_USERS_BY_DEFAULT_DEALER_REQUEST, getUsersByDefaultDealer);
  }

  export function* watchUpdateActiveUsersRequest() {
    yield takeLatest(UPDATE_ACTIVE_USERS_REQUEST, updateActiveUsers);
  }
  // export function* watchGetActiveUserByDateRequest() {
  //   yield takeEvery(GET_ACTIVE_USERS_BY_DATE_REQUEST, getActiveUsersByDate);
  // }

  // export function* watchGetInactiveUserByDateRequest() {
  //   yield takeEvery(GET_INACTIVE_USERS_BY_DATE_REQUEST, getInactiveUsersByDate);
  // }
  
  export default function* rootSaga() {
    yield all([
      fork(watchGetActiveUsersAllRequest),
      fork(watchGetUsersByDefaultDealerRequest),
      fork(watchUpdateActiveUsersRequest),
      // fork(watchGetActiveUserByDateRequest),
      // fork(watchGetInactiveUserByDateRequest),
    ]);
  }