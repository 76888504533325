import { all, takeLatest, takeEvery, call, fork, put } from 'redux-saga/effects';
import {    
    getUserSecondCarSuccess,
    hideGetUserSecondHandCarLoader,
    secondHandCarSuccess,
    hideSecondHandCarLoader,
    showMessageSecondHandCar
} from 'actions/SecondHandCar';
import {
    GET_USER_SECOND_HAND_CAR_REQUEST,
    SECOND_HAND_CAR_REQUEST
} from 'constants/ActionTypes';
import { getFirestore, collection, query, where, getDoc, getDocs } from 'firebase/firestore';
import { rsf, firebase, db } from '../firebase/firebase';
import { getFunctions, httpsCallable } from 'firebase/functions';

const functions = getFunctions(firebase, 'asia-east2'); // specify your region

function* getUserSecondHandCar({ payload }) {
    const { phoneNumber, vinNumber } = payload;
    try {
      // const colRef = firebase.firestore().collection('UsersThailand');
      
      // const snapshot = yield call(
      //   rsf.firestore.getCollection,
      //   colRef
      //       //.where('phone', '==', phoneNumber)
      //       .where('chassisNumber', 'array-contains', vinNumber)        
      //       //.where('isHaveCar', '==', true)                
      // );

      const colRef = collection(db, 'UsersThailand');
    
      // Build query with date range
      const q = query(
        colRef,
        where('chassisNumber', 'array-contains', vinNumber),        
      );
      
      // Fetch documents
      const snapshot = yield call(getDocs, q);

      //console.log('items:' + snapshot);
      let items = [];
      snapshot.forEach((doc) => {
        //console.log(doc.data());
        var data = doc.data();
        data['id'] = data.objectId;
        items.push(data);
  
        // datas = {
        //   id: doc.id,
        //   ...doc.data()
        // };
      });
    //   items.sort((a, b) => {
    //     return a.createdAt > b.createdAt ? 1 : -1; //ascending
    //     //return a.dealerCode > b.dealerCode ? -1 : 1; //descending
    //   });
      //console.log('NewTo:' + items.length);
      yield put(getUserSecondCarSuccess(items));
    } catch (error) {
      console.log(error);
      yield put(hideGetUserSecondHandCarLoader());
    }
  }

function* secondHandCar({ payload }) {
    const { userId, vinNumber, isActive } = payload;
    try {
        // console.log('userId:' + userId + ' vinNumber:' + vinNumber + ' isActive:' + isActive);
        // const postParams = {
        //   userid: userId,
        //   vinnumber: vinNumber,
        //   isactive: isActive
        // };
        
        // rsf.region = 'asia-east2';
        // const json = yield call(rsf.functions.call, 'secondHandCar', postParams);

        

        const postParams = {
          userid: userId,
          vinnumber: vinNumber,
          isactive: isActive
        };

        const functionUrl =  'https://asia-east2-hello-suzuki.cloudfunctions.net/secondHandCar';

        const url = new URL(functionUrl);
        Object.keys(postParams).forEach(key => 
          url.searchParams.append(key, postParams[key])
        );

        const response = yield call(fetch, url.toString(), {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(postParams)
        });

        const json = yield call([response, 'json']);

        //console.log('result:' + json);

        //console.log('CHAHHHH:' + JSON.stringify(json, null, "  "));
        // console.log('CHAHHHH2:' + JSON.stringify(json.data.STATUS_CODE, null, "  "));                
        yield put(secondHandCarSuccess(json.STATUS_CODE));
        yield put(showMessageSecondHandCar(json.STATUS_CODE));
        

        // yield put(secondHandCarSuccess(100));
        // yield put(showMessageSecondHandCar(100));
        //yield put(changePhoneSuccess(json));
    } catch (error) {
      console.log(error);
      yield put(hideSecondHandCarLoader());
    }
  }

  export function* watchUserSecondHandRequest() {
    yield takeLatest(GET_USER_SECOND_HAND_CAR_REQUEST, getUserSecondHandCar);
  }
  
  export function* watchSecondHandCarRequest() {
    yield takeLatest(SECOND_HAND_CAR_REQUEST, secondHandCar);
  }
  
  export default function* rootSaga() {
    yield all([
      fork(watchUserSecondHandRequest),
      fork(watchSecondHandCarRequest),
    ]);
  }