import {
    // RESET_PAGE_STATE,
    // UPDATE_PAGE_STATE,
    RESET_PAGE,
    RESET_PAGE_SUCCESS,
    RESET_PAGE_FAILURE,
} from 'constants/ActionTypes';


// export const resetPageState = (pageId) => ({
//     type: RESET_PAGE_STATE,
//     payload: pageId
// });

// export const updatePageState = (pageId, data) => ({
//     type: UPDATE_PAGE_STATE,
//     payload: { pageId, data }
// });

export const resetPage = (pageId) => ({
    type: RESET_PAGE,
    payload: { pageId }
});
  
export const resetPageSuccess = (pageId) => ({
    type: RESET_PAGE_SUCCESS,
    payload: { pageId }
});

export const resetPageFailure = (pageId) => ({
    type: RESET_PAGE_FAILURE,
    payload: { pageId }
});