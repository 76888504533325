import { all, takeLatest, takeEvery, call, fork, put } from 'redux-saga/effects';
import {
  getUsersByDateSuccess,
  getUsersByDealerSuccess,
  getUsersByDealerByDateSuccess,
  getUsersByDefaultDealerByDateSuccess,
  getFriendsByDealerSuccess,
  hideLoaderUsersByDate,
  hideLoaderUsersByDealer,
  hideLoaderUsersByDealerByDate,
  hideLoaderFriendsByDealer,
} from 'actions/Users';
import {
  GET_USERS_BY_DATE_REQUEST,
  GET_USERS_BY_DEALER_REQUEST,
  GET_USERS_BY_DEALER_BY_DATE_REQUEST,
  GET_USERS_BY_DEFAULT_DEALER_BY_DATE_REQUEST,
  GET_FRIENDS_BY_DEALER_REQUEST,
} from 'constants/ActionTypes';
import { getFirestore, collection, query, where, doc, getDoc, getDocs } from 'firebase/firestore';
import { rsf, firebase, db } from '../firebase/firebase';


function* getUsersByDate({ payload }) {
  const { startDate, endDate } = payload;
  const startDateStr = startDate + '000000';
  const endDateStr = endDate + '595959';
  try {
    // const colRef = firebase.firestore().collection('UsersThailand');
    
    // const snapshot = yield call(
    //   rsf.firestore.getCollection,
    //   colRef
    //     .where('createdAt', '>=', startDateStr)
    //     .where('createdAt', '<=', endDateStr)
    // );

    const colRef = collection(db, 'UsersThailand');
    
    // Build query with date range
    const q = query(
      colRef,
      where('createdAt', '>=', startDateStr),
      where('createdAt', '<=', endDateStr)
    );
    
    // Fetch documents
    const snapshot = yield call(getDocs, q);

    //console.log('items:' + snapshot);
    let items = [];
    snapshot.forEach((doc) => {
      //console.log(doc.data());
      var data = doc.data();
      data['id'] = data.objectId;
      items.push(data);
      //console.log('userId:' + data.objectId);

      // datas = {
      //   id: doc.id,
      //   ...doc.data()
      // };
    });
    //console.log('To:' + items.length);
    yield put(getUsersByDateSuccess(items));
  } catch (error) {
    console.log(error);
    yield put(hideLoaderUsersByDate());
  }
}

function* getUsersByDealer({ payload }) {
  const { dealerCode } = payload;
  //console.log('ddddealerCode:' + dealerCode);
  try {
    // const colRef = firebase.firestore().collection('UsersThailand');
    // // const colRef = query(collection(db, 'UsersThailand'));
    // const snapshot = yield call(
    //   rsf.firestore.getCollection,
    //   colRef.where('friends', 'array-contains', dealerCode)
    // );

    const colRef = collection(db, 'UsersThailand');
    
    // Build query with date range
    const q = query(
      colRef,
      where('friends', 'array-contains', dealerCode),      
    );
    
    // Fetch documents
    const snapshot = yield call(getDocs, q);

    //console.log('items:' + snapshot);
    let items = [];
    snapshot.forEach((doc) => {
      //console.log(doc.data());
      var data = doc.data();
      data['id'] = data.objectId;
      items.push(data);

      // datas = {
      //   id: doc.id,
      //   ...doc.data()
      // };
    });
    items.sort((a, b) => {
      return a.createdAt > b.createdAt ? 1 : -1; //ascending
      //return a.dealerCode > b.dealerCode ? -1 : 1; //descending
    });
    //console.log('To:' + items.length);
    yield put(getUsersByDealerSuccess(items));
  } catch (error) {
    console.log(error);
    yield put(hideLoaderUsersByDealer());
  }
}

function* getUsersByDealerByDate({ payload }) {
  const { dealerCode, startDate, endDate } = payload;
  const startDateStr = startDate + '000000';
  const endDateStr = endDate + '595959';
  try {
    // const colRef = firebase.firestore().collection('UsersThailand');
    
    // const snapshot = yield call(
    //   rsf.firestore.getCollection,
    //   colRef
    //     .where('friends', 'array-contains', dealerCode)
    //     .where('createdAt', '>=', startDateStr)
    //     .where('createdAt', '<=', endDateStr)
    // );

    const colRef = collection(db, 'UsersThailand');
    
    // Build query with date range
    const q = query(
      colRef,
      where('friends', 'array-contains', dealerCode), 
      where('createdAt', '>=', startDateStr), 
      where('createdAt', '<=', endDateStr),      
    );
    
    // Fetch documents
    const snapshot = yield call(getDocs, q);

    //console.log('items:' + snapshot);
    let items = [];
    snapshot.forEach((doc) => {
      //console.log(doc.data());
      var data = doc.data();
      data['id'] = data.objectId;
      items.push(data);

      // datas = {
      //   id: doc.id,
      //   ...doc.data()
      // };
    });
    items.sort((a, b) => {
      return a.createdAt > b.createdAt ? 1 : -1; //ascending
      //return a.dealerCode > b.dealerCode ? -1 : 1; //descending
    });
    //console.log('To:' + items.length);
    yield put(getUsersByDealerByDateSuccess(items));
  } catch (error) {
    console.log(error);
    yield put(hideLoaderUsersByDealerByDate());
  }
}

function* getUsersByDefaultDealerByDate({ payload }) {
  const { dealerCode, startDate, endDate } = payload;
  const startDateStr = startDate + '000000';
  const endDateStr = endDate + '595959';
  try {
    // const colRef = firebase.firestore().collection('UsersThailand');
    
    // const snapshot = yield call(
    //   rsf.firestore.getCollection,
    //   colRef
    //     //.where('friends', 'array-contains', dealerCode)
    //     .where('dealerCode', '==', dealerCode)
    //     .where('createdAt', '>=', startDateStr)
    //     .where('createdAt', '<=', endDateStr)
    // );

    const colRef = collection(db, 'UsersThailand');
    
    // Build query with date range
    const q = query(
      colRef,
      where('dealerCode', '==', dealerCode), 
      where('createdAt', '>=', startDateStr), 
      where('createdAt', '<=', endDateStr),      
    );
    
    // Fetch documents
    const snapshot = yield call(getDocs, q);

    //console.log('items:' + snapshot);
    let items = [];
    snapshot.forEach((doc) => {
      //console.log(doc.data());
      var data = doc.data();
      data['id'] = data.objectId;
      // if (data.friends > 0) {
      //   if (data.friends[0] === dealerCode) {
      //     items.push(data);
      //   }
      // }

      items.push(data);

      // datas = {
      //   id: doc.id,
      //   ...doc.data()
      // };
    });
    items.sort((a, b) => {
      return a.createdAt > b.createdAt ? 1 : -1; //ascending
      //return a.dealerCode > b.dealerCode ? -1 : 1; //descending
    });
    //console.log('To:' + items.length);
    yield put(getUsersByDealerByDateSuccess(items));
  } catch (error) {
    console.log(error);
    yield put(hideLoaderUsersByDealerByDate());
  }
}

function* getFriendsByDealer({ payload }) {
  const { dealerCode } = payload;
  try {
    // const colRef = firebase
    //   .firestore()
    //   .collection('DealerFriendsThailand')
    //   .doc(dealerCode)
    //   .collection(dealerCode);
    
    // const snapshot = yield call(rsf.firestore.getCollection, colRef);
    
    const docRef = doc(db, 'DealerFriendsThailand', dealerCode);    
    const colRef = collection(docRef, dealerCode);
  
    const snapshot = yield call(getDocs, colRef); 
        
    let items = [];
    snapshot.forEach((doc) => {
      //console.log(doc.data());
      var data = doc.data();
      data['id'] = data.friendId;
      items.push(data);

      // datas = {
      //   id: doc.id,
      //   ...doc.data()
      // };
    });
    items.sort((a, b) => {
      return a.friendNameTh > b.friendNameTh ? 1 : -1; //ascending
      //return a.dealerCode > b.dealerCode ? -1 : 1; //descending
    });
    console.log('To:' + items.length);
    yield put(getFriendsByDealerSuccess(items));
  } catch (error) {
    console.log(error);
    yield put(hideLoaderFriendsByDealer());
  }
}

export function* watchGetUsersByDateRequest() {
  yield takeLatest(GET_USERS_BY_DATE_REQUEST, getUsersByDate);
}
export function* watchGetUsersByDealerRequest() {
  yield takeLatest(GET_USERS_BY_DEALER_REQUEST, getUsersByDealer);
}
export function* watchGetUsersByDealerByDateRequest() {
  yield takeLatest(GET_USERS_BY_DEALER_BY_DATE_REQUEST, getUsersByDealerByDate);
}
export function* watchGetUsersByDefaultDealerByDateRequest() {
  yield takeLatest(
    GET_USERS_BY_DEFAULT_DEALER_BY_DATE_REQUEST,
    getUsersByDefaultDealerByDate
  );
}
export function* watchGetFriendsByDealerRequest() {
  yield takeLatest(GET_FRIENDS_BY_DEALER_REQUEST, getFriendsByDealer);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetUsersByDateRequest),
    fork(watchGetUsersByDealerRequest),
    fork(watchGetUsersByDealerByDateRequest),
    fork(watchGetUsersByDefaultDealerByDateRequest),
    fork(watchGetFriendsByDealerRequest),
  ]);
}
