import { all, takeLatest, takeEvery, call, fork, put } from 'redux-saga/effects';
import {
  getDealersSuccess,
  getUsersDefaultSuccess,
  createDealerAccountSuccess,
  addDealerDataSuccess,
  hideLoader,
  hideLoaderUsersDefault,
  hideLoaderCreateDealerAccount,
  hideLoaderAddDealerData
} from 'actions/Dealers';
import {
  GET_DEALERS_REQUEST,
  GET_USERS_DEFAULT_DEALERS_REQUEST,
  CREATE_DEALER_ACCOUNT_REQUEST,
  ADD_DEALER_DATA_REQUEST
} from 'constants/ActionTypes';
import { getFirestore, collection, query, where, getDoc, getDocs } from 'firebase/firestore';
import { rsf, firebase, db } from '../firebase/firebase';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';


function* getDealer() {
  try {
    // const colRef = firebase.firestore().collection('DealersThailand');
    
    // const snapshot = yield call(rsf.firestore.getCollection, colRef);
   

    // Create reference to the RegisterUsers collection
    const colRef = collection(db, 'DealersThailand');
    
    // Build query with date range
    // const q = query(
    //   colRef,
    //   where('date', '>=', startDate),
    //   where('date', '<=', endDate)
    // );
    
    // Fetch documents
    const snapshot = yield call(getDocs, colRef);

    //console.log('items:' + snapshot);
    let items = [];
    snapshot.forEach(doc => {
      //console.log(doc.data());
      var data = doc.data();
      data['id'] = data.dealerCode;
      data['friendsCount'] = data.friends.length;
      //console.log('T:' + data.friends.length);
      if ((data.dealerCode !== '988888') && (data.dealerCode !== '999999')) {
        items.push(data);
      }

      // datas = {
      //   id: doc.id,
      //   ...doc.data()
      // };
    });
    console.log('To:' + items[1].friendsCount);
    yield put(getDealersSuccess(items));
  } catch (error) {
    console.log(error);
    yield put(hideLoader());
  }
}

function* getUsersDefault({ payload }) {
  //const { dealerCode } = payload;
  //console.log('ddddealerCode:' + dealerCode);

  //console.log('Call getActiveUsersAll Sagas');

  try {

    // const colRef = firebase.firestore().collection('ActiveUsersThailand');   

    // const snapshot = yield call(rsf.firestore.getCollection, colRef);

    // Create reference to the RegisterUsers collection
    const colRef = collection(db, 'ActiveUsersThailand');
      
    // Build query with date range
    // const q = query(
    //   colRef,
    //   where('date', '>=', startDate),
    //   where('date', '<=', endDate)
    // );
    
    // Fetch documents
    const snapshot = yield call(getDocs, colRef);
    
    //console.log('items:' + snapshot);
    let items = [];
    snapshot.forEach((doc) => {
      //console.log(doc.data());
      var data = doc.data();
      data['id'] = data.dealerCode;
      items.push(data);

      // datas = {
      //   id: doc.id,
      //   ...doc.data()
      // };
    });
    items.sort((a, b) => {
      return a.updatedDate > b.updatedDate ? 1 : -1; //ascending
      //return a.dealerCode > b.dealerCode ? -1 : 1; //descending
    });
    //console.log('Users Defaults To:' + items.length);
    yield put(getUsersDefaultSuccess(items));
  } catch (error) {
    console.log(error);
    yield put(hideLoaderUsersDefault());
  }
}

// const createUserWithEmailPasswordRequest = async (email, password) =>
//   await auth
//     .createUserWithEmailAndPassword(email, password)
//     .then(authUser => authUser)
//     .catch(error => error);
  
// Using async/await
/*
const createUserWithEmailPasswordRequest = async (email, password) => {
  const auth = getAuth();
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    return userCredential.user;
  } catch (error) {
    throw error;
  }
};
// Using with Redux-Saga
function* createUserSaga(action) {
  try {
    const auth = getAuth();
    const { email, password } = action.payload;
    
    const userCredential = yield call(
      createUserWithEmailAndPassword,
      auth,
      email,
      password
    );
    
    yield put({ 
      type: 'CREATE_USER_SUCCESS', 
      payload: userCredential.user 
    });
    
    return userCredential.user;
  } catch (error) {
    yield put({ 
      type: 'CREATE_USER_ERROR', 
      payload: error.message 
    });
    throw error;
  }
}
*/

function* createDealerAccount({ payload }) {
  const { email, password } = payload;
  console.log(JSON.stringify(payload));
  //console.log('emaila:' + email + ' passa:' + password);
  // try {
  //   const signUpDealer = yield call(
  //     createUserWithEmailPasswordRequest,
  //     email,
  //     password
  //   );
  //   if (signUpDealer.message) {
  //     //yield put(showAuthMessage(signUpUser.message));
  //     console.log('Create Dealer Error:' + signUpDealer.message);
  //     yield put(hideLoaderCreateDealerAccount);
  //   } else {
  //     //localStorage.setItem('user_id', signUpUser.user.uid);
  //     yield put(createDealerAccountSuccess(signUpDealer.user.uid));
  //   }
  // } catch (error) {
  //   console.log('Create Dealer Error Catch:' + error);
  //   yield put(hideLoaderCreateDealerAccount);
  //   //yield put(showAuthMessage(error));
  // }
}

function* addDealerData({ payload }) {}

export function* watchGetDealersRequest() {
  yield takeLatest(GET_DEALERS_REQUEST, getDealer);
}
export function* watchGetUsersDefaultRequest() {
  yield takeLatest(GET_USERS_DEFAULT_DEALERS_REQUEST, getUsersDefault);
}
export function* watchCreateDealerAccountRequest() {
  yield takeLatest(CREATE_DEALER_ACCOUNT_REQUEST, createDealerAccount);
}
export function* watchAddDealerDataRequest() {
  yield takeLatest(ADD_DEALER_DATA_REQUEST, addDealerData);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetDealersRequest),
    fork(watchGetUsersDefaultRequest),
    fork(watchCreateDealerAccountRequest),
    fork(watchAddDealerDataRequest)
  ]);
}
