import {
  PROCESS_CAMPAIGN_INACTIVE_REQUEST,
  PROCESS_CAMPAIGN_INACTIVE_PROGRESS,
  PROCESS_CAMPAIGN_INACTIVE_SUCCESS,
  PROCESS_CAMPAIGN_INACTIVE_FAILURE,
  PROCESS_CAMPAIGN_INACTIVE_CANCEL,
} from 'constants/ActionTypes';


// Without Params
// export const processDataRequest = () => {
//   return {
//     type: PROCESS_DATA_REQUEST,
//   };
// };

export const processDataRequest = (params) => ({
  type: PROCESS_CAMPAIGN_INACTIVE_REQUEST,
  payload: params
});

export const processDataProgress = (progress) => {
  return {
    type: PROCESS_CAMPAIGN_INACTIVE_PROGRESS,
    payload: progress,
  };
};

export const processDataSuccess = () => {
  return {
    type: PROCESS_CAMPAIGN_INACTIVE_SUCCESS,
  };
};

export const processDataFailure = (error) => {
  return {
    type: PROCESS_CAMPAIGN_INACTIVE_FAILURE,
    payload: error,
  };
};

export const processDataCancel = (error) => {
  return {
    type: PROCESS_CAMPAIGN_INACTIVE_CANCEL,
    payload: error,
  };
};