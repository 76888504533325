import {
  PROCESS_CAMPAIGN_RANK_REQUEST,
  PROCESS_CAMPAIGN_RANK_PROGRESS,
  PROCESS_CAMPAIGN_RANK_SUCCESS,
  PROCESS_CAMPAIGN_RANK_FAILURE,
  PROCESS_CAMPAIGN_RANK_CANCEL,
} from 'constants/ActionTypes';


// Without Params
// export const processDataRequest = () => {
//   return {
//     type: PROCESS_DATA_REQUEST,
//   };
// };

export const processDataRequest = (params) => ({
  type: PROCESS_CAMPAIGN_RANK_REQUEST,
  payload: params
});

export const processDataProgress = (progress) => {
  return {
    type: PROCESS_CAMPAIGN_RANK_PROGRESS,
    payload: progress,
  };
};

export const processDataSuccess = () => {
  return {
    type: PROCESS_CAMPAIGN_RANK_SUCCESS,
  };
};

export const processDataFailure = (error) => {
  return {
    type: PROCESS_CAMPAIGN_RANK_FAILURE,
    payload: error,
  };
};

export const processDataCancel = (error) => {
  return {
    type: PROCESS_CAMPAIGN_RANK_CANCEL,
    payload: error,
  };
};

// export const processDataRequest = () => ({
//   type: PROCESS_DATA_REQUEST
// });

// export const processDataProgress = (progress) => ({
//   type: PROCESS_DATA_PROGRESS,
//   payload: progress
// });

// export const processDataSuccess = () => ({
//   type: PROCESS_DATA_SUCCESS
// });

// export const processDataFailure = (error) => ({
//   type: PROCESS_DATA_FAILURE,
//   payload: error
// });
