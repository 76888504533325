import { all, takeLatest, takeEvery, call, fork, put } from 'redux-saga/effects';
import {
  getResultServiceSurveySuccess,
  getResultDealerServiceSurveySuccess,
  getResultAreaServiceSurveySuccess,
  getResultByDealerServiceSurveySuccess,
  getServiceSurveySuccess,
  getServiceSurveyByDateSuccess,
  hideLoader,
  hideLoaderServiceSurvey
} from 'actions/ServiceSurvey';
import {
  GET_RESULT_SERVICE_SURVEY_REQUEST,
  GET_RESULT_DEALER_SERVICE_SURVEY_REQUEST,
  GET_RESULT_AREA_SERVICE_SURVEY_REQUEST,
  GET_RESULT_BY_DEALER_SERVICE_SURVEY_REQUEST,
  GET_SERVICE_SURVEY_REQUEST,
  GET_SERVICE_SURVEY_BY_DATE_REQUEST
} from 'constants/ActionTypes';
import { getFirestore, collection, query, where, getDoc, getDocs } from 'firebase/firestore';
import { rsf, firebase, db } from '../firebase/firebase';


function* getResultServiceSurvey({ payload }) {
  const { month, year } = payload;
  //console.log('sagaMonth:' + month + ' sagaYear:' + year);
  try {    
    // const colRef = firebase
    //   .firestore()
    //   .collection('ResultServiceSurveyThailand');
    
    // const snapshot = yield call(
    //   rsf.firestore.getCollection,
    //   colRef.where('taxMonth', '==', month).where('taxYear', '==', year)
    // );


    const colRef = collection(db, 'ResultServiceSurveyThailand');
    
    // Build query with date range
    const q = query(
      colRef,
      where('taxMonth', '==', month),
      where('taxYear', '==', year)
    );
    
    // Fetch documents
    const snapshot = yield call(getDocs, q);

    //console.log('items:' + snapshot);
    let items = [];
    snapshot.forEach(doc => {
      //console.log(doc.data());
      var data = doc.data();
      data['id'] = data.sendSurveyId;
      //data['friendsCount'] = data.friends.length;
      //console.log('T:' + data.friends.length);
      items.push(data);

      // datas = {
      //   id: doc.id,
      //   ...doc.data()
      // };
    });
    //console.log('To:' + items[1].friendsCount);
    yield put(getResultServiceSurveySuccess(items));
  } catch (error) {
    console.log(error);
    yield put(hideLoader());
  }
}
/*
function* getResultDealerServiceSurvey() {
  try {
    const colRef = firebase
      .firestore()
      .collection('ResultDealerServiceSurveyThailand');
    const snapshot = yield call(rsf.firestore.getCollection, colRef);
    //colRef.where('taxMonth', '==', '01').where('taxYear', '==', '2020')
    //console.log('items:' + snapshot);
    let items = [];
    snapshot.forEach(doc => {
      //console.log(doc.data());
      var data = doc.data();
      data['id'] = data.taxYear + '-' + data.taxMonth + '-' + data.dealerCode;
      items.push(data);

      // datas = {
      //   id: doc.id,
      //   ...doc.data()
      // };
    });
    items.sort((a, b) => {
      return a.docId > b.docId ? 1 : -1; //ascending
      //return a.docId > b.docId ? -1 : 1; //descending
    });
    //console.log('To:' + items[1].friendsCount);
    yield put(getResultDealerServiceSurveySuccess(items));
  } catch (error) {
    console.log(error);
    yield put(hideLoader());
  }
}
*/

function* getResultDealerServiceSurvey({ payload }) {
  const { month, year } = payload;
  //console.log('sagaMonthDealer:' + month + ' sagaYearDealer:' + year);
  try {
    // const colRef = firebase
    //   .firestore()
    //   .collection('ResultDealerServiceSurveyThailand');
    
    // const snapshot = yield call(rsf.firestore.getCollection, colRef.where('taxYear', '==', year));    

    const colRef = collection(db, 'ResultDealerServiceSurveyThailand');
    
    // Build query with date range
    const q = query(
      colRef,
      where('taxYear', '==', year)
    );
    
    // Fetch documents
    const snapshot = yield call(getDocs, q);

    let items = [];
    snapshot.forEach(doc => {
      //console.log(doc.data());
      var data = doc.data();
      data['id'] = data.taxYear + '-' + data.taxMonth + '-' + data.dealerCode;
      items.push(data);

      // datas = {
      //   id: doc.id,
      //   ...doc.data()
      // };
    });
    items.sort((a, b) => {
      return a.docId > b.docId ? 1 : -1; //ascending
      //return a.docId > b.docId ? -1 : 1; //descending
    });
    //console.log('To:' + items[1].friendsCount);
    yield put(getResultDealerServiceSurveySuccess(items));
  } catch (error) {
    console.log(error);
    yield put(hideLoader());
  }
}

/*
function* getResultAreaServiceSurvey() {
  try {
    const colRef = firebase
      .firestore()
      .collection('ResultAreaServiceSurveyThailand');
    const snapshot = yield call(rsf.firestore.getCollection, colRef);
    //colRef.where('taxMonth', '==', '01').where('taxYear', '==', '2020')
    //console.log('items:' + snapshot);
    let items = [];
    snapshot.forEach(doc => {
      //console.log(doc.data());
      var data = doc.data();
      data['id'] = data.docId;
      items.push(data);

      // datas = {
      //   id: doc.id,
      //   ...doc.data()
      // };
    });
    //console.log('To:' + items[1].friendsCount);
    items.sort((a, b) => {
      return a.docId > b.docId ? 1 : -1; //ascending
      //return a.docId > b.docId ? -1 : 1; //descending
    });
    yield put(getResultAreaServiceSurveySuccess(items));
  } catch (error) {
    console.log(error);
    yield put(hideLoader());
  }
}
*/

function* getResultAreaServiceSurvey({ payload }) {
  const { year } = payload; //month,
  //console.log('sagaMonthArea:' + month + ' sagaYearArea:' + year);
  try {
    // const colRef = firebase
    //   .firestore()
    //   .collection('ResultAreaServiceSurveyThailand');
    
    // const snapshot = yield call(rsf.firestore.getCollection, colRef.where('taxYear', '==', year));

    const colRef = collection(db, 'ResultAreaServiceSurveyThailand');
    
    // Build query with date range
    const q = query(
      colRef,
      where('taxYear', '==', year)
    );
    
    // Fetch documents
    const snapshot = yield call(getDocs, q);

    //console.log('items:' + snapshot);
    let items = [];
    snapshot.forEach(doc => {
      //console.log(doc.data());
      var data = doc.data();
      data['id'] = data.docId;
      items.push(data);

      // datas = {
      //   id: doc.id,
      //   ...doc.data()
      // };
    });
    //console.log('To:' + items[1].friendsCount);
    items.sort((a, b) => {
      return a.docId > b.docId ? 1 : -1; //ascending
      //return a.docId > b.docId ? -1 : 1; //descending
    });
    yield put(getResultAreaServiceSurveySuccess(items));
  } catch (error) {
    console.log(error);
    yield put(hideLoader());
  }
}

/*
function* getResultByDealerServiceSurvey({ payload }) {
  const { dealerCode } = payload;
  try {
    const colRef = firebase
      .firestore()
      .collection('ResultDealerServiceSurveyThailand');
    const snapshot = yield call(
      rsf.firestore.getCollection,
      colRef.where('dealerCode', '==', dealerCode)
    );
    //colRef.where('taxMonth', '==', '01').where('taxYear', '==', '2020')
    //console.log('items:' + snapshot);
    let items = [];
    snapshot.forEach(doc => {
      //console.log(doc.data());
      var data = doc.data();
      data['id'] = data.taxYear + '-' + data.taxMonth + '-' + data.dealerCode;
      items.push(data);

      // datas = {
      //   id: doc.id,
      //   ...doc.data()
      // };
    });

    items.sort((a, b) => {
      return a.docId > b.docId ? 1 : -1; //ascending
      //return a.docId > b.docId ? -1 : 1; //descending
    });
    //console.log('To:' + items[1].friendsCount);
    yield put(getResultByDealerServiceSurveySuccess(items));
  } catch (error) {
    console.log(error);
    yield put(hideLoader());
  }
}
*/

function* getResultByDealerServiceSurvey({ payload }) {
  const { dealerCode, month, year } = payload;
  //console.log('sagaDealerCode' + dealerCode + 'sagaMonthDealer:' + month + ' sagaYearDealer:' + year);
  try {
    // const colRef = firebase
    //   .firestore()
    //   .collection('ResultDealerServiceSurveyThailand');
    
    // const snapshot = yield call(
    //   rsf.firestore.getCollection,
    //   colRef.where('dealerCode', '==', dealerCode).where('taxYear', '==', year)
    // );

    const colRef = collection(db, 'ResultDealerServiceSurveyThailand');
    
    // Build query with date range
    const q = query(
      colRef,
      where('dealerCode', '==', dealerCode),
      where('taxYear', '==', year)
    );
    
    // Fetch documents
    const snapshot = yield call(getDocs, q);

    let items = [];
    snapshot.forEach(doc => {
      //console.log(doc.data());
      var data = doc.data();
      data['id'] = data.taxYear + '-' + data.taxMonth + '-' + data.dealerCode;
      items.push(data);

      // datas = {
      //   id: doc.id,
      //   ...doc.data()
      // };
    });

    items.sort((a, b) => {
      return a.docId > b.docId ? 1 : -1; //ascending
      //return a.docId > b.docId ? -1 : 1; //descending
    });
    //console.log('itemsDealer:' + items.length);
    //console.log('To:' + items[1].friendsCount);
    yield put(getResultByDealerServiceSurveySuccess(items));
  } catch (error) {
    console.log(error);
    yield put(hideLoader());
  }
}

function* getServiceSurvey({ payload }) {
  const { dealerCode, month, year } = payload;
  //console.log('sagaMonth:' + month + ' sagaYear:' + year);
  const startDateStr = year + '-' + month + '-' + '01';
  const endDateStr = year + '-' + month + '-' + '31';
  // console.log('dealerCode', dealerCode);
  // console.log('startDateStr', startDateStr);
  // console.log('endDateStr', endDateStr);
  try {
    //const colRef = firebase.firestore().collection('SendServiceSurveyThailand');
    
    const colRef = collection(db, 'SendServiceSurveyThailand');
        

    if (dealerCode !== '') {
      //console.log('call with dealerCode');

      // const snapshot = yield call(
      //   rsf.firestore.getCollection,
      //   colRef
      //     .where('taxDate', '>=', startDateStr)
      //     .where('taxDate', '<=', endDateStr)
      //     .where('dealerCode', '==', dealerCode)
      // );

      // Build query with date range
      const q = query(
        colRef,
        where('taxDate', '>=', startDateStr),
        where('taxDate', '<=', endDateStr),
        where('dealerCode', '==', dealerCode),      
      );
      
      // Fetch documents
      const snapshot = yield call(getDocs, q);

      //console.log('items:' + snapshot);
      let items = [];
      snapshot.forEach(doc => {
        //console.log(doc.data());
        var data = doc.data();
        data['id'] = data.sendSurveyId;
        //data['friendsCount'] = data.friends.length;
        //console.log('T:' + data.friends.length);
        items.push(data);

        // datas = {
        //   id: doc.id,
        //   ...doc.data()
        // };
      });
      //console.log('To:' + items[1].friendsCount);
      yield put(getServiceSurveySuccess(items));
    } else {
      //console.log('call withOUT dealerCode');

      // const snapshot = yield call(
      //   rsf.firestore.getCollection,
      //   colRef
      //     .where('taxDate', '>=', startDateStr)
      //     .where('taxDate', '<=', endDateStr)
      // );

      // Build query with date range
      const q = query(
        colRef,
        where('taxDate', '>=', startDateStr),
        where('taxDate', '<=', endDateStr),          
      );
      
      // Fetch documents
      const snapshot = yield call(getDocs, q);

      //console.log('items:' + snapshot);
      let items = [];
      snapshot.forEach(doc => {
        //console.log(doc.data());
        var data = doc.data();
        data['id'] = data.sendSurveyId;
        //data['friendsCount'] = data.friends.length;
        //console.log('T:' + data.friends.length);
        items.push(data);

        // datas = {
        //   id: doc.id,
        //   ...doc.data()
        // };
      });
      //console.log('To:' + items[1].friendsCount);
      yield put(getServiceSurveySuccess(items));
    }
  } catch (error) {
    console.log(error);
    yield put(hideLoaderServiceSurvey());
  }
}

function* getServiceSurveyByDate({ payload }) {
  const { dealerCode, startDate, endDate } = payload;
  //console.log('sagaMonth:' + month + ' sagaYear:' + year);
  //const startDateStr = year + '-' + month + '-' + '01';
  //const endDateStr = year + '-' + month + '-' + '31';
  // console.log('dealerCode', dealerCode);
  // console.log('startDate', startDate);
  // console.log('endDate', endDate);

  try {
    //const colRef = firebase.firestore().collection('SendServiceSurveyThailand');
    
    const colRef = collection(db, 'SendServiceSurveyThailand');

    if (dealerCode !== '') {
      //console.log('call with dealerCode');

      // const snapshot = yield call(
      //   rsf.firestore.getCollection,
      //   colRef
      //     .where('taxDate', '>=', startDate)
      //     .where('taxDate', '<=', endDate)
      //     .where('dealerCode', '==', dealerCode)
      // );

      // Build query with date range
      const q = query(
        colRef,
        where('taxDate', '>=', startDate),
        where('taxDate', '<=', startDate),
        where('dealerCode', '==', dealerCode),      
      );
      
      // Fetch documents
      const snapshot = yield call(getDocs, q);

      //console.log('items:' + snapshot);
      let items = [];
      snapshot.forEach(doc => {
        //console.log(doc.data());
        var data = doc.data();
        data['id'] = data.sendSurveyId;
        //data['friendsCount'] = data.friends.length;
        //console.log('T:' + data.friends.length);
        items.push(data);

        // datas = {
        //   id: doc.id,
        //   ...doc.data()
        // };
      });
      //console.log('To:' + items[1].friendsCount);
      yield put(getServiceSurveyByDateSuccess(items));
    } else {
      //console.log('call withOUT dealerCode');

      // const snapshot = yield call(
      //   rsf.firestore.getCollection,
      //   colRef.where('taxDate', '>=', startDate).where('taxDate', '<=', endDate)
      // );

      // Build query with date range
      const q = query(
        colRef,
        where('taxDate', '>=', startDate),
        where('taxDate', '<=', startDate),            
      );
      
      // Fetch documents
      const snapshot = yield call(getDocs, q);

      //console.log('items:' + snapshot);
      let items = [];
      snapshot.forEach(doc => {
        //console.log(doc.data());
        var data = doc.data();
        data['id'] = data.sendSurveyId;
        //data['friendsCount'] = data.friends.length;
        //console.log('T:' + data.friends.length);
        items.push(data);

        // datas = {
        //   id: doc.id,
        //   ...doc.data()
        // };
      });
      //console.log('To:' + items[1].friendsCount);
      yield put(getServiceSurveyByDateSuccess(items));
    }
  } catch (error) {
    console.log(error);
    yield put(hideLoaderServiceSurvey());
  }
}

export function* watchGetResultServiceSurveyRequest() {
  yield takeLatest(GET_RESULT_SERVICE_SURVEY_REQUEST, getResultServiceSurvey);
}

export function* watchGetResultDealerServiceSurveyRequest() {
  yield takeLatest(
    GET_RESULT_DEALER_SERVICE_SURVEY_REQUEST,
    getResultDealerServiceSurvey
  );
}

export function* watchGetResultAreaSurveyRequest() {
  yield takeLatest(
    GET_RESULT_AREA_SERVICE_SURVEY_REQUEST,
    getResultAreaServiceSurvey
  );
}

export function* watchGetResultByDealerServiceSurveyRequest() {
  yield takeLatest(
    GET_RESULT_BY_DEALER_SERVICE_SURVEY_REQUEST,
    getResultByDealerServiceSurvey
  );
}

export function* watchGetServiceSurveyRequest() {
  yield takeLatest(GET_SERVICE_SURVEY_REQUEST, getServiceSurvey);
}

export function* watchGetServiceSurveyByDateRequest() {
  yield takeLatest(GET_SERVICE_SURVEY_BY_DATE_REQUEST, getServiceSurveyByDate);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetResultServiceSurveyRequest),
    fork(watchGetResultDealerServiceSurveyRequest),
    fork(watchGetResultAreaSurveyRequest),
    fork(watchGetResultByDealerServiceSurveyRequest),
    fork(watchGetServiceSurveyRequest),
    fork(watchGetServiceSurveyByDateRequest)
  ]);
}
