import React from 'react';
import { useDispatch } from 'react-redux';
import { resetPage } from 'actions/ResetPage';

function ResetButton({ 
  pageId, 
  className = "px-4 py-2 bg-gray-200 hover:bg-gray-300 rounded",
  label = "Reset"
}) {
  const dispatch = useDispatch();

  const handleReset = (e) => {
    // Prevent default form submission
    e.preventDefault();
    e.stopPropagation();
    dispatch(resetPage(pageId));
  };

  return (
    <button 
      type="button"
      onClick={handleReset}
      className={className}
    >
      {label}
    </button>
  );
}

export default ResetButton;


// import React from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { resetPage } from 'actions/ResetPage';
// import { getResetStatus } from 'reducers/ResetPage';

// const ResetButton = ({ pageId, onResetComplete }) => {
//     const dispatch = useDispatch();
//     const resetStatus = useSelector((state) => getResetStatus(state, pageId));
  
//     const handleReset = (e) => {
//       // Prevent default form submission
//       e.preventDefault();
//       e.stopPropagation();
//       dispatch(resetPage(pageId));
//     };
  
//     React.useEffect(() => {
//       if (resetStatus.success && onResetComplete) {
//         onResetComplete();
//       }
//     }, [resetStatus.success, onResetComplete]);
  
//     return (
//       <button
//         type="button" // Explicitly set type to button
//         onClick={handleReset}
//         disabled={resetStatus.isResetting}
//         className={`px-4 py-2 rounded ${
//           resetStatus.isResetting ? 'bg-gray-300' : 'bg-gray-200 hover:bg-gray-300'
//         }`}
//       >
//         {resetStatus.isResetting ? 'Resetting...' : 'Reset'}
//       </button>
//     );
// };
  
// export default ResetButton;

// const ResetButton = ({ 
//   pageId, 
//   className = "px-4 py-2 bg-gray-200 hover:bg-gray-300 rounded",
//   label = "Reset"
// }) => {
//   const dispatch = useDispatch();

//   const handleReset = () => {
//     //dispatch(resetPage(pageId));
//     resetPage(pageId);
//   };

//   return (
//     <button 
//       onClick={handleReset}
//       className={className}
//     >
//       {label}
//     </button>
//   );
// };

// export default ResetButton;

