import { all, takeLatest, takeEvery, call, fork, put } from 'redux-saga/effects';
import {
    getUserByVinSuccess,
    hideLoaderUserByVin,  
    getCarsByUserSuccess,
    hideLoaderCarsByUser
} from 'actions/SearchUser';
import {
    GET_USER_BY_VIN_REQUEST,
    GET_CARS_BY_USER_REQUEST,
} from 'constants/ActionTypes';
import { getFirestore, collection, query, where, getDoc, getDocs } from 'firebase/firestore';
import { rsf, firebase, db } from '../firebase/firebase';

function* getUserByVin({ payload }) {

    const { vinNumber } = payload;
    //console.log('vinNumber:' + vinNumber);

    try {
        // const colRef = firebase.firestore().collection('UsersThailand');
        
        // const snapshot = yield call(
        //   rsf.firestore.getCollection,
        //   colRef
        //     .where('chassisNumber', 'array-contains', vinNumber)  
        //     //.where('phone', '==', vinNumber)          
        // );

        const colRef = collection(db, 'UsersThailand');
    
        // Build query with date range
        const q = query(
          colRef,
          where('chassisNumber', 'array-contains', vinNumber),          
        );
        
        // Fetch documents
        const snapshot = yield call(getDocs, q);


        //console.log('items:' + snapshot);
        let data;
        snapshot.forEach((doc) => {
          //console.log(doc.data());
          data = doc.data();
          data['id'] = data.objectId;
          //items.push(data);
    
          // datas = {
          //   id: doc.id,
          //   ...doc.data()
          // };
        });
        //console.log('To:' + data.objectId);
        yield put(getUserByVinSuccess(data));
      } catch (error) {
        console.log(error);
        yield put(hideLoaderUserByVin());
      }
}

function* getCarsByUser({ payload }) {

  const { userId } = payload;
  //console.log('userId:' + userId);

  try {
      // const colRef = firebase.firestore().collection('CarsThailand');
      
      // const snapshot = yield call(
      //   rsf.firestore.getCollection,
      //   colRef
      //     .where('userId', '==', userId) 
      //     //.where('chassisNumber', '==', vinNumber)                     
      // );

      const colRef = collection(db, 'CarsThailand');
    
      // Build query with date range
      const q = query(
        colRef,
        where('userId', '==', userId),        
      );
      
      // Fetch documents
      const snapshot = yield call(getDocs, q);

      //console.log('items:' + snapshot);
      let items = [];
      snapshot.forEach((doc) => {
        //console.log(doc.data());
        var data = doc.data();
        data['id'] = data.chassisNumber;
        items.push(data);
  
        // datas = {
        //   id: doc.id,
        //   ...doc.data()
        // };
      });
      //console.log('Num cars:' + items.length);

      items.sort((a, b) => {
        return a.chassisNumber > b.chassisNumber ? 1 : -1; //ascending
        //return a.chassisNumber > b.chassisNumber ? -1 : 1; //descending
      });

      yield put(getCarsByUserSuccess(items));
    } catch (error) {
      console.log(error);
      yield put(hideLoaderCarsByUser());
    }
}

export function* watchGetUserByVinRequest() {
  yield takeLatest(GET_USER_BY_VIN_REQUEST, getUserByVin);
}

export function* watchGetCarsByUserRequest() {
  yield takeLatest(GET_CARS_BY_USER_REQUEST, getCarsByUser);
}
  
export default function* rootSaga() {
    yield all([
      fork(watchGetUserByVinRequest),
      fork(watchGetCarsByUserRequest),
    ]);
}