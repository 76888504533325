import { all, takeLatest, takeEvery, call, fork, put } from 'redux-saga/effects';
import {
    getApiUserSuccess,
    getUserByOldPhoneSuccess,
    getUserByNewPhoneSuccess,
    hideApiUserLoader,
    hideOldPhoneLoader,
    hideNewPhoneLoader,
    changePhoneSuccess,
    hideChangePhoneLoader,
    showMessageChangePhone,
    resetChangePhone
} from 'actions/ChangePhone';
import {
    GET_API_USER_REQUEST,
    GET_USER_BY_OLD_PHONE_REQUEST,
    GET_USER_BY_NEW_PHONE_REQUEST,
    CHANGE_PHONE_REQUEST
} from 'constants/ActionTypes';
import { getFirestore, collection, query, where, getDoc, getDocs } from 'firebase/firestore';
import { rsf, firebase, db } from '../firebase/firebase';
import { getFunctions, httpsCallable } from 'firebase/functions';
import axios from "axios";
import https from "https";

const functions = getFunctions(firebase, 'asia-east2'); // specify your region


export const fetchUser = (postParams) => {
    // return axios ({
    //     method: 'get',
    //     headers: { 
    //         'Content-Type': 'application/json',
    //         'Access-Control-Allow-Origin': '*',
    //         'Access-Control-Allow-Credentials' : 'true',
    //         'Access-Control-Allow-Methods': 'GET, POST, OPTIONS',
    //         'Access-Control-Allow-Headers' : 'Origin, Content-Type, Accept'
    //     },
    //     url: url
    // }).then( resp => resp.data );

    // return axios.post(url, 
    //     {
    //         headers: { 
    //             'Content-Type': 'application/json',
    //             'Access-Control-Allow-Origin': '*',
    //             'Access-Control-Allow-Credentials' : 'true',
    //             'Access-Control-Allow-Methods': 'GET, POST, OPTIONS',
    //             'Access-Control-Allow-Headers' : 'Origin, Content-Type, Accept'
    //         }
    //     }).then(function (response) {
    //         console.log(response);
    //     });

    

    const agent = new https.Agent({
      rejectUnauthorized: false,
    });

    axios.defaults.options = agent

    return axios
    .post("https://34.96.201.193/getUserFromApi", postParams,  { httpsAgent: agent } )
    .then(response => {
      //console.log("response: ", response)
      // do something about response
    })
    .catch(err => {
      console.error(err)
    })
}


// function fetchUser2(postParams) {
//   var getUser = firebase.functions().httpsCallable('getUserMyCar');
//   return getUser(postParams).then(function(result) {
//       return result;
//   }).catch(function(error) {
//       return error;
//   }); 
// }


//https://stackoverflow.com/questions/52365937/react-redux-saga-api-call
//https://stackoverflow.com/questions/47499340/cant-yield-json-response-from-server-with-redux-saga
function* getApiUser({ payload }) {
    const { newPhone, vinNumber } = payload;
    //console.log('newP:' + newPhone);
    //console.log('vinN:' + vinNumber);
    try {

        // var formData = new FormData();
        // formData.append('vinnumber', vinNumber);
        // formData.append('newphone', newPhone);

       
        const postParams = {
          vinNumber: vinNumber,
          newPhone: newPhone
        };

        // // const headerParams = {
        // //   'Content-Type': 'application/json',
        // //   'Access-Control-Allow-Origin': '*',
        // // };

        // const agent = new https.Agent({
        //   rejectUnauthorized: false,
        // }); 

        // axios.defaults.options = agent


        // rsf.region = 'asia-east2';      
        
        // const json = yield call(rsf.functions.call, 'getUserMyCar', postParams);
              


        // Create callable function with custom options
        // const functionRef = httpsCallable(functions, 'getUserMyCar', {
        //   timeout: 60000, // 60 seconds timeout
        // });

        // const functionRef = httpsCallable(functions, 'getUserMyCar');
        // const json = yield call(functionRef, postParams);

        // console.log("json:" + json);
        // console.log("UUUUUUUUUUUUU");
        // console.log("json:" + json.data);


        const functionUrl =  'https://asia-east2-hello-suzuki.cloudfunctions.net/getUserMyCar';

        const url = new URL(functionUrl);
        Object.keys(postParams).forEach(key => 
          url.searchParams.append(key, postParams[key])
        );

        const response = yield call(fetch, url.toString(), {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(postParams)
        });

        const json = yield call([response, 'json']);

        //console.log('result:' + json);


        yield put(getApiUserSuccess(json));
        //yield put(getApiUserSuccess(json.data));
      } catch (error) {
        console.log(error);
        yield put(hideApiUserLoader());
      }
}

function* getUsersByOldPhone({ payload }) {
    const { oldPhone } = payload;
    try {

      // const colRef = firebase.firestore().collection('UsersThailand');
      
      // const snapshot = yield call(
      //   rsf.firestore.getCollection,
      //   colRef
      //       .where('phone', '==', oldPhone)
      //       .where('isHaveCar', '==', true)
      // );

      // Create reference to the RegisterUsers collection
      const colRef = collection(db, 'UsersThailand');
      
      // Build query with date range
      const q = query(
        colRef,
        where('phone', '==', oldPhone),
        where('isHaveCar', '==', true)
      );
      
      // Fetch documents
      const snapshot = yield call(getDocs, q);


      //console.log('items:' + snapshot);
      let items = [];
      snapshot.forEach((doc) => {
        //console.log(doc.data());
        var data = doc.data();
        data['id'] = data.objectId;
        items.push(data);
  
        // datas = {
        //   id: doc.id,
        //   ...doc.data()
        // };
      });
    //   items.sort((a, b) => {
    //     return a.createdAt > b.createdAt ? 1 : -1; //ascending
    //     //return a.dealerCode > b.dealerCode ? -1 : 1; //descending
    //   });
      //console.log('OldTo:' + items.length);
      yield put(getUserByOldPhoneSuccess(items));
    } catch (error) {
      console.log(error);
      yield put(hideOldPhoneLoader());
    }
  }

  function* getUsersByNewPhone({ payload }) {
    const { newPhone } = payload;
    try {


      // const colRef = firebase.firestore().collection('UsersThailand');
      // //const colRef = query(collection(db, 'UsersThailand'));
      // const snapshot = yield call(
      //   rsf.firestore.getCollection,
      //   colRef
      //       .where('phone', '==', newPhone)
      //       .where('isHaveCar', '==', true)
      // );


      // Create reference to the RegisterUsers collection
      const colRef = collection(db, 'UsersThailand');
      
      // Build query with date range
      const q = query(
        colRef,
        where('phone', '==', newPhone),
        where('isHaveCar', '==', true)
      );
      
      // Fetch documents
      const snapshot = yield call(getDocs, q);

      if (snapshot.empty) {
        //console.log('empty');
        const postParams = {
          phone: newPhone,
          token: '534d081b38cb068a04c728a10a43f7c1277b5192283a20cf8a0ab5ff33d3f5b78b1ebbff09b28e4c6034a28aca2c5f19c3b4beac1b69773f6d1913ed9d5e3617'
        }
  
        // rsf.region = 'asia-east2';
        // const json = yield call(rsf.functions.call, 'checkPhoneIsUsing', postParams);

        // const functionRef = httpsCallable(functions, 'checkPhoneIsUsing');
        // const json = yield call(functionRef, postParams);

        const functionUrl =  'https://asia-east2-hello-suzuki.cloudfunctions.net/checkPhoneIsUsing';

        const url = new URL(functionUrl);
        Object.keys(postParams).forEach(key => 
          url.searchParams.append(key, postParams[key])
        );

        const response = yield call(fetch, url.toString(), {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(postParams)
        });

        const result = yield call([response, 'json']);

        //console.log('result:' + result);
        

        let items = [];
        
        yield put(getUserByNewPhoneSuccess(items)); //items
      } else {
        //console.log('no empty');
        let items = [];
        snapshot.forEach((doc) => {
          //console.log(doc.data());
          var data = doc.data();
          data['id'] = data.objectId;
          items.push(data);          
        });
        //let isPhoneUsing = true;
   
        yield put(getUserByNewPhoneSuccess(items));
      }
      
    } catch (error) {
      console.log(error);
      yield put(hideNewPhoneLoader());
    }
  }

  function* changePhone({ payload }) {
    const { userId, oldPhone, newPhone, vinNumber } = payload;
    try {
        

        const postParams = {
          userid: userId,
          oldphone: oldPhone,
          newphone: newPhone,
          vinnumber: vinNumber,
        };

        // console.log('userId:' + userId);
        // console.log('oldPhone:' + oldPhone);
        // console.log('newPhone:' + newPhone);
        // console.log('vinNumber:' + vinNumber);
        

        // rsf.region = 'asia-east2';
        // const json = yield call(rsf.functions.call, 'changePhone', postParams);

        
        // const functionRef = httpsCallable(functions, 'changePhone');
        // const json = yield call(functionRef, postParams);

        // START HERE

        const functionUrl =  'https://asia-east2-hello-suzuki.cloudfunctions.net/changePhone';

        const url = new URL(functionUrl);
        Object.keys(postParams).forEach(key => 
          url.searchParams.append(key, postParams[key])
        );

        const response = yield call(fetch, url.toString(), {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(postParams)
        });

        const json = yield call([response, 'json']);

        //console.log('result:' + json);


        yield put(changePhoneSuccess(json.STATUS_CODE));
        yield put(showMessageChangePhone(json.STATUS_CODE));
        
        // END HERE


        // FOR Testing
        // yield put(changePhoneSuccess(100));
        // yield put(showMessageChangePhone(100));

        //yield put(changePhoneSuccess(json));
    } catch (error) {
      console.log(error);
      yield put(hideChangePhoneLoader());
    }
  }

  function* resetData () {
    yield put(resetChangePhone());
  }

  export function* watchGetApiUserRequest() {
    yield takeLatest(GET_API_USER_REQUEST, getApiUser);
  }
  export function* watchGetUserByOldPhoneRequest() {
    yield takeLatest(GET_USER_BY_OLD_PHONE_REQUEST, getUsersByOldPhone);
  }
  export function* watchGetUserByNewPhoneRequest() {
    yield takeLatest(GET_USER_BY_NEW_PHONE_REQUEST, getUsersByNewPhone);
  }
  export function* watchChangePhoneRequest() {
    yield takeLatest(CHANGE_PHONE_REQUEST, changePhone);
  }
  // export function* watchResetChangePhoneRequest() {
  //   yield takeEvery(RESET_CHANGE_PHONE, resetData);
  // }
  
  export default function* rootSaga() {
    yield all([
      fork(watchGetApiUserRequest),
      fork(watchGetUserByOldPhoneRequest),
      fork(watchGetUserByNewPhoneRequest),
      fork(watchChangePhoneRequest),
      //fork(watchResetChangePhoneRequest)
    ]);
  }